import {
  CloseOutlined,
  DownloadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Divider,
  Col,
  Row,
  Space,
  Button,
  notification,
  Spin,
  Checkbox,
} from "antd";
import { Form } from "antd";
import get from "lodash/get";
import map from "lodash/map";
import "./style.scss";
import { addAppointmentNotes, getNoteTemplates } from "../../../redux/actions/doctorAction";
import NotesDynamicForm from "./NotesDynamicForm";
import AttachmentsDynamicForm from "./AttachmentsDynamicForm";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NoteTemplates from "./NoteTemplates";
import CustomizeTemplateForm from "./CustomizeTemplateForm";
import { useForm } from "antd/lib/form/Form";
import PatientAnswersDetails from "./PatientAnswersDetails";

const ConsultationForm = ({
  toggleConsultationForm,
  appointment_details,
  appointment_uuid,
  getAppointments,
  filter,
}) => {
  const [form] = useForm();
  const roles = useSelector((state) => get(state, 'auth.roles'));
  const appointmentPatientAnswers =
    appointment_details?.patient_answers ?
      JSON.parse(appointment_details?.patient_answers)
      : {};
  const [loading, setLoading] = useState(false);
  const [attachmentImages, setAttachmentImages] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [includeNotes, setIncludeNotes] = useState(false);
  const [includeAttachments, setIncludeAttachments] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    const attachmentHeadings = get(values, "attachments");

    const finalImages = map(attachmentImages, (imageArray) =>
      map(imageArray, (image) => get(image, "name"))
    );

    const attachments = map(finalImages, (images, index) => ({
      images: images,
      title: get(attachmentHeadings[index], "title"),
    }));

    const payload = {
      notes: includeNotes ? get(values, "notes") : undefined,
      attachments: includeAttachments ? attachments : undefined,
    };

    addAppointmentNotes(payload, appointment_uuid)
      .then((result) => {
        notification.success({
          message: "Notes added",
        });
        setLoading(false);
        toggleConsultationForm();
        getAppointments(
          get(filter, "page"),
          get(filter, "fromDate"),
          get(filter, "toDate"),
          get(filter, "days"),
          get(filter, "status")
        );
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const hospital_uuid = useSelector((state) =>
    get(state, "auth.currentHospital.id")
  );
  const doctor_user_uuid = useSelector((state) => state?.auth.user?.id);
  const [noteTemplates, setNoteTemplates] = useState([]);
  const [openTemplate, setOpenTemplate] = useState(false);

  useEffect(() => {
    getNoteTemplates(doctor_user_uuid).then((result) => {
      setLoading(false);
      setNoteTemplates(result);
    })
  }, []);

  const createTemplate = () => {
    setOpenTemplate(true);
  }

  return (
    <Spin spinning={loading}>
      <div>
        <Row justify="space-between">
          <Col className="drawer-title">Start Consultation</Col>
          <Col>
            <CloseOutlined onClick={toggleConsultationForm} />
          </Col>
        </Row>
        <div className="drawer-content">
          <Row justify="space-between" style={{ marginBottom: "20px" }}>
            <Col>
              <div>{get(appointment_details, "date")}</div>
              <div className="small-grey">
                {get(appointment_details, "time")}
              </div>
            </Col>
            <Col>
              <DownloadOutlined style={{ fontSize: "20px" }} />
            </Col>
          </Row>
          <Row>
            <Space size={40} align="flex-start">
              <Col>
                <Avatar size={64} icon={<UserOutlined />} />
              </Col>
              <Col>
                <div>{get(appointment_details, "patient.name")}</div>
              </Col>
              <Col>
                <div>ID: {get(appointment_details, "appointment_id")}</div>
              </Col>
            </Space>
          </Row>
          <Divider />

          <div>
            {noteTemplates?.length > 0 ?
              <>
                <div className="current-label">Select Template</div>
                <NoteTemplates setSelectedTemplate={setSelectedTemplate} noteTemplates={noteTemplates} />
              </>
              : null}
          </div>
          <Button onClick={createTemplate} type="primary">
            Customize Template
          </Button>
          {
            openTemplate &&
            <CustomizeTemplateForm setOpenTemplate={setOpenTemplate} doctor_user_uuid={doctor_user_uuid} setNoteTemplates={setNoteTemplates} hospital_uuid={hospital_uuid} />
          }
          <div className="current-report">
            <Form
              name="dynamic_form_nest_item"
              onFinish={onFinish}
              autoComplete="off"
              form={form}
            >
              <div className="current-label">
                <Checkbox
                  checked={includeNotes}
                  onChange={(e) => setIncludeNotes(e.target.checked)}
                >
                  Include in Medical History
                </Checkbox>
              </div>
              <NotesDynamicForm
                selectedTemplate={selectedTemplate}
                noteTemplates={noteTemplates}
                form={form}
              />
              <div className="current-label">
                <Checkbox
                  checked={includeAttachments}
                  onChange={(e) => setIncludeAttachments(e.target.checked)}
                >
                  Include in Medical History
                </Checkbox>
              </div>
              <AttachmentsDynamicForm
                attachmentImages={attachmentImages}
                setAttachmentImages={setAttachmentImages}
                form={form}
              />
              <Button type="primary" htmlType="submit">
                Save and Mark as Consulted
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default ConsultationForm;
