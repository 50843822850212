/**
 * Constants
 */
import * as APIS from '../../constants/apiEndpoints';
import { axiosInstance } from '../../utilities/configureAxios';


export const postClaim = (payload) => {
    return axiosInstance.post(APIS.CREATE_CLAIM, payload).then((result) => {
      return result;
    });
};
export const getClaims = () => {
  return axiosInstance.get(APIS.GET_CLAIMS).then((result) => {
    return result;
  });
};
export const getClaimTypes = () => {
  return axiosInstance.get(APIS.GET_CLAIM_TYPES).then((result) => {
    return result;
  });
};
export const updateClaim = (id,data) => {
  let url = APIS.UPDATE_CLAIM.replace('{id}',id);
  return axiosInstance.put(url,data).then((result) => {
    return result;
  });
};
