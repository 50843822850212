import { Route } from 'react-router-dom';
import * as ROUTES from '../constants/routes';
import CityScreen from '../screens/CityScreen';
import BillingsScreen from '../screens/BillingsScreen/BillingsScreen';
import HospitalsScreen from '../screens/HospitalsScreen/HospitalsScreen';

export default function AuthRouter() {
  return (
    <>
      <Route
        path={ROUTES.HOSPITALS}
        exact
        component={() => <HospitalsScreen />}
      />

      <Route path={ROUTES.CITIES} exact component={() => <CityScreen />} />
      <Route
        path={ROUTES.BILLINGS}
        exact
        component={() => <BillingsScreen />}
      />
    </>
  );
}
