import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import './styles.scss';
import SingleWorkingHours from '../singleWorkingHours';
import map from 'lodash/map';

const SelectWorkingHours = ({ openHours, setOpenHours, onChange }) => {
  const add = () => {
    setOpenHours([
      ...openHours,
      { days: [], from_time: '12:00', to_time: '12:00' },
    ]);
  };

  const remove = (index) => {
    const newOpenHours = [...openHours];
    newOpenHours.splice(index, 1);
    setOpenHours(newOpenHours);
  };

  return (
    <>
      {map(openHours, (single, i) => (
        <SingleWorkingHours
          openHours={single}
          onChange={onChange}
          index={i}
          remove={remove}
        />
      ))}
      <Button
        onClick={add}
        style={{
          width: '100%',
          height: '40px',
          background: '#f4efff',
          border: 'none',
        }}
        icon={<PlusOutlined style={{ fontSize: '16px', color: '#532eb3' }} />}
      ></Button>
    </>
  );
};

export default SelectWorkingHours;
