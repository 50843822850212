/**
 * Constants
 */
import axios from 'axios';
import get from 'lodash/get';
import * as APIS from '../../constants/apiEndpoints';
import { axiosInstance } from '../../utilities/configureAxios';
import { getUrl, replaceMultiple } from '../../utilities/helpers';

export const invite = (credentials) => {
  return axiosInstance.post(APIS.INVITE, credentials).then((result) => {
    return result;
  });
};
export const getDoctorInvitations = (credentials) => {
  return axiosInstance.post(APIS.GET_DOCTOR_INVITATIONS, credentials).then((result) => {
    return result;
  });
};

export const listDoctors = (specialisation_uuid, page, limit = 10) => {
  let url = replaceMultiple(APIS.LISTDOCTORS, {
    '{page}': page,
    '{limit}': limit,
  });
  if (specialisation_uuid) {
    url = url.concat('&specialisation_uuid=' + specialisation_uuid);
  }

  return axiosInstance.get(url).then((result) => {
    return result;
  });
};

export const updateDoctor = (values) => {
  return axiosInstance.put(APIS.UPDATEDOCTOR, values).then((result) => {
    return result;
  });
};

export const specList = () => {
  return axiosInstance
    .get(APIS.SPECLIST)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log('error', error);
    });
};

export const singleDoctor = (id) => {
  return axiosInstance.get(APIS.SINGLEDOCTOR.concat(id)).then((result) => {
    return result;
  });
};

export const updateWorkingHours = (values, doctor_uuid) => {
  let url = APIS.WORKINGHOURS.replace('{doctor_uuid}', doctor_uuid);
  return axiosInstance.put(url, values).then((result) => {
    return result;
  });
};

export const getWorkingHours = (doctor_uuid) => {
  let url = APIS.WORKINGHOURS.replace('{doctor_uuid}', doctor_uuid);
  return axiosInstance.get(url).then((result) => {
    return result;
  });
};

export const validateDoctorLink = (uuid) => {
  return axiosInstance
    .get(APIS.VALID_DOCTOR_LINK.concat(uuid))
    .then((result) => {
      return result;
    });
};

export const registerDoctor = (values) => {
  return axiosInstance.post(APIS.UPDATEDOCTOR, values).then((result) => {
    return result;
  });
};

export const doctorSlots = (values) => {
  let url = replaceMultiple(APIS.DOCTOR_SLOTS, {
    '{doctor_uuid}': get(values, 'doctor_uuid'),
    '{hospital_uuid}': get(values, 'hospital_uuid'),
    '{date}': get(values, 'date'),
  });

  console.log('URL for slots', url);

  return axiosInstance.get(url).then((result) => {
    return result;
  });
};

export const getMonthAvailability = (values) => {
  let url = replaceMultiple(APIS.DOCTOR_MONTHLY_AVAILABILITY, {
    '{doctor_uuid}': get(values, 'doctor_uuid'),
    '{hospital_uuid}': get(values, 'hospital_uuid'),
    '{date}': get(values, 'date'),
  });

  return axiosInstance.get(url).then((result) => {
    return result;
  });
};

export const markUnavailability = (payload, doctor_uuid) => {
  let url = APIS.DOCTOR_LEAVE.replace('{doctor_uuid}', doctor_uuid);
  return axiosInstance.post(url, payload).then((result) => {
    return result;
  });
};


export const deleteDoctorLeave = (doctor_uuid,leave_uuid)=>{
  let url = replaceMultiple(APIS.DELETE_DOCTOR_LEAVE, {
    '{doctor_uuid}': doctor_uuid,
    '{uuid}': leave_uuid,
  });
  return axiosInstance
  .delete(url)
  .then((result) => {
    return result;
  });

}

export const fetchAppointments = (from_date, to_date, status, page, query) => {
  console.log('Status is', status);
  var url = getUrl(APIS.APPOINTMENTS, {
    from_date,
    to_date,
    page,
    query,
  });
  if (status) {
    url = url.concat('&status=' + status);
  }
  return axiosInstance.get(url).then((result) => {
    return result;
  });
};

export const addAppointmentNotes = (payload, appointment_uuid) => {
  let url = APIS.APPOINTMENT_NOTES.replace(
    '{appointment_uuid}',
    appointment_uuid
  );
  return axiosInstance.post(url, payload).then((result) => {
    return result;
  });
};

export const createEmergencyAppointment =(payload,newPayload)=>{
  const data = {...payload,patient : newPayload?.patient}
  let url = APIS.POST_EMERGENCY_APPOINTMENT;
  return axiosInstance.post(url,data).then((result)=>{
    return result;
  })
}

export const getPatientMembers = (data) => {
  let url = APIS.GET_PATIENT_MEMBERS.replace('{uuid}', data?.hospital_uuid);;
  return axiosInstance.get(url).then((result) => {
    return result;
  });
};

export const getPatientDashboard = (filteredUser) => {
  let url = APIS.GET_PATIENT_DASHBOARD.replace('{uuid}', filteredUser?.id);;
  return axiosInstance.get(url).then((result) => {
    return result;
  });
};
export const getDoctorsAppointmentSlots = (doctor_uuid,hospital_uuid,date) => {
  let url = replaceMultiple(APIS.GET_DOCTOR_APPOINTMENT_SLOTS, {
    '{uuid}': doctor_uuid,
    '{hospital_uuid}': hospital_uuid,
    '{date}' : date
  });
  return axiosInstance.get(url).then((result) => {
    return result;
  });
};
export const getMedicalHistory = (patient_uuid) => {
  let url = APIS.MEDICAL_HISTORY.replace('{patient_uuid}', patient_uuid);
  return axiosInstance.get(url).then((result) => {
    return result;
  });
};

export const updateMedicalHistory = (
  payload,
  appointment_uuid,
  appointment_note_uuid
) => {
  let url = replaceMultiple(APIS.UPDATE_APPOINTMENT_NOTES, {
    '{appointment_uuid}': appointment_uuid,
    '{appointment_note_uuid}': appointment_note_uuid,
  });

  return axiosInstance.put(url, payload).then((result) => {
    return result;
  });
};

export const updateAppointmentStatus = (appointment_uuid, payload) => {
  let url = APIS.UPDATE_APPOINTMENT_STATUS.replace(
    '{appointment_uuid}',
    appointment_uuid
  );

  return axiosInstance.put(url, payload).then((result) => {
    return result;
  });
};

export const rescheduleAppointment = (appointment_uuid, payload) => {
  let url = APIS.RESCHEDULE_APPOINTMENT.replace(
    '{appointment_uuid}',
    appointment_uuid
  );
  return axiosInstance.put(url, payload).then((result) => {
    return result;
  });
};

export const getDelegates = () => {
  return axiosInstance.get(APIS.DELEGATES).then((result) => {
    return result;
  });
};

export const deleteDelegates = (id) => {
  return axiosInstance
    .delete(APIS.DELEGATES.concat(`/${id}`))
    .then((result) => {
      return result;
    });
};

export const getDoctorFaqs = (doctor_uuid) => {
  let url = APIS.GET_DOCTOR_FAQS.replace('{doctor_uuid}', doctor_uuid);
  return axiosInstance.get(url).then((result) => {
    return result;
  });
};

export const postDoctorFaqs = (payload) => {
  return axiosInstance.post(APIS.POST_DOCTOR_FAQS, payload).then((result) => {
    return result;
  });
};

export const downloadMedicalHistory = async (
  patient_uuid,
  appointment_notes_uuid,
  token
) => {
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_API_HOST}/patients/${patient_uuid}/medical-history/${appointment_notes_uuid}/print`,
      method: 'GET',
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteDoctor =(id) =>{
  let url = APIS.DELETE_DOCTOR.replace('{uuid}',id);
  return axiosInstance.delete(url).then((result)=>{
    return result;
  })
}

export const getDoctorsQuestions = (doctor_uuid) => {
  let url = APIS.GET_DOCTORS_QUESTIONS.replace('{doctor_uuid}', doctor_uuid);
  return axiosInstance.get(url).then((result) => {
    return result;
  });
};

export const getAllPatientAnsweredQuestions = (doctor_uuid,hospital_uuid) => {
  let url = APIS.GET_ALL_PATIENT_ANSWERED_QUESTIONS.replace('{doctor_uuid}', doctor_uuid);
  let url1 = url.replace('{hospital_uuid}', hospital_uuid);
  return axiosInstance.get(url1).then((result) => {
    return result;
  });
};
export const getIncludedDoctorQuestions = (doctor_uuid) => {
  let url = APIS.GET_INCLUDED_DOCTOR_QUESTIONS.replace('{doctor_uuid}', doctor_uuid);
  return axiosInstance.get(url).then((result) => {
    return result;
  });
};
export const getNotIncludedDoctorQuestions = (doctor_uuid) => {
  let url = APIS.GET_NOT_INCLUDED_DOCTOR_QUESTIONS.replace('{doctor_uuid}', doctor_uuid);
  return axiosInstance.get(url).then((result) => {
    return result;
  });
};

export const deleteDoctorQuestion = (uuid) => {
  let url = APIS.DELETE_DOCTOR_QUESTION.replace('{uuid}',uuid);
  return axiosInstance.delete(url).then((result) => {
    return result;
  });
};
export const postDoctorQuestion = (payload) => {
  return axiosInstance.post(APIS.POST_DOCTOR_QUESTION, payload).then((result) => {
    return result;
  });
};
export const postIncludedQuestions = (payload) => {
  return axiosInstance.post(APIS.POST_INCLUDED_QUESTIONS, payload).then((result) => {
    return result;
  });
};
export const updateDoctorQuestion = (uuid,data) => {
  let url = APIS.UPDATE_DOCTOR_QUESTION.replace('{uuid}',uuid);
  return axiosInstance.put(url,data).then((result) => {
    return result;
  });
};

export const changeDefault = (payload) => {
  return axiosInstance.post(APIS.CHANGE_DEFAULT_LOGIN, payload).then((result) => {
    return result;
  });
};

export const createNoteTemplete =(payload)=>{
  return axiosInstance.post(APIS.POST_NOTE_TEMPLATE, payload).then((result) => {
    return result;
  });
}
export const getNoteTemplates=(doctor_user_id)=>{
    let url = APIS.GET_NOTE_TEMPLATE.replace('{doctor_user_id}', doctor_user_id);
    return axiosInstance.get(url).then((result) => {
      return result;
    });
  };

export const getNoteTemplateById=(notetemplate_id)=>{
  let url = APIS.FETCH_NOTE_TEMPLATE_BY_ID.replace('{uuid}', notetemplate_id);
  return axiosInstance.get(url).then((result) => {
    return result;
  });

}

export const updateDoctorDelay = (uuid,values) => {
  let url = APIS.UPDATE_DOCTOR_DELAY.replace('{uuid}', uuid);
  return axiosInstance.put(url, values).then((result) => {
    return result;
  });
};

