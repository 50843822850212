import { UserOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Select, Space } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { Option } from 'antd/lib/mentions';
import { useState } from 'react';
import edit_white from '../../../assets/icons/edit_white.svg';

import './style.scss';

const ManagerDetails = ({managerData}) => {
  const [edit, setEdit] = useState(true);
  return (
    <div className="manager-details">
      {edit ? (
        <EditManagetDetails setEdit={setEdit} managerData={managerData}/>
      ) : (
        <ViewManagerDetails setEdit={setEdit} managerData={managerData}/>
      )}
    </div>
  );
};
export default ManagerDetails;

const EditManagetDetails = ({ setEdit,managerData }) => {
  return (
    <div>
      <div>
        <Avatar size={96} icon={<UserOutlined />} />
      </div>
      <div className="input-custom">
        <div className="form-label">Department Manager</div>
        <Row>
          <Col>
            <Select
              defaultValue="Ms"
              getPopupContainer={(trigger) => trigger.parentNode} // to avoid dropdown scrolling
            >
              <Option key="1" value="Ms">
                Ms
              </Option>
              <Option key="2" value="Mr">
                Mr
              </Option>
              <Option key="3" value="Mrs">
                Mrs
              </Option>
            </Select>
          </Col>
          <Col>
            <Input placeholder="Enter name" 
            value={managerData?.name}
             />
          </Col>
        </Row>
      </div>
      <div className="input-custom">
        <div className="form-label">Department Name</div>
        <Input placeholder="Enter department" 
        value={managerData?.department_name}
        />
      </div>
      <div className="input-custom">
        <div className="form-label">Email</div>
        <Input placeholder="Enter email" 
        value={managerData?.email}
         />
      </div>
      <div className="input-custom">
        <div className="form-label">Contact Number</div>
        <Input placeholder="Enter contact" 
        value={managerData?.contact_number}
        />
      </div>

      
    </div>
  );
};

const ViewManagerDetails = ({ setEdit,managerData }) => {
  return (
    <>
      <div>
        <Avatar size={96} icon={<UserOutlined />} />
      </div>
      <div className="label">Department Manager</div>
      <div className="entry">Dr. Manoj Tiwari</div>

      <div className="label">Department Name</div>
      <div className="entry">Cardiology</div>

      <div className="label">Email</div>
      <div className="entry">kritika1agrawal@gmail.com</div>

      <div className="label">Contact Number</div>
      <div className="entry">8855445566</div>

      <Button
        type="primary"
        icon={
          <img
            src={edit_white}
            alt=""
            height="18px"
            style={{ marginRight: '4px' }}
          />
        }
        onClick={() => setEdit(true)}
      >
        Edit details
      </Button>
    </>
  );
};
