import { useState, useEffect } from "react";
import { Col, Row } from "antd";
import { singleDoctor } from "../../redux/actions/doctorAction";
import { useParams } from "react-router";
import get from "lodash/get";
import { fetchAppointments } from "../../redux/actions/doctorAction";
import moment from "moment";
import { YEAR_MONTH_DAY } from "../../constants/constantsDateFormats";
import { Card, Pagination } from "antd";
import CustomTag from "../../components/tag/CustomTag";
import { useSelector } from "react-redux";
import './style.css';


const LineUpScreen = (props) => {
  const [doctorDetails, setDoctorDetails] = useState();
  const authDetails = useSelector((state) => get(state, "auth.user"));
  const [loading, setLoading] = useState(false);
  const [appointmentsResult, setAppointmentsResult] = useState({
    appointments: [],
    pagination: null,
  });
  const [filter, setFilter] = useState({
    page: 1,
    fromDate: moment().format(YEAR_MONTH_DAY),
    toDate: moment().format(YEAR_MONTH_DAY),
    days: 1,
    status: null,
    query: "",
  });

  const { id } = useParams();

  const getDoctorDetails = () => {
    singleDoctor(authDetails?.id)
      .then((result) => {
        setDoctorDetails(result);
      })
      .catch((error) => {});
  };

  const getLineups = () => {};

  useEffect(() => {
    getDoctorDetails();
    getLineups();
  }, []);

  useEffect(() => {
    getAppointments(
      get(filter, "page"),
      get(filter, "fromDate"),
      get(filter, "toDate"),
      get(filter, "days"),
      get(filter, "status"),
      get(filter, "query")
    );
  }, []);

  const getAppointments = (
    page,
    fromDate,
    toDate,
    days,
    status = null,
    query = ""
  ) => {
    setLoading(true);
    fetchAppointments(fromDate, toDate, status, page, query)
      .then((result) => {
        setLoading(false);
        setAppointmentsResult({
          appointments: get(result, "data"),
          pagination: get(result, "meta.pagination"),
        });
        setFilter({ page, fromDate, toDate, days, status, query });
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const handlePageChange = (page) => {
    setFilter((prevFilter) => ({ ...prevFilter, page }));
  };
  return (
    <Row gutter={[0, 24]}>
      {appointmentsResult.appointments.map((appointment, index) => (
        <Col
          span={24}
          key={index}
          className="main-appointments-lineup"
          style={{display: "flex",
            justifyContent: "center"}}
        >
          {" "}
          <Card
            title={
              <span>
                Appointment <b>{appointment?.appointment_id}</b>
              </span>
            }
            className="appointment-card"
            bordered={false}
          >
            <Row gutter={[16, 16]} wrap={false}>
              {" "}
              <Col flex="auto">
                <p>
                  <span className="column-label">Patient Name: </span>
                  <span style={{  }}>
                    {appointment.patient?.name}
                  </span>
                </p>
              </Col>
              <Col flex="auto">
                <p>
                  <span className="column-label">Date:</span>
                  <span className="column-appointment-value">{appointment.date}</span>
                </p>
              </Col>
              <Col flex="auto">
                <p>
                  <span className="column-label">Status:</span>
                  <span className="column-appointment-value">
                    <CustomTag status={get(appointment, "status.name")} />
                  </span>
                </p>
              </Col>
              <Col flex="auto">
                <p>
                  <span className="column-label">Visit Time:</span>
                  <span className="column-appointment-value">{appointment?.time}</span>
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
      ))}
      <Col span={24} className="appointment-pagination">
        {appointmentsResult.pagination && (
          <Pagination
            current={filter.page}
            total={appointmentsResult.pagination.total}
            onChange={handlePageChange}
          />
        )}
      </Col>
    </Row>
  );
};

export default LineUpScreen;
