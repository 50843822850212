import { Button, Col, Row, Spin, Form, Input, Select, Modal, message } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { DASHBOARD, FORGOT_PASSWORD, AVAILABILITY, LOGIN } from '../../constants/routes';
import { login, loginMoreRoles } from '../../redux/actions/authAction';
import { logout } from '../../redux/actions/authAction';

import get from 'lodash/get';
import login_illus from '../../assets/images/login_illus.svg';

import './style.scss';
import { validateMessages } from '../../constants/validation';
const { Option } = Select;

const LoginScreen = () => {
  const dispatch = useDispatch();
  let history = useHistory();

  const [loading, setLoading] = useState(false);
  const isLoggedIn = useSelector((state) => get(state, 'auth.isLoggedIn'));
  const user = useSelector((state) => get(state, 'auth.user'))
  const [openModal, setOpenModal] = useState(false);
  const [loginValues, setLoginValues] = useState();
  const [roles, setRoles] = useState();


  if (isLoggedIn) {
    if (user?.roles?.[0]?.value !== 7 && user?.roles?.[0]?.value !== 4) {
      return <Redirect to={DASHBOARD} />;
    } else {
      message.error("Doesn't have dashboard for this user")
      dispatch(logout())
        .then(() => {
          history.push(LOGIN);
        })
        .catch((error) => { });
      return <Redirect to={LOGIN} />
    }
  }

  const onFinish = (values) => {
    setLoading(true);
    setLoginValues(values);
    dispatch(login(values))
      .then((response) => {
        if (response?.user?.roles?.length > 1) {
          if (response?.user?.roles?.[0] !== null) {
            setLoading(false);
            setOpenModal(true);
            setRoles(response?.user?.roles);
          } else {
            setLoading(false);
            message.error("Doesn't have dashboard for this user")
          }
        }
        else {
          if (response?.user?.roles?.[0]?.value === 3 && response?.user?.is_default_login) {
            setLoading(false);
            history.push(AVAILABILITY);
          } else {
            setLoading(false);
            history.push(DASHBOARD);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleForgotPassword = () => {
    history.push(FORGOT_PASSWORD);
  };
  const changeRoleHandler = (value) => {
    let role = { name: value?.label, value: value?.value };
    setRoles(role);
  }
  const onClickSubmit = () => {
    dispatch(loginMoreRoles(loginValues, roles))
      .then((res) => {
      })

  }

  return (
    <div className="dashboard-auth-container">
      <div className="dashboard-inner-container">
        <Spin spinning={loading}>
          <Row>
            <Col xs={24} md={12} className='login-img'>
              <img src={login_illus} alt="" />
            </Col>
            <Col xs={24} md={12} className="form">
              <div className="title">Login</div>
              <Form
                name="basic"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                validateMessages={validateMessages}
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: 'email',
                    },
                  ]}
                >
                  <Input className="custom-input" />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                    },
                    {
                      min: 6,
                      message: 'Password must be atleast 6 characters',
                    },
                  ]}
                >
                  <Input.Password className="custom-input" />
                </Form.Item>
                <p
                  className="purple-info"
                  onClick={handleForgotPassword}
                  style={{ cursor: 'pointer', textAlign: 'end' }}
                >
                  Forgot password?
                </p>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="full-button"
                  >
                    Login
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Spin>

        {
          openModal ?
            <Modal
              open={openModal}
              centered
              onOk={setOpenModal}
              onCancel={setOpenModal}
              footer={null}
              closable={false}
            >
              <div style={{ marginBottom: "12px" }}>We found more roles for this email</div>
              <Select
                placeholder="Please Select Role "
                labelInValue
                style={{ width: "100%" }}
                onChange={changeRoleHandler}
              >
                <Option key={roles?.[0]?.value} value={roles?.[0]?.value}>
                  {roles?.[0]?.name}
                </Option>
                <Option key={roles?.[1]?.value} value={roles?.[1]?.value}>
                  {roles?.[1]?.name}
                </Option>
              </Select>

              <Button loading={loading} style={{ marginTop: "12px" }} type='primary' onClick={onClickSubmit}>submit</Button>
            </Modal>
            : null
        }
      </div>
    </div>
  );
};
export default LoginScreen;
