import {
  CloseOutlined,
  DownloadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Divider,
  Col,
  Row,
  Space,
  Button,
  Spin,
  Select,
  notification,
} from "antd";
import get from "lodash/get";
import "./style.scss";
import { useEffect, useState } from "react";
import { APPOINTMENT_STATUS_OPTIONS } from "../../../constants/constantRoles";
import { updateAppointmentStatus } from "../../../redux/actions/doctorAction";
import { UPDATE_APPOINTMENT_STATUS } from "../../../constants/messages";
const { Option } = Select;

const UpdateStatusForm = ({
  toggleUpdateStatusForm,
  appointment_details,
  appointment_uuid,
  getAppointments,
  filter,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedUpdateValue, setSelectedUpdateValue] = useState();
  const updateStatus = () => {
    setLoading(true);
    const payload = { status: selectedUpdateValue };
    updateAppointmentStatus(appointment_uuid, payload)
      .then((result) => {
        setLoading(false);
        notification.success({
          message: UPDATE_APPOINTMENT_STATUS,
        });
        toggleUpdateStatusForm(null);
        getAppointments(
          get(filter, "page"),
          get(filter, "fromDate"),
          get(filter, "toDate"),
          get(filter, "days"),
          get(filter, "status")
        );
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleSelectStatus = (value) => {
    setSelectedUpdateValue(value);
  };
  const handleUpdateButtonClick = () => {
    updateStatus();
  };
  return (
    <Spin spinning={loading}>
      <div>
        <Row justify="space-between">
          <Col className="drawer-title">Update Appointment Status</Col>
          <Col>
            <CloseOutlined onClick={toggleUpdateStatusForm} />
          </Col>
        </Row>
        <div className="drawer-content">
          <Row justify="space-between" style={{ marginBottom: "20px" }}>
            <Col>
              <div>{get(appointment_details, "date")}</div>
              <div className="small-grey">
                {get(appointment_details, "time")}
              </div>
            </Col>
            <Col>
              <DownloadOutlined style={{ fontSize: "20px" }} />
            </Col>
          </Row>
          <Row>
            <Space size={40} align="flex-start">
              <Col>
                <Avatar size={64} icon={<UserOutlined />} />
              </Col>
              <Col>
                <div>{get(appointment_details, "patient.name")}</div>
              </Col>
              <Col>
                <div>ID: {get(appointment_details, "appointment_id")}</div>
              </Col>
            </Space>
          </Row>
          <Divider />

          <div>
            <div className="current-label">
              <b>Select Status</b>
            </div>
            <div style={{ marginTop: "12px" }}>
              <Select
                defaultValue="Select Status"
                onChange={handleSelectStatus}
              >
                {Object.keys(APPOINTMENT_STATUS_OPTIONS).map((key) => (
                  <Option
                    key={key}
                    value={APPOINTMENT_STATUS_OPTIONS[key].value}
                  >
                    {APPOINTMENT_STATUS_OPTIONS[key].name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>

          <Button
            type="primary"
            style={{ marginTop: "12px" }}
            onClick={handleUpdateButtonClick}
          >
            Update
          </Button>
        </div>
      </div>
    </Spin>
  );
};

export default UpdateStatusForm;
