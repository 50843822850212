import { CloseOutlined } from '@ant-design/icons';
import { Col, Row, Space } from 'antd';
import get from 'lodash/get';

const AdminDetails = ({ toggleHospitalAdmin, details }) => {
  return (
    <div className="add-drawer-content">
      <Row justify="space-between">
        <Col className="drawer-title">Hospital Admin Details</Col>
        <Col>
          <CloseOutlined onClick={toggleHospitalAdmin} />
        </Col>
      </Row>
      <div className="add-drawer-form">
        <Space size="large" direction="vertical">
          <div>
            <div className="details-label">Hospital Admin Name</div>
            <div>
              {get(details, 'title')} {get(details, 'name')}
            </div>
          </div>
          <div>
            <div className="details-label">Email</div>
            <div>{get(details, 'email')}</div>
          </div>
          <div>
            <div className="details-label">Mobile number</div>
            <div>
              +{get(details, 'contact_code')} {get(details, 'contact_number')}
            </div>
          </div>
          <div>
            <div className="details-label">Contact Hours</div>
          </div>
        </Space>
      </div>
    </div>
  );
};

export default AdminDetails;
