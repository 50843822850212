import React from "react";
import { CloseCircleFilled, PlusOutlined } from "@ant-design/icons";
import {
  DatePicker,
  Row,
  Spin,
  Form,
  Button,
  Empty,
  message,
  Input,
  Select,
} from "antd";
import moment from "moment";
import get from "lodash/get";
import { useEffect, useState } from "react";
import { getClaims, postClaim,getClaimTypes,updateClaim } from "../../redux/actions/claimAction";
const { Option } = Select;

function RaiseClaimDetails({ appointment_details,onCloseClaim,getAppointments,filter }) {
  const dateFormat = "YYYY-MM-DD";
  const [loading, setLoading] = useState(false);
  const [claimTypes,setClaimTypes] = useState();
  const serviceTypes = {
    type1: ["seriveType1", "serviceType11", "serviceType111"],
    type2: ["serviceType2", "serviceType22", "serviceType222"],
    type3: ["serviceType3", "serviceType33", "serviceType333"],
  };
  // const serviceTypes = [{type:"S"},{type:"O"},{type:"P"}]

  const onFinish = (values) => {
    const itemsArr = [];
    for(let i=0;i<values?.items?.length;i++){
      const item={...values?.items[i],
        "date":moment(values?.items[i]?.date).format("YYYY-MM-DD")
      };
      itemsArr.push(item);
    }
    const formValues = {
      ...values,
      items:itemsArr,
      appointment_id: appointment_details?.appointment_id,
    };
    setLoading(true);
    postClaim(formValues)
      .then((result) => {
        setLoading(false);
        message.success("Created Successfully");
        onCloseClaim();
        getAppointments(
          get(filter, "page"),
          get(filter, "fromDate"),
          get(filter, "toDate"),
          get(filter, "days"),
          get(filter, "status"),
          get(filter, "query")
        );
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const [selectedType, setSelectedType] = useState();
  const onClickServiceType = (type) => {
    setSelectedType(type);
  };
  useEffect(()=>{
    getClaims().then((result)=>{
      console.log("result",result);
    }).catch((err)=>{
      console.log("ERR",err)
    })
    getClaimTypes().then((result)=>{
      setClaimTypes(result);
    }).catch((err)=>{
      console.log("Err",err);
    })
  },[])
  const onUpdate=(values)=>{
    const itemsArr = [];
    for(let i=0;i<values?.items?.length;i++){
      const item={...values?.items[i],
        "date":moment(values?.items[i]?.date).format("YYYY-MM-DD")
      };
      itemsArr.push(item);
    }
    const formValues = {
      ...values,
      items:itemsArr,
      appointment_id: appointment_details?.appointment_id,
    };
    setLoading(true);
    updateClaim(appointment_details?.claim?.id,formValues)
      .then((result) => {
        setLoading(false);
        message.success("Updated Successfully");
        onCloseClaim();
        getAppointments(
          get(filter, "page"),
          get(filter, "fromDate"),
          get(filter, "toDate"),
          get(filter, "days"),
          get(filter, "status"),
          get(filter, "query")
        );
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  return (
    <Spin spinning={loading}>
      <div className="add-drawer-content">
        <h1>Claim Details</h1>
        <div className="add-drawer-form">
          <Form
            name="basic"
            layout="vertical"
            onFinish={appointment_details?.claim?.id ? onUpdate : onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={appointment_details?.claim}
          >
            <Form.Item
              label="Type"
              name="type"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select className="select-code" onChange={onClickServiceType}>
                {Object.keys(serviceTypes)?.map((type) => {
                  return <Option value={type}>{type}</Option>;
                })}
              </Select>
               {/* <Select className="select-code" onChange={onClickServiceType}>
                {claimTypes?.map((claimType) => {
                  return <Option value={claimType?.type}>{claimType?.type}</Option>;
                })} 
              </Select> */}
            </Form.Item>
            <Form.Item
              label="Service Type"
              name="service_type"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select className="select-code">
                {selectedType &&
                  Object.keys(serviceTypes)?.map((type) => {
                    if (type === selectedType) {
                      return serviceTypes[type]?.map((serviceType) => {
                        return (
                          <Option value={serviceType}>{serviceType}</Option>
                        );
                      });
                    }
                  })}
              </Select>
              {/* <Select className="select-code" onChange={onClickServiceType}>
                {serviceTypes?.map((serviceType) => {
                  return <Option value={serviceType?.type}>{serviceType?.type}</Option>;
                })}
              </Select> */}
              {/* <Select className="select-code">
                {selectedType &&
                  Object.keys(serviceTypes)?.map((type) => {
                    if (type === selectedType) {
                      return serviceTypes[type]?.map((serviceType) => {
                        return (
                          <Option value={serviceType}>{serviceType}</Option>
                        );
                      });
                    }
                  })}
              </Select> */}
            </Form.Item>
            <Form.List name="items">
              {(items, { add, remove }) => (
                <div>
                  {items.map(({ name, fieldKey, key }, index) => {
                    return (
                      <>
                        <Form.Item
                          name={[index, "itemNumber"]}
                          label="itemNumber"
                          rules={[{ required: true,max:5 }]}
                        >
                          <div style={{ display: "flex" }}>
                            <Input
                              fieldKey={[fieldKey, "itemNumber"]}
                              placeholder="Enter Item Number"
                              defaultValue={
                                appointment_details?.claim?.items[index]?.itemNumber
                              }
                            />
                          </div>
                        </Form.Item>
                        <Form.Item
                          name={[index, "chargeAmount"]}
                          label="Charge Amount"
                          rules={[{ required: true }]}
                        >
                          <div style={{ display: "flex" }}>
                            <Input
                              fieldKey={[fieldKey, "chargeAmount"]}
                              placeholder="Enter Amount"
                              defaultValue={
                                appointment_details?.claim?.items[index]?.chargeAmount
                              }
                            />
                          </div>
                        </Form.Item>
                        <Form.Item
                          name={[index, "date"]}
                          label="Date"
                          rules={[{ required: true }]}
                        >
                          <DatePicker
                          fieldKey={[fieldKey, "date"]}
                          format={dateFormat}
                          />
                        </Form.Item>
                        <CloseCircleFilled
                          style={{ fontSize: "20px" }}
                          onClick={() => remove(name)}
                        />
                      </>
                    );
                  })}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Item
                    </Button>
                  </Form.Item>
                </div>
              )}
            </Form.List>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {appointment_details?.claim?.id ? "Update" : "Submit"}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Spin>
  );
}

export default RaiseClaimDetails;
