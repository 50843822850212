import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import map from 'lodash/map';
import get from 'lodash/get';
import { useRef, useState } from 'react';
import './style.scss';

const AvailabilitySlots = ({ availability }) => {
  return (
    <div className="availability-slots">
      {map(get(availability, 'slots'), (available_slot, i) => (
        <>
          {available_slot.time.length > 0 && (
            <div className="medium-bold">{get(available_slot, 'slotName')}</div>
          )}
          <GroupSlot available_slot={available_slot} i={i} />
        </>
      ))}
    </div>
  );
};

export default AvailabilitySlots;

const GroupSlot = ({ available_slot, i }) => {
  const [scrollFlag, setScrollFlag] = useState(false);
  const scrollContainerRef = useRef();

  const handleLeftScroll = () => {
    setScrollFlag(!scrollFlag);
    scrollContainerRef.current.scrollLeft += 150;
  };

  const handleRightScroll = () => {
    setScrollFlag(!scrollFlag);
    scrollContainerRef.current.scrollLeft -= 150;
  };
  return (
    <div className="row">
      <div
        style={
          scrollContainerRef.current &&
          scrollContainerRef.current.scrollWidth > 0
            ? null
            : { display: 'none' }
        }
      >
        <LeftOutlined
          style={{ fontSize: '12px' }}
          onClick={handleRightScroll}
        />
      </div>
      <div className="slots" ref={scrollContainerRef}>
        {map(available_slot.time, (slot) => (
          <div
            className="single-slot"
            style={{
              backgroundColor: get(slot, 'booked') ? 'grey' : 'white',
              color: get(slot, 'booked') ? 'white' : 'black',
            }}
          >
            {slot.slot_start}
          </div>
        ))}
      </div>
      <div
        style={
          scrollContainerRef.current &&
          scrollContainerRef.current.scrollLeft +
            scrollContainerRef.current.offsetWidth -
            scrollContainerRef.current.scrollWidth <
            0
            ? null
            : { display: 'none' }
        }
      >
        <RightOutlined
          style={{ fontSize: '12px' }}
          onClick={handleLeftScroll}
        />
      </div>
    </div>
  );
};
