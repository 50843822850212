import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, List, Row } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import get from 'lodash/get';
import { useState } from 'react';
import './style.scss';

const Notifications = ({ toggleNotificationDrawer }) => {
  const notifications = [
    {
      date: '13th July',
      content: '14 appointments booked in hospital with Dr. Jacob Jones ',
      time: 'Now',
    },
    {
      date: '13th July',
      content: '14 appointments booked in hospital with Dr. Jacob Jones ',
      time: 'Now',
    },
    {
      date: '10th July',
      content: '14 appointments booked in hospital with Dr. Jacob Jones ',
      time: '2days ago',
    },

    {
      date: '10th July',
      content: '14 appointments booked in hospital with Dr. Jacob Jones ',
      time: '2days ago',
    },
    {
      date: '9th July',
      content: '14 appointments booked in hospital with Dr. Jacob Jones ',
      time: '3days ago',
    },
  ];

  const [notifs, setNotifs] = useState([...notifications]);

  return (
    <div className="notifications-drawer">
      <Row justify="space-between">
        <Col className="drawer-title">Notifications</Col>
        <Col>
          <CloseOutlined onClick={toggleNotificationDrawer} />
        </Col>
      </Row>
      <List
        itemLayout="horizontal"
        dataSource={notifs}
        loadMore={LoadMore({ setNotifs, notifs })}
        renderItem={(item, index) => (
          <>
            {index === 0 ||
            (notifs[index - 1] &&
              notifs[index].date !== notifs[index - 1].date) ? (
              <div className="notif-date">{get(item, 'date')}</div>
            ) : null}
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar size={14} />}
                title={get(item, 'content')}
                description={get(item, 'time')}
              />
            </List.Item>
          </>
        )}
      />
      ,
    </div>
  );
};

export default Notifications;

const LoadMore = ({ setNotifs, notifs }) => {
  const seeMoreData = [
    {
      date: '6th July',
      content: '14 appointments booked in hospital with Dr. Jacob Jones ',
      time: '4days ago',
    },
    {
      date: '6th July',
      content: '14 appointments booked in hospital with Dr. Jacob Jones ',
      time: '4days ago',
    },
    {
      date: '6th July',
      content: '14 appointments booked in hospital with Dr. Jacob Jones ',
      time: '4days ago',
    },
  ];

  const loadMore = () => {
    setNotifs([...notifs, ...seeMoreData]);
  };

  return (
    <div className="see-more-button">
      <Button onClick={() => loadMore()}>see more</Button>
    </div>
  );
};
