import { CloseOutlined } from '@ant-design/icons';
import { Col, DatePicker, Row, Spin, Form, Button, Empty, notification } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
  doctorSlots,
  rescheduleAppointment,
} from '../../../redux/actions/doctorAction';
import './style.scss';
import SelectSlot from './SelectSlot';
import TextArea from 'antd/lib/input/TextArea';
import get from 'lodash/get';
import filterArray from 'lodash/filter';
import { validateMessages } from '../../../constants/validation';
import { RESCHEDULE_APPOINTMENT } from '../../../constants/messages';

const RescheduleForm = ({
  toggleReschedule,
  hospital_uuid,
  doctor_uuid,
  appointment_uuid,
  getAppointments,
  filter,
}) => {
  const date_format = 'YYYY-MM-DD';

  const [newDate, setNewDate] = useState(moment());
  const [newSlot, setNewSlot] = useState();
  const [slots, setSlots] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleDate = (date) => {
    if (date) {
      setNewDate(date);
    }
  };

  useEffect(() => {
    setLoading(true);
    const values = {
      date: newDate.format(date_format),
      hospital_uuid: hospital_uuid,
      doctor_uuid: doctor_uuid,
    };
    doctorSlots(values)
      .then((result) => {
        setLoading(false);
        const filteredSlots = filterArray(result, ['booked', false]);
        setSlots(filteredSlots);
        setNewSlot(filteredSlots[0].id);
      })
      .catch((error) => {
        setLoading(false);
      });
    //eslint-disable-next-line
  }, [newDate]);

  const reschedule = (payload) => {
    setLoading(true);
    rescheduleAppointment(appointment_uuid, payload)
      .then((result) => {
        setLoading(false);
        toggleReschedule();
        notification.success({
          message: (RESCHEDULE_APPOINTMENT + newDate.format(date_format) + '.'),
        });
        
        getAppointments(
          get(filter, 'page'),
          get(filter, 'fromDate'),
          get(filter, 'toDate'),
          get(filter, 'days'),
          get(filter, 'status'),
        );
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    const payload = {
      ...values,
      date: get(values, 'date').format(date_format),
      slot_id: newSlot,
    };
    reschedule(payload);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Spin spinning={loading}>
      <div className="add-drawer-content">
        <Row justify="space-between">
          <Col className="drawer-title">Reschedule Appointment</Col>
          <Col>
            <CloseOutlined onClick={toggleReschedule} />
          </Col>
        </Row>

        <div className="add-drawer-form">
          <Form
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            validateMessages={validateMessages}
            initialValues={{ date: newDate }}
          >
            <Form.Item
              label="Select a date"
              name="date"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker
                onChange={(date) => handleDate(date)}
                format={'DD-MM-YYYY'}
                disabledDate={(date) =>
                  moment().startOf('day').isSameOrAfter(date)
                }
              />
            </Form.Item>
            <Form.Item label="Choose a slot " name="choose_slot">
              {slots && slots.length > 0 ? (
                <SelectSlot
                  slots={slots}
                  setNewSlot={setNewSlot}
                  newSlot={newSlot}
                />
              ) : (
                <Empty description="No Slots Available" />
              )}
            </Form.Item>
            <Form.Item
              label="Give Reason"
              name="reschedule_reason"
              rules={[{ required: true, message: 'Please enter reason' }]}
            >
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Done
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Spin>
  );
};
export default RescheduleForm;
