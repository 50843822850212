import hospital_details from '../../assets/icons/hospital_details.svg';
import admin_contact from '../../assets/icons/admin_contact.svg';
import approve from '../../assets/icons/approve.svg';
import cancel from '../../assets/icons/cancel.svg';
import notes from '../../assets/icons/medicalhistory.svg';
import get from 'lodash/get';
import { Button, Col, Row, Space } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import CustomTag from '../../components/tag/CustomTag';
import AddressHelper from '../../utilities/addressHelper/AddressHelper';

export const requestsColumn = ({
  toggleHospitalDetails,
  toggleHospitalAdmin,
  toggleReject,
  toggleBilling,
  toggleUpdateBilling,
  toggleHospitalRejectMessage
}) => [
  {
    title: 'Hospital Name',
    key: 'hospital_name',
    fixed: 'left',
    render: (record) => 
      (
      <Row>
        <Space>
          <Col>
            <Avatar
              size={48}
              icon={<img src={record?.logo_url?.url} alt="" />}
            />
          </Col>
          <Col>
            <div className="medium">{get(record, 'name')}</div>
          </Col>
        </Space>
      </Row>
    )
  },
  {
    title: 'Email',
    key: 'email',
    render: (record) => {
      return <span>{get(record, 'admin.email')}</span>;
    },
  },
  {
    title: 'Date',
    key: 'date',
    render: (record) => {
      return <>{get(record, 'created_at').split(' ')[0]}</>;
    },
  },

  {
    title: 'Status',
    key: 'status',
    render: (record) => <CustomTag status={get(record, 'verified')} />,
  },
  {
    title: 'Location',
    key: 'location',
    render: (record) => <AddressHelper details={record} />,
    ellipsis: true,
  },

  {
    title: 'Actions',
    key: 'action',
    render: (record) => (
      <Row>
        <Space>
          <Button
            className="custom-action-button"
            onClick={() => {
              toggleUpdateBilling(record);
            }}
            type="text"
          >
            <>
              <img
                src={hospital_details}
                alt=""
                className="appointment-action-icon"
              />
              <p className="small">Update Billing</p>
            </>
          </Button>
          <Button
            className="custom-action-button"
            onClick={() => {
              toggleHospitalDetails(record);
            }}
            type="text"
          >
            <>
              <img
                src={hospital_details}
                alt=""
                className="appointment-action-icon"
              />
              <p className="small">Hospital Details</p>
            </>
          </Button>

          <Button
            className="custom-action-button"
            type="text"
            onClick={() => {
              toggleHospitalAdmin(get(record, 'admin'));
            }}
          >
            <img
              src={admin_contact}
              alt=""
              className="appointment-action-icon"
            />
            <p className="small">Admin Contact</p>
          </Button>

          <Button
            className="custom-action-button"
            type="text"
            onClick={() => toggleBilling(record)}
            style={{
              opacity:
                get(record, 'verified') === 2 || get(record, 'verified') === 1
                  ? 0.3
                  : 1,
            }}
            disabled={
              get(record, 'verified') === 2 || get(record, 'verified') === 1
                ? true
                : false
            }
          >
            <img src={approve} alt="" className="appointment-action-icon" />
            <p className="small">Approve</p>
          </Button>

          <Button
            className="custom-action-button"
            type="text"
            onClick={() => toggleReject(record)}
            style={{
              opacity:
                get(record, 'verified') === 2 || get(record, 'verified') === 1
                  ? 0.3
                  : 1,
            }}
            disabled={
              get(record, 'verified') === 1 || get(record, 'verified') === 2
                ? true
                : false
            }
          >
            <>
              <img src={cancel} alt="" className="appointment-action-icon" />
              <p className="small">Reject</p>
            </>
          </Button>

          <Button
            className="custom-action-button"
            type="text"
            onClick={() => toggleHospitalRejectMessage(record)}
            style={{
              display:
                get(record, 'verified') === 2 ? 'inline' : 'none'
            }}
          >
            <img
              src={notes}
              alt=""
              className="appointment-action-icon"
            />
            <p className="small">Rejection Notes</p>
          </Button>
        </Space>
      </Row>
    ),
  },
];
