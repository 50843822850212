import {
  Col,
  Radio,
  Row,
  Space,
  Spin,
  DatePicker,
  Input,
  Select,
  Table,
  Drawer,
} from 'antd';
import { useEffect, useState } from 'react';
import { filterApprovals, filterDays } from '../../constants/options';
import get from 'lodash/get';
import map from 'lodash/map';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import { getHospitals } from '../../redux/actions/hospitalAction';
import { requestsColumn } from '../../constants/table/requestsColumn';
import HospitalDetails from '../../components/drawerContent/hospitalDetails/HospitalDetails';
import AdminDetails from '../../components/drawerContent/adminDetails/AdminDetails';
import HospitalRejectionDetails from '../../components/drawerContent/hospitalRejectionDetails/HospitalRejectionDetails'
import requireAuth from '../../hocs/requireAuth';
import SimpleModal from '../../components/modals/simpleModal/SimpleModal';
import DoneModal from '../../components/modals/doneModal/DoneModal';
import ReasonModal from '../../components/modals/reasonModal/ReasonModal';
import './style.scss';
import {
  updateHospitalBilling,
  updateHospitalStatus,
} from '../../redux/actions/superAdminAction';
import assign from 'lodash/assign';
import BillingModal from '../../components/modals/billingModal/BillingModal';
import { YEAR_MONTH_DAY } from '../../constants/constantsDateFormats';
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;

const HospitalsScreen = () => {
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    page: 1,
    fromDate: '',
    toDate: '',
    days: null,
    status: null,
    query: '',
  });

  const [updateBillingModal, setUpdateBillingModal] = useState({
    visible: false,
    data: null,
  });
  const toggleUpdateBilling = (data = null) => {
    setUpdateBillingModal({
      visible: !get(updateBillingModal, 'visible'),
      data: data,
    });
    setHospitalId(get(data, 'id'));
  };

  const [hospitalId, setHospitalId] = useState();

  const [hospitalsResult, setHospitalsResult] = useState({
    hospitals: [],
    pagination: null,
  });

  const [approvePayload, setApprovePayload] = useState({
    hospitalId: '',
    payload: '',
  });

  const fetchHospitals = (
    page,
    fromDate,
    toDate,
    days,
    status = null,
    query = ''
  ) => {
    setLoading(true);
    getHospitals(fromDate, toDate, status, page, query)
      .then((result) => {
        setLoading(false);
        setHospitalsResult({
          hospitals: get(result, 'data'),
          pagination: get(result, 'meta.pagination'),
        });
        setFilter({ page, fromDate, toDate, days, status });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchHospitals(
      get(filter, 'page'),
      get(filter, 'fromDate'),
      get(filter, 'toDate'),
      get(filter, 'days'),
      get(filter, 'status'),
      get(filter, 'query')
    );

    //eslint-disable-next-line
  }, []);

  const rejectHospital = (reason) => {
    setLoading(true);
    const payload = { verified: 2, reject_reason: reason };
    updateHospitalStatus(hospitalId, payload)
      .then((result) => {
        setLoading(false);
        toggleDone('Rejected!');
        fetchHospitals(
          get(filter, 'page'),
          get(filter, 'fromDate'),
          get(filter, 'toDate'),
          get(filter, 'days'),
          get(filter, 'status'),
          get(filter, 'query')
        );
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleDays = (days) => {
    const toDate = moment().format(YEAR_MONTH_DAY);
    const fromDate = moment(moment()).subtract(days, 'd').format(YEAR_MONTH_DAY);
    fetchHospitals(
      get(filter, 'page'),
      fromDate,
      toDate,
      days,
      get(filter, 'status'),
      get(filter, 'query')
    );
  };

  const onSearch = (value) => {
    fetchHospitals(
      get(filter, 'page'),
      get(filter, 'fromDate'),
      get(filter, 'toDate'),
      get(filter, 'days'),
      get(filter, 'status'),
      value
    );
  };

  const handleRange = (dates) => {
    if (dates) {
      const toDate = moment(dates[1]).format(YEAR_MONTH_DAY);
      const fromDate = moment(dates[0]).format(YEAR_MONTH_DAY);
      fetchHospitals(
        get(filter, 'page'),
        fromDate,
        toDate,
        'null',
        get(filter, 'status'),
        get(filter, 'query')
      );
    }
  };

  const [hospitaldetailsDrawer, setHospitalDetailsDrawer] = useState({
    visible: false,
    edit: false,
    data: null,
  });

  const toggleHospitalDetails = (data = null) => {
    setHospitalDetailsDrawer({
      visible: !get(hospitaldetailsDrawer, 'visible'),
      edit: !get(hospitaldetailsDrawer, 'visible'),
      data,
    });
  };

  const [hospitalAdminDrawer, setHospitalAdminDrawer] = useState({
    visible: false,
    edit: false,
    data: null,
  });

  const toggleHospitalAdmin = (data = null) => {
    setHospitalAdminDrawer({
      visible: !get(hospitalAdminDrawer, 'visible'),
      edit: !get(hospitalAdminDrawer, 'visible'),
      data,
    });
  };

  const [hospitalRejectMessageDrawer, setHospitalRejectMessageDrawer] = useState({
    visible: false,
    edit: false,
    data: null,
  })
  const toggleHospitalRejectMessage = (data = null) => {
    setHospitalRejectMessageDrawer({
      visible: !get(hospitalRejectMessageDrawer, 'visible'),
      edit: !get(hospitalRejectMessageDrawer, 'visible'),
      data,
    });
  }

  const [rejectModal, setRejectModal] = useState(false);
  const toggleReject = (data = null) => {
    setRejectModal(!rejectModal);
    console.log('Data is', data);
    setHospitalId(get(data, 'id'));
  };

  const [approveModal, setApproveModal] = useState(false);
  const toggleApprove = () => {
    setApproveModal(!approveModal);
  };

  const [reasonModal, setReasonModal] = useState(false);
  const toggleReason = () => {
    setRejectModal(false);
    setReasonModal(!reasonModal);
  };

  const [billingModal, setBillingModal] = useState({
    visible: false,
    data: null,
  });
  const toggleBilling = (data = null) => {
    setBillingModal({ visible: !get(billingModal, 'visible'), data: data });
    setHospitalId(get(data, 'id'));
  };

  const [message, setMessage] = useState('');

  const [doneModal, setDoneModal] = useState(false);
  const toggleDone = (message) => {
    setApproveModal(false);
    setReasonModal(false);
    setMessage(message);
    setDoneModal(!doneModal);
  };

  const updateBilling = (values) => {
    updateHospitalBilling(hospitalId, values)
      .then((result) => {
        setLoading(false);
        toggleUpdateBilling();
        toggleDone('Billing Details Updated!');
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const approveBilling = (values) => {
    assign(values, { verified: 1 });
    setApprovePayload(values);
    toggleBilling(get(billingModal, 'data'));
    toggleApprove();
  };

  const approveHospital = () => {
    setLoading(true);
    updateHospitalStatus(hospitalId, approvePayload)
      .then((result) => {
        setLoading(false);
        toggleDone('Approved!');
        fetchHospitals(
          get(filter, 'page'),
          get(filter, 'fromDate'),
          get(filter, 'toDate'),
          get(filter, 'days'),
          get(filter, 'status'),
          get(filter, 'query')
        );
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <Spin spinning={loading}>
      <div className="requestScreen xs-mt-20">
        <Row justify="space-between" style={{ alignItems: 'baseline' }}>
          <Col  span={6}>
            <div className="screen-title">Hospitals</div>
          </Col>
          <Col  span={18}>
            <Row align="end" gutter={[10, 10]}>
              <Col>
                <Radio.Group
                  options={filterDays}
                  value={get(filter, 'days')}
                  optionType="button"
                  buttonStyle="solid"
                  onChange={(event) => handleDays(get(event, 'target.value'))}
                />
              </Col>
              <Col>
                <RangePicker
                  onChange={(dateStrings) => handleRange(dateStrings)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="search-doctor xs-mt-20">
        <Col xs={12} md={12} lg={6}>
            <Search
              placeholder="Hospital"
              className="search-input"
              allowClear
              enterButton={false}
              suffix={false}
              size="large"
              onSearch={onSearch}
              prefix={<SearchOutlined style={{ fontSize: '12px' }} />}
            />
          </Col>
          <Col xs={12} md={12} lg={18} style={{ textAlign: 'end' }}>
            <Select
              value={get(filter, 'status')}
              onChange={(value) =>
                fetchHospitals(
                  get(filter, 'page'),
                  get(filter, 'fromDate'),
                  get(filter, 'toDate'),
                  get(filter, 'days'),
                  value,
                  get(filter, 'query')
                )
              }
            >
              {map(filterApprovals, (filter, i) => (
                <Option key={i} value={get(filter, 'value')}>
                  {get(filter, 'label')}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Table
          columns={requestsColumn({
            toggleReject,
            toggleBilling,
            toggleHospitalDetails,
            toggleHospitalAdmin,
            toggleUpdateBilling,
            toggleHospitalRejectMessage
          })}
          dataSource={get(hospitalsResult, 'hospitals')}
          scroll={{ x: true }}
          pagination={{
            pageSize: get(hospitalsResult, 'pagination.per_page', 1),
            current: get(hospitalsResult, 'pagination.current_page', 1),
            total: get(hospitalsResult, 'pagination.total', 1),
            onChange: (val) => {
              fetchHospitals(
                val,
                get(filter, 'fromDate'),
                get(filter, 'toDate'),
                get(filter, 'days'),
                get(filter, 'status'),
                get(filter, 'query')
              );
            },

            position: ['bottomLeft'],
          }}
        />
        <Drawer
          className="hospital-details-drawer main-drawer"
          destroyOnClose
          //width="70%"
          closable={false}
          onClose={() => toggleHospitalDetails(null)}
          open={get(hospitaldetailsDrawer, 'visible')}
        >
          <HospitalDetails
            toggleHospitalDetails={toggleHospitalDetails}
            details={get(hospitaldetailsDrawer, 'data')}
          />
        </Drawer>
        <Drawer
          className="main-drawer"
          destroyOnClose
          closable={false}
          onClose={() => toggleHospitalAdmin(null)}
          open={get(hospitalAdminDrawer, 'visible')}
        >
          <AdminDetails
            toggleHospitalAdmin={toggleHospitalAdmin}
            details={get(hospitalAdminDrawer, 'data')}
          />
        </Drawer>
        <Drawer
          className="main-drawer"
          destroyOnClose
          width={350}
          closable={false}
          onClose={() => toggleHospitalRejectMessage(null)}
          open={get(hospitalRejectMessageDrawer, 'visible')}
        >
          {console.log("data", get(hospitalRejectMessageDrawer, 'data'))}
          <HospitalRejectionDetails
            toggleHospitalRejectMessage={toggleHospitalRejectMessage}
            details={get(hospitalRejectMessageDrawer, 'data')}
          />
        </Drawer>
        <SimpleModal
          okText="Yes"
          closable={false}
          centered={true}
          visible={rejectModal}
          onCancel={toggleReject}
          onOk={toggleReason}
          text="Reject?"
        />
        <SimpleModal
          okText="Yes"
          closable={false}
          centered={true}
          visible={approveModal}
          onCancel={toggleApprove}
          onOk={() => approveHospital()}
          text="Approve?"
        />
        <ReasonModal
          visible={reasonModal}
          onDone={rejectHospital}
          onCancel={() => toggleReason()}
          title="Reason to Reject?"
        />
        <BillingModal
          onFinish={updateBilling}
          title="Update billing info"
          modal={updateBillingModal}
          okText="Update"
          onCancel={toggleUpdateBilling}
        />
        <BillingModal
          onFinish={approveBilling}
          title="Send billing info"
          modal={billingModal}
          okText="Approve"
          onCancel={toggleBilling}
        />
        <DoneModal
          message={message}
          visible={doneModal}
          toggleDone={toggleDone}
        />
      </div>
    </Spin>
  );
};

export default requireAuth(HospitalsScreen, [1]);
