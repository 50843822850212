import { CloseCircleFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Row, Col } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import { getNoteTemplateById } from "../../../redux/actions/doctorAction";
import { useForm } from "antd/lib/form/Form";


const initialNotes = [
  // Add your initial notes here
  {
    heading: "Note 1 Heading",
    note: "Note 1 Content",
  },
  {
    heading: "Note 2 Heading",
    note: "Note 2 Content",
  },
];
let intivalues = [];

const NotesDynamicForm = ({ selectedTemplate, noteTemplates,form }) => {
  // const [form] = useForm();

  const [noteTemplate, setNoteTemplate] = useState();
  // useEffect(()=>{
  //   if (selectedTemplate !== null) {
  //     const filteredNotes = noteTemplates?.filter((noteTemplate) => {
  //       return noteTemplate?.uuid == selectedTemplate?.key;
  //     });
  //     console.log("bindu", filteredNotes);
  //     if (filteredNotes?.length > 0) {
  //       let obj = {
  //         heading: filteredNotes?.[0]?.template_name,
  //         note: filteredNotes?.[0]?.notetemplate,
  //       };
  //       setNoteTemplate(obj);
  //       console.log("hello", obj,[obj]);
  //       intivalues = [obj];
  //     }
  //   }

  // },[selectedTemplate])

  useEffect(()=>{
    if(selectedTemplate !== null && selectedTemplate !== undefined){
    getNoteTemplateById(selectedTemplate?.key).then((result)=>{
      let obj = {
        heading: result?.template_name,
        note: result?.notetemplate,
      };
      setNoteTemplate(obj);
      console.log("obj", obj,[obj]);
      intivalues = [obj];
      form.setFieldValue("notes",[obj]);
      // form.setFieldValue("attachments",[]);
    })
  }

  },[selectedTemplate?.key])

  return (
    // <Form 
    // // form={form}
    // >
      <Form.List
        name="notes"
        // initialValue={selectedTemplate !== null && intivalues}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <div key={key}>
                <Row align="space-between">
                  <Col span={22}>
                    <Form.Item
                      name={[name, "heading"]}
                      fieldKey={[fieldKey, "heading"]}
                      rules={[
                        {
                          required: true,
                          message: "Please enter the heading for the note!",
                        },
                      ]}
                    >
                      <Input placeholder="Heading" className="custom-input" />
                    </Form.Item>

                    <Form.Item
                      name={[name, "note"]}
                      fieldKey={[fieldKey, "note"]}
                      rules={[
                        {
                          required: true,
                          message: "Please enter the content for the note!",
                        },
                      ]}
                    >
                      <TextArea placeholder="Content" rows="6" />
                    </Form.Item>
                  </Col>
                  <Col>
                    <CloseCircleFilled
                      style={{ fontSize: "18px" }}
                      onClick={() => remove(name)}
                    />
                  </Col>
                </Row>
              </div>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                icon={<PlusOutlined />}
              >
                Add Note
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    // </Form>
  );
};

export default NotesDynamicForm;
