import { Calendar, Badge, Row, Space, Spin,Button } from 'antd';
import moment from 'moment';
import get from 'lodash/get';
import find from 'lodash/find';
import map from 'lodash/map';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getMonthAvailability } from '../../redux/actions/doctorAction';
import AvailabilityDrawer from '../../components/drawer/AvailabilityDrawer';
import MarkUnavailability from '../../components/drawer/MarkUnavailability';
import './style.scss';
import Avatar from 'antd/lib/avatar/avatar';
import dayjs from 'dayjs';
import { YEAR_MONTH_DAY } from '../../constants/constantsDateFormats';

const AvailabilityCalendar = ({ selectedDoctorId }) => {
  const [loading, setLoading] = useState(false);
  const [availability, setAvailability] = useState([]);
  const startOfMonth = moment().startOf('month');
  const endOfMonth = moment().endOf('month');
  const currentHospital = useSelector((state) =>
    get(state, 'auth.currentHospital')
  );
  const [value, setValue] = useState(() => dayjs(new Date()));
  const [selectedValue, setSelectedValue] = useState(() => dayjs(new Date()));

  const user = useSelector((state) => get(state, 'auth.user'));

  const currentDate = moment().format('MMM Do YY');

  moment.updateLocale('en', {
    weekdaysMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  });

  const getData = (date) => {
    let className = 'calendar-success';
    if (date.isBefore(startOfMonth) || date.isAfter(endOfMonth)) {
      return {
        data: [],
        className: '',
      };
    }
    let dayAvailablity = find(availability, [
      'date',
      date.format(YEAR_MONTH_DAY),
    ]);

    let data = [];
    const appointments = get(dayAvailablity, 'appointments', []).length;

    if (get(dayAvailablity, 'slots', []).length === 0) {
      if (!date.isBefore(moment(), 'day')) {
        className = 'calendar-no-slots';
      }
    }
    if (get(dayAvailablity, 'slots', []).length > 0) {
      if (!date.isBefore(moment(), 'day')) {
        data.push({
          type: 'processing',
          content: `${get(dayAvailablity, 'slots', []).length} Slots`,
        });
      }
    }
    if (appointments === 0) {
      className = 'calendar-no-slots';
      data.push({
        type: 'success',
        content: `No Appointments`,
      });
    }
    if (appointments > 0) {
      if (appointments < 5) {
        className = 'calendar-less-5';
      } else if (appointments < 10) {
        className = 'calendar-less-10';
      } else {
        className = 'calendar-more-10';
      }

      data.push({
        type: 'success',
        content: `${appointments} Appointments `,
      });
    }
    if (get(dayAvailablity, 'leaves')) {
      let message = get(dayAvailablity, 'leaves.whole_day')
        ? `You are unavailable for whole day`
        : `You are unavailable from ${map(
            get(dayAvailablity, 'leaves.leave_hours', []),
            (lh) => `${get(lh, 'from_time')}-${get(lh, 'to_time')}`
          )}`;
      className = 'calendar-warning';
      data.push({
        type: 'warning',
        content: message,
      });
    }
    return {
      data,
      className,
    };
  };

  function dateFullCellRender(value) {
    const { data, className } = getData(value);
    return (
      <div
        className={`ant-picker-cell-inner ant-picker-calendar-date ${className}`}
      >
        <div className="ant-picker-calendar-date-value">{`${value.date()}`}</div>
        <div className="ant-picker-calendar-date-content">
          <ul className="events">
            {data.map((item) => (
              <li key={item.content}>
                <Row>
                  <Space style={{ alignItems: 'flex-start' }}>
                    <Badge status={item.type} />
                    <span className="small">{item.content}</span>
                  </Space>
                </Row>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }

  const fetchMonthlyAvailability = () => {
    setLoading(true);
    getMonthAvailability({
      doctor_uuid: selectedDoctorId ? selectedDoctorId : get(user, 'id'),
      hospital_uuid: get(currentHospital, 'id'),
      date:selectedValue?.format(YEAR_MONTH_DAY)
    })
      .then((result) => {
        setAvailability(result);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(()=>{
    getMonthAvailability({
      doctor_uuid: selectedDoctorId ? selectedDoctorId : get(user, 'id'),
      hospital_uuid: get(currentHospital, 'id'),
      date:selectedValue?.format(YEAR_MONTH_DAY)
    })
      .then((result) => {
        setAvailability(result);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  },[selectedValue]);
  
 
  const onSelect = (newValue) => {
    setValue(newValue);
    setSelectedValue(newValue);
  };

  const onPanelChange = (newValue) => {
    setValue(newValue);
  }; 

  return (
    <Spin spinning={loading}>
      <div className="avaibility-calendar-screen">
      <Row justify="space-between" gutter={16}>
                <div className="screen-title">Availability Calendar</div>
                <Space>
                  <MarkUnavailability
                    refreshCalendar={fetchMonthlyAvailability}
                    selectedDoctorId={selectedDoctorId}
                    availability={availability}
                  />
                  <AvailabilityDrawer
                    refreshCalendar={fetchMonthlyAvailability}
                    selectedDoctorId={selectedDoctorId}
                  />
                </Space>
              </Row>
              <Row>
                <Space size={20}>
                  <Row>
                    <Space>
                      <Avatar
                        shape="square"
                        size={12}
                        className="leave-indication"
                      />
                      <div className="small-grey">Leave/Unavailable</div>
                    </Space>
                  </Row>
                  <Row>
                    <Space>
                      <Avatar
                        shape="square"
                        size={12}
                        className="less-5-appointments-indication"
                      />
                      <div className="small-grey">Appointments less than 5</div>
                    </Space>
                  </Row>
                  <Row>
                    <Space>
                      <Avatar
                        shape="square"
                        size={12}
                        className="less-10-appointments-indication"
                      />
                      <div className="small-grey">
                        Appointments less than 10
                      </div>
                    </Space>
                  </Row>
                  <Row>
                    <Space>
                      <Avatar
                        shape="square"
                        size={12}
                        className="more-10-appointments-indication"
                      />
                      <div className="small-grey">
                        Appointments more than 10
                      </div>
                    </Space>
                  </Row>
                </Space>
              </Row>
        <Calendar
          dateFullCellRender={dateFullCellRender}
          // validRange={[startOfMonth, endOfMonth]}
          onSelect={onSelect} 
          onPanelChange={onPanelChange}
          // headerRender={() => (
          //   <>
          //     {/* <div className="calendar-month">{currentDate}</div> */}
          //   </>
          // )}
        />
      </div>
    </Spin>
  );
};

export default AvailabilityCalendar;
