import { Button, Col, Row, Form, Input, Spin } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';

import create_account_illus from '../../assets/images/Sign Up-illustration.svg';
import { useState } from 'react';
import { validateMessages } from '../../constants/validation';

const CreateAccountScreen = () => {
  const [loading, setLoading] = useState(false);
  const onFinish = (values) => {
    setLoading(true);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <Spin spinning={loading}>
      <div className="dashboard-auth-container">
        <div className="dashboard-inner-container">
          <Row>
            <Col xs={24} md={12} className='login-img'>
              <img src={create_account_illus} alt="" />
            </Col>
            <Col xs={24} md={12} className="form">
              <div className="title">Create your Account</div>

              <Form
                name="basic"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                validateMessages={validateMessages}
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: 'email',
                    },
                  ]}
                >
                  <Input className="custom-input" />
                </Form.Item>
                <Form.Item
                  label="Create Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                    },
                    {
                      min: 6,
                      message: 'Password must be atleast 6 characters',
                    },
                  ]}
                >
                  <Input.Password className="custom-input" />
                </Form.Item>
                <Form.Item
                  label=" Confirm Password"
                  name="confirm_password"
                  dependencies={['password']}
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            'The two passwords that you entered do not match!',
                          ),
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password className="custom-input" />
                </Form.Item>
                <Form.Item>
                  <Checkbox>
                    <span className="grey-info" style={{color:'#5F5F5F99'}}>I accept the</span>&nbsp; &nbsp;
                    <span className="purple-info">Terms and Conditions</span>
                  </Checkbox>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="full-button"
                  >
                    Sign Up
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </Spin>
  );
};
export default CreateAccountScreen;
