import send_invoice from "../../assets/icons/send_invoice.svg";
import send_reminder from "../../assets/icons/send_reminder.svg";
import get from "lodash/get";
import { Avatar, Button, Col, Row, Space } from "antd";
import CustomTag from "../../components/tag/CustomTag";
import {
  DeleteOutlined,
} from "@ant-design/icons";

export const MediaAndAdsColumn = ({
  deleteTv,
  toggleViewAds,
  toggleEdit,
  toggleLineUp
}) => [
  {
    title: "Tv Name",
    key: "tv_name",
    render: (record) => (
      <Row>
        <Space>
          <Col>
            <Avatar
              size={48}
              icon={<img src={record?.tv_image?.url} alt="" />}
            />
          </Col>
          <Col>
            <div className="medium">{get(record, "tv_name")}</div>
          </Col>
        </Space>
      </Row>
    ),
  },
  
  {
    title: "Status",
    key: "is_active",
    render: (record) => <CustomTag status={record?.is_active=== true ? "Active" : "In Active"} />,
  },
  {
    title: "Actions",
    key: "action",
    render: (record) => (
      <Row>
        <Space>
          <Button
            className="custom-action-button"
            onClick={() => {
              toggleViewAds(record?.id);
            }}
            type="text"
          >
            <>
              <img
                src={send_invoice}
                alt=""
                className="appointment-action-icon"
              />
              <p className="small">View Ads</p>
            </>
          </Button>

          <Button
            className="custom-action-button"
            type="text"
            onClick={() => toggleEdit(record?.uuid)}
          >
            <img
              src={send_reminder}
              alt=""
              className="appointment-action-icon"
            />
            <p className="small">Edit</p>
          </Button>
          <Button
            className="custom-action-button"
            type="text"
            onClick={() => toggleLineUp(record)}
          >
            <img
              src={send_reminder}
              alt=""
              className="appointment-action-icon"
            />
            <p className="small">Lineup</p>
          </Button>
          <>
            <Button
              className="custom-action-button"
              type="text"
              onClick={() => {
                deleteTv(record?.uuid);
              }}
              icon={<DeleteOutlined />}
            >
              <p className="small">Delete</p>
            </Button>
          </>
        </Space>
      </Row>
    ),
  },
];
