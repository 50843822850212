import './style.scss';

const ColorPicker = ({ setBrandColor, brandColor }) => {
  const ItemChecked = (e) => {
    setBrandColor(e.target.value);
  };

  return (
    <>
      <div>
        <label>
          <div className="result" style={{ backgroundColor: brandColor }}>
            {brandColor ? null : '+'}
          </div>
          <input type="color" id="color-picker" onChange={ItemChecked} />
        </label>
      </div>
    </>
  );
};

export default ColorPicker;
