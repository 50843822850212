import { Button, Tooltip } from 'antd';
import './style.scss';
import Avatar from 'antd/lib/avatar/avatar';

import edit_white from '../../../../assets/icons/edit_white.svg';
import map from 'lodash/map';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import AddressHelper from '../../../../utilities/addressHelper/AddressHelper';
import infoCircle from '../../../../assets/icons/info-circle.svg';

const ViewDetails = ({ details, setEditDetails }) => {
  const role = useSelector((state) => get(state, 'auth.roles'));

  return (
    <>
      <div className="doctor-image">
        <Avatar
          size={96}
          icon={<img src={get(details?.image, 'url')} alt="profile" />}
        />
      </div>
      <div className="label">Doctor's Name</div>
      <div className="entry">
        {get(details, 'title')} {get(details, 'name')}
      </div>

      <div className="label">Email</div>
      <div className="entry">{get(details, 'email')}</div>

      <div className="label">Contact Number</div>
      <div className="entry">
        {'+'}
        {get(details, 'contact_code')} {get(details, 'contact_number')}
      </div>

      <div className="label">Speciality</div>

      {map(get(details, 'specialisations'), (specialisation, i) => (
        <span className="entry" key={i}>
          {' '}
          {get(specialisation, 'name')}{' '}
        </span>
      ))}

      <div className="label">Experience</div>
      <div className="entry">
        {get(details, 'doctor_detail.experience')} years
      </div>

      <div className="label">Qualification</div>
      <div className="entry">
        {get(details, 'doctor_detail.qualifications')}
      </div>

      <div className="label">
        <div className="feature-label">
          <div>Featured</div>
          <Tooltip
            placement="rightTop"
            title={
              'These will be the highlights of your achievements and would be visible to the patients along with your details'
            }
            className="tool-tip"
          >
            <img src={infoCircle} className="info-circle" alt="" />
          </Tooltip>
        </div>
      </div>
      <ul>
        {map(get(details, 'doctor_detail.features'), (feature) => (
          <li className="entry">{feature}</li>
        ))}
      </ul>

      <div className="label">About</div>
      <div className="entry">{get(details, 'doctor_detail.about')}</div>

      <div className="label">Address</div>
      <div className="entry">{details?.doctor_detail && JSON.parse(details?.doctor_detail?.address_1)?.selectedAddress}</div>
      {role.includes(3) && (
        <Button
          type="primary"
          icon={
            <img
              src={edit_white}
              alt=""
              height="18px"
              style={{ marginRight: '4px' }}
            />
          }
          onClick={() => setEditDetails(true)}
        >
          Edit details
        </Button>
      )}
    </>
  );
};

export default ViewDetails;
