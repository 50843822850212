import { Button, Form, Input, Row, Col, Select, Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";
const { Option } = Select;

const NoteTemplates = ({ setSelectedTemplate, noteTemplates }) => {
  const changeHandler = (name, value) => {
    setSelectedTemplate(name);
  };
  return (
    <Form>
      <Form.Item  name="note_template">
        { noteTemplates?.length > 0 ? 
          <Select
          placeholder="Select Note Template"
          labelInValue
          style={{ width: "100%" }}
          onChange={changeHandler}
        >
          {noteTemplates?.map((noteTemplate) => {
            return (
              <Option key={noteTemplate?.uuid} value={noteTemplate?.uuid}>
                {noteTemplate?.template_name}
              </Option>
            );
          })}
        </Select>
        : null
        // <Spin/>
}
      </Form.Item>
    </Form>
  );
};

export default NoteTemplates;
