import map from 'lodash/map';
import get from 'lodash/get';
import remove from 'lodash/remove';
import round from 'lodash/round';
import assign from 'lodash/assign';

// export const replaceMultiple = (string, mapObj) => {
//   const re = new RegExp(Object.keys(mapObj).join('|'), 'gi');
//   string = string.replace(re, (matched) => mapObj[matched]);
//   return string;
// };

export const replaceMultiple = (string, mapObj) => {
  const re = new RegExp(Object.keys(mapObj).join('|'), 'gi');
  string = string.replace(re, (matched) => mapObj[matched]);
  return string;
};

// export const getUrl = (apipath, params) => {
//   let paramString = '';
//   let firstparam = true;
//   if (params) {
//     paramString += '?';
//     map(params, (value, key) => {
//       if (firstparam) {
//         paramString += `${key}=${value}`;
//         firstparam = true;
//       } else {
//         paramString += `&${key}=${value}`;
//       }
//     });
//   }
//   return `${apipath}${paramString}`;
// };

export const getUrl = (apipath, params) => {
  let paramString = '';
  let firstparam = true;
  if (params) {
    paramString += '?';
    map(params, (value, key) => {
      if (firstparam) {
        paramString += `${key}=${value}`;
        firstparam = false;
      } else {
        paramString += `&${key}=${value}`;
      }
    });
  }
  return `${apipath}${paramString}`;
};

export const percentUtility = (object) => {
  remove(object, (app) => get(app, 'status.value') === 0);
  var total = 0;
  for (let obj of object) {
    total = total + get(obj, 'count');
  }

  if (total === 0) {
    return {
      total: 0,
      done: 0,
      cancel: 0,
      noshow: 0,
      donePercent: 0,
      cancelPercent: 0,
      noshowPercent: 0,
    };
  }
  var percentObj = { total: total };

  for (let obj of object) {
    if (get(obj, 'status.value') === 3) {
      assign(percentObj, { donePercent: getPercent(get(obj, 'count'), total) });
      assign(percentObj, { done: get(obj, 'count', total) });
    }
    if (get(obj, 'status.value') === 4) {
      assign(percentObj, {
        cancelPercent: getPercent(get(obj, 'count', 0), total),
      });
      assign(percentObj, { cancel: get(obj, 'count') });
    }
    if (get(obj, 'status.value') === 2) {
      assign(percentObj, {
        noshowPercent: getPercent(get(obj, 'count'), total),
      });
      assign(percentObj, { noshow: get(obj, 'count') });
    }
  }

  return percentObj;
};
const getPercent = (count, total) =>
  isNaN(round((count / total) * 100, 2))
    ? '0'
    : round((count / total) * 100, 2);
