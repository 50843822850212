import { Card, Spin, Tabs } from 'antd';
import Faqs from '../../components/faqs/Faqs';
import requireAuth from '../../hocs/requireAuth';
const { TabPane } = Tabs;

const SettingsScreen = () => {
  return (
    <Spin spinning={false}>
      <div className="screen-title">Settings</div>
      <div className="tab-section">
        <Card>
          <Tabs defaultActiveKey="1">
            <TabPane tab="FAQs" key="1">
              <Faqs />
            </TabPane>
          </Tabs>
        </Card>
      </div>
    </Spin>
  );
};

export default requireAuth(SettingsScreen, [3]);
