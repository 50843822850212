import { useState } from 'react';
import {
  Form,
  Switch,
  Button,
  notification,
  Spin,
  DatePicker,
  TimePicker,
  Space,
  Input,
} from 'antd';
import moment from 'moment';
import map from 'lodash/map';
import get from 'lodash/get';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { markUnavailability,deleteDoctorLeave } from '../../redux/actions/doctorAction';
import { MARK_UNAVIALABILITY_SUCCESS,MARK_AVIALABILITY_SUCCESS } from '../../constants/messages';
import { useSelector } from 'react-redux';
import { HOUR_MIN, YEAR_MONTH_DAY } from '../../constants/constantsDateFormats';

const MarkUnavailablityForm = ({ onSuccess, edit, selectedDoctorId,dayAvailablity }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [showDynamicForm, setDynamicForm] = useState(true);
  const user = useSelector((state) => get(state, 'auth.user'));

  const generatePayload = (values) => {
    return {
      date: moment(get(values, 'date')).format(YEAR_MONTH_DAY),
      whole_day: get(values, 'whole_day'),
      reason: get(values, 'reason'),
      hours: map(get(values, 'hours'), (h) => {
        let obj = {
          from_time: get(h, 'from_time').format(HOUR_MIN),
          to_time: get(h, 'to_time').format(HOUR_MIN),
        };
        return obj;
      }),
    };
  };

  const onFinish = (values) => {
    setLoading(true);
    let payload = generatePayload(values);
    markUnavailability(
      payload,
      selectedDoctorId ? selectedDoctorId : get(user, 'id')
    )
      .then(() => {
        setLoading(false);
        notification.success({
          message: (MARK_UNAVIALABILITY_SUCCESS),
        });
        onSuccess();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = () => {};
  const markAvailability=(leave_uuid,doctor_uuid)=>{
    setLoading(true);
    deleteDoctorLeave(doctor_uuid,leave_uuid).then(()=>{
      setLoading(false);
      notification.success({
        message: (MARK_AVIALABILITY_SUCCESS),
      });
      onSuccess();
    })
    .catch(()=>{
      setLoading(false);
    })
  }

  return (
    <Spin spinning={loading}>
      {dayAvailablity?.leaves?.id ?
      <Button type="primary" onClick={()=>markAvailability(dayAvailablity?.leaves?.uuid,dayAvailablity?.leaves?.hospital_user_id)}>Mark Availability</Button>
      :
      <Form
        form={form}
        layout="vertical"
        scrollToFirstError
        onFinish={onFinish}
        initialValues={
          edit
            ? {}
            : {
                whole_day: false,
                hours: [undefined],
              }
        }
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Date"
          name="date"
          rules={[{ required: true, message: 'Please select date' }]}
        >
          <DatePicker
            size="large"
            placeholder="Date"
            disabledDate={(date) => moment().startOf('day').isSameOrAfter(date)}
            format="DD-MM-YYYY"
          />
        </Form.Item>

        <Form.Item label="Mark Unavailable for whole day" name="whole_day">
          <Switch
            onChange={(e) => {
              setDynamicForm(!e);
            }}
          />
        </Form.Item>

        {showDynamicForm && (
          <Form.List name="hours">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                  <Space
                    key={key}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'from_time']}
                      label="From time"
                      fieldKey={[fieldKey, 'from_time']}
                      rules={[{ required: true, message: 'Missing from time' }]}
                    >
                      <TimePicker
                        minuteStep={15}
                        format={'HH:mm'}
                        size="large"
                        placeholder="From time"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="To time"
                      name={[name, 'to_time']}
                      fieldKey={[fieldKey, 'to_time']}
                      rules={[
                        { required: true, message: 'Missing To  time' },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            let time = getFieldValue('hours')[index];

                            if (
                              get(time, 'from_time').isSameOrAfter(
                                get(time, 'to_time')
                              )
                            ) {
                              return Promise.reject(
                                new Error(
                                  'From time  should be greater than To time!'
                                )
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <TimePicker
                        minuteStep={15}
                        format={'HH:mm'}
                        placeholder="To time"
                        size="large"
                      />
                    </Form.Item>
                    <Form.Item label=" ">
                      {index > 0 && (
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      )}
                    </Form.Item>
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add Time slot
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        )}
        <Form.Item
          label="Give Reason"
          name="reason"
          rules={[{ required: true, message: 'Please enter reason' }]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
}
    </Spin>
  );
};

export default MarkUnavailablityForm;
