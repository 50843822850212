// import { useParams } from 'react-router-dom';
import { Button, Spin, Form, Input } from "antd";
import { ContactCodeSelector } from "../../components/contactCodeSelector/ContactCodeSelector";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import get from "lodash/get";
// import './style.scss';
import { logout } from "../../redux/actions/authAction";
import { useParams } from "react-router-dom";
import { getHospitalAgentInvitation,validateHospitalAgentLink } from "../../redux/actions/hospitalAgentAction";
import { registerHospitalAgent } from "../../redux/actions/hospitalAgentAction";
import CustomResult from "../../components/customResult/CustomResult";
import assign from "lodash/assign";
import {
  INVALID_LINK,
  INVALID_LINK_SUBTITLE,
  REGISTRATION_SUBTITLE_STAFF,
  SUCCESSFUL_REGISTRATION,
} from "../../constants/messages";

const RegisterHospitalAgentScreen = () => {
  // const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const { uuid } = useParams();
  const [showSuccess, setShowSuccess] = useState(false);
  const [registerDetails, setRegisterDetails] = useState();
  const [showForm, setShowForm] = useState(uuid ? true : false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    getHospitalAgentInvitation(uuid)
      .then((result) => {
        if(result?.status === 2){
          setShowError(true);
        }else{
        setRegisterDetails(result);
        }
      })
      .catch((err) => {
        console.log("Err", err);
      });
  }, [uuid]);

  useEffect(() => {
    validateHospitalAgentLink(uuid)
      .then((result) => {
        setLoading(false);
        let user = {...result?.user};
        user = {image : "",name :result?.user?.name}
        result = {...result,user}

        if (get(result, 'as_hospital_agent_exists')) {
          setShowSuccess(true);
        } else {
          setRegisterDetails({ info: result, invite_uuid: uuid });
          setShowForm(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        setShowError(true);
      });
  }, [uuid]);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (values) => {
    assign(values, { invite_link_uuid: uuid });
    setLoading(true);
    registerHospitalAgent(values)
      .then((result) => {
        setLoading(false);
        setShowSuccess(true);
        setShowForm(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const dispatch = useDispatch();

  dispatch(logout())
    .then(() => {})
    .catch((error) => {});
  return (
    <Spin spinning={loading}>
      <div className="register-hospital-agent-screen">
        {showForm && registerDetails && (
          <div className="hospital-agent-form">
            <Form
              name="control-hooks"
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              // validateMessages={validateMessages}
              initialValues={{
                email: get(registerDetails, "email"),
                title: "Ms",
                name: get(registerDetails, "name"),
                contact_code: "91",
              }}
            >
              <Form.Item
                label="Agent's Name"
                name="name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input className="custom-input" />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                  },
                ]}
              >
                <Input className="custom-input" disabled={true} />
              </Form.Item>
              <Form.Item
                label="Create Password"
                name="password"
                rules={[
                  {
                    required: true,
                  },
                  {
                    min: 6,
                    message: "Password must be atleast 6 characters",
                  },
                ]}
              >
                <Input.Password className="custom-input" />
              </Form.Item>
              <Form.Item
                label=" Confirm Password"
                name="confirm_password"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password className="custom-input" />
              </Form.Item>
              <Form.Item
                label="Contact Number"
                name="contact_number"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  className="custom-input"
                  addonBefore={ContactCodeSelector}
                />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Register
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
        {showSuccess && (
          <CustomResult
            status="success"
            title={SUCCESSFUL_REGISTRATION}
            subTitle={REGISTRATION_SUBTITLE_STAFF}
            fromstaff="Staff"
          />
        )}
        {showError && (
          <CustomResult
            status="error"
            title={INVALID_LINK}
            subTitle={INVALID_LINK_SUBTITLE}
          />
        )}
      </div>
    </Spin>
  );
};
export default RegisterHospitalAgentScreen;
