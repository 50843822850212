import { SearchOutlined } from '@ant-design/icons';
import { Checkbox, Col, Input, Row, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { listDoctors } from '../../../redux/actions/doctorAction';
import AddDoctorDrawer from '../../drawer/AddDoctorDrawer';
import map from 'lodash/map';
import get from 'lodash/get';
import indexOf from 'lodash/indexOf';
import remove from 'lodash/remove';
// Styles
import './styles.scss';

import DoctorProfile from '../../doctorprofile/DoctorProfile';

const AddDoctorCard = ({
  departmentDoctors,
  setSelectedDoctors,
  selectedDoctors,
}) => {
  const [doctors, setDoctors] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    listDoctors(null, 1, 100)
      .then((result) => {
        setDoctors(result.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setSelectedDoctors(map(departmentDoctors, 'id'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheck = (event) => {
    if (event.target.checked) {
      setSelectedDoctors([...selectedDoctors, event.target.doctorId]);
    } else {
      setSelectedDoctors(
        remove(selectedDoctors, (doctorId) => {
          if (doctorId !== event.target.doctorId) {
            return doctorId;
          }
        }),
      );
    }
  };

  return (
    <div className="add-doctor-card">
      <div className="title">Select Doctors</div>

      <Row className="search-add-doctors">
        <Col span={10} push={14} style={{ textAlign: 'end' }}>
          <AddDoctorDrawer />
        </Col>
        <Col span={14} pull={10}>
          <Input
            placeholder="Doctor, specialty, hospital or treatment"
            allowClear
            size="large"
            prefix={<SearchOutlined style={{ fontSize: '12px' }} />}
          />
        </Col>
      </Row>

      <div className="divider" />
      <Spin spinning={loading}>
        <div className="doctors-list">
          {selectedDoctors &&
            departmentDoctors &&
            map(doctors, (doctor, i) => (
              <div className="single-doctor-entry">
                <Row justify="space-between" align="middle">
                  <Col>
                    <DoctorProfile
                      entry_name={get(doctor, 'name')}
                      title={get(doctor, 'title')}
                      specializations={get(doctor, 'specialisations')}
                      experience={get(doctor, 'experience')}
                    />
                  </Col>
                  <Col>
                    <div>{get(doctor, 'email')}</div>
                  </Col>
                  <Col>
                    <Checkbox
                      checked={
                        indexOf(selectedDoctors, get(doctor, 'id')) > -1
                          ? true
                          : false
                      }
                      onChange={(event) => handleCheck(event)}
                      doctorId={get(doctor, 'id')}
                    />
                  </Col>
                </Row>

                <div className="divider" />
              </div>
            ))}
        </div>
      </Spin>
    </div>
  );
};

export default AddDoctorCard;
