import get from 'lodash/get';
import includes from 'lodash/includes';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { switchHospital } from './redux/actions/authAction';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router-dom';
import * as Routes from './constants/routes';

function HelperComponent({ setIsLoading }) {
  const isLoggedIn = useSelector((state) => get(state, 'auth.isLoggedIn'));

  const dispatch = useDispatch();
  const currentHospital = useSelector((state) =>
    get(state, 'auth.currentHospital')
  );
  const roles = useSelector((state) => get(state, 'auth.roles'));
  const location = useLocation();

  const match = matchPath(location.pathname, {
    path: Routes.REGISTER_DOCTOR,
    exact: true,
    strict: false,
  });

  useEffect(() => {
    if (match && isLoggedIn && !includes(roles, 1)) {
      setIsLoading(true);

      dispatch(switchHospital({ hospital_uuid: get(currentHospital, 'id') }))
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export default HelperComponent;
