import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, notification } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import get from 'lodash/get';
import copy_black from '../../assets/icons/copy_black.svg';
import link_black from '../../assets/icons/link_black.svg';

const LinkModal = ({ linkModal, toggleLinkModal }) => {
  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(
      // `https://smartmeet.com/lineup/${get(linkModal, 'data.doctor_detail.id')}`
      `${process.env.REACT_APP_UI_HOST}/lineup/${get(linkModal, 'data.doctor_detail.id')}`
      // `http://localhost:3000/lineup/${get(linkModal, 'data.doctor_detail.id')}`

    );
    notification.success({
      message: ('Copied to clipboard.'),
    });
  };
  const onVisit=()=>{
    // window.open(`http://localhost:3000/lineup/${get(linkModal, 'data.doctor_detail.id')}`, "_blank")
    window.open(`${process.env.REACT_APP_UI_HOST}/lineup/${get(linkModal, 'data.doctor_detail.id')}`, "_blank")
    
    // window.open(`https://smartmeet.com/lineup/${get(linkModal, 'data.doctor_detail.id')}`, "_blank");
  }

  return (
    <Modal
      centered
      visible={get(linkModal, 'visibility')}
      onOk={() => toggleLinkModal()}
      onCancel={() => toggleLinkModal()}
      footer={null}
      closable={false}
    >
      <div className="link-modal">
        <Row justify="space-between">
          <Col>
            <p className="large-bold">
              {`Here is the lineup link for Dr. ${get(linkModal, 'data.name')}`}
            </p>
          </Col>
          <Col>
            <CloseOutlined onClick={() => toggleLinkModal()} />
          </Col>
        </Row>
        <Input
          className="custom-input"
          value={
          //   `http://localhost:3000/lineup/${get(
          //   linkModal,
          //   'data.doctor_detail.id'
          // )}`
          `${process.env.REACT_APP_UI_HOST}/lineup/${get(
            linkModal,
            'data.doctor_detail.id'
          )}`
        }
         
          // value={`https://smartmeet.com/lineup/${get(
          //   linkModal,
          //   'data.doctor_detail.id'
          // )}`
          suffix={
            <img
              src={copy_black}
              alt=""
              height="14px"
              onClick={copyToClipboard}
            />
          }
          prefix={<img src={link_black} alt="" height="14px" />}
        />
        <div className="visit-button">
          <Button type="primary" onClick={onVisit}>Visit</Button>
        </div>
      </div>
    </Modal>
  );
};

export default LinkModal;
