import { CloseOutlined } from '@ant-design/icons';
import { Col, Row, Space } from 'antd';
import get from 'lodash/get';

const AppointmentCancellationDetails = ({ toggleCancelAppointmentNotes, details }) => {
  return (
    <div className="add-drawer-content">
      <Row justify="space-between">
        <Col className="drawer-title">Reason</Col>
        <Col>
          <CloseOutlined onClick={toggleCancelAppointmentNotes} />
        </Col>
      </Row>
      <div className="add-drawer-form">
        <Space size="large" direction="vertical">
          <div>
            <div>
              {get(details, 'reschedule_reason') !== null ? get(details, 'reschedule_reason') : get(details, 'cancel_reason')} 
              {console.log("details", get(details))}
            </div>
          </div>
        </Space>
      </div>
    </div>
  );
};

export default AppointmentCancellationDetails;
