import { Input, Space, Button, Row, Col, Card } from 'antd';
import get from 'lodash/get';
import moment from 'moment';
import {useSelector} from "react-redux";

import './styles.scss';

const InvoiceCard = ({data}) => {
  return(
    <Card className="invoice-details">
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        <Row>
          <Col span={24} >
            <div className="invoice-number title5">
              Invoice: {get(data, 'invoice_number')}
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 24]}>
          <Col span={8}>
            <div className="details-label">Invoice number</div>
            <div className="medium">{get(data, 'invoice_number')}</div>
          </Col>
          <Col span={8}>
            <div className="details-label">Issue date</div>
            <div className="medium">
              {moment(get(data, 'created_at')).format("DD-MMM-YYYY")}
            </div>
          </Col>
          <Col span={8}>
            <div className="details-label">Hospital details</div>
            <div className="medium">
            {get(data, 'hospital.name')}
            <br></br>
            {get(data, 'hospital.address_1')}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <div className="details-label">Total appointments</div>
            <div className="medium">{get(data, 'units')}</div>
          </Col>
          <Col span={8}>
            <div className="details-label">Total amount to be paid</div>
            <div className="medium">${get(data, 'amount')}</div>
          </Col>
        </Row>
      </Space>
    </Card>
  )
  
}

const SendInvoice = ({ data, toggleSendInvoiceModal }) => {
  const details = useSelector((state) => get(state, 'auth.user'));
    return (
      <div>
        <div className="send-invoice-modal">
          <div className="title">Send Invoice</div>                  
          <Space direction="vertical" size="large" style={{ width: "100%" }}>
            <div className="admin-email-field">
              <div className="medium">Email</div>
              <Input
                value={details?.roles[0]?.value === 1 ? details?.email:"Admin Email"}
                disabled="true"
              />
            </div>            
            < InvoiceCard data={data}/>
            <div className="action-buttons">
              <Space>
                <Button type="default" onClick={toggleSendInvoiceModal}>
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                  Send
                </Button>
              </Space>
            </div>
          </Space>          
        </div>        
      </div>
    )
}

export {InvoiceCard}
export default SendInvoice

