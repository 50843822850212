import {
  Col,
  Radio,
  Row,
  Space,
  Spin,
  DatePicker,
  Select,
  Input,
  Table,
  Drawer,
  Modal,
  Button,
  Form,
  InputNumber,
} from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { filterBillings, filterDays } from '../../constants/options';
import get from 'lodash/get';
import map from 'lodash/map';
import {
  getBillings,
  markInvoicePaid,
} from '../../redux/actions/superAdminAction';
import { SearchOutlined } from '@ant-design/icons';
import './styles.scss';
import { billingsColumn } from '../../constants/table/billingsColumn';
import DoneModal from '../../components/modals/doneModal/DoneModal';
import InvoiceInfo from '../../components/invoiceInfo/InvoiceInfo';
import SendInvoice, { InvoiceCard } from '../../components/sendInvoice/SendInvoice';
import requireAuth from '../../hocs/requireAuth';
import { validateMessages } from '../../constants/validation';
import { YEAR_MONTH_DAY } from '../../constants/constantsDateFormats';
const { Option } = Select;
const { Search } = Input;

const { RangePicker } = DatePicker;
const BillingsScreen = () => {
  const [loading, setLoading] = useState(false);
  const [billingsResult, setBillingsResult] = useState({
    billings: [],
    pagination: null,
  });
  const [filter, setFilter] = useState({
    page: 1,
    fromDate: moment().format(YEAR_MONTH_DAY),
    toDate: moment().format(YEAR_MONTH_DAY),
    days: 1,
    status: null,
    name: '',
  });
  const handleDays = (days) => {
    const toDate = moment().format(YEAR_MONTH_DAY);
    const fromDate = moment(moment()).subtract(days, 'd').format(YEAR_MONTH_DAY);
    getBillingList(
      get(filter, 'page'),
      fromDate,
      toDate,
      days,
      get(filter, 'status'),
      get(filter, 'name')
    );
  };

  const [billingInfo, setBillingInfo] = useState({
    visible: false,
    data: null,
  });

  const toggleBillingInfo = (data = null) => {
    setBillingInfo({
      visible: !get(billingInfo, 'visible'),
      data,
    });
  };

  const [visibleDone, setVisibleDone] = useState(false);
  const [doneMessage, setDoneMessage] = useState('');

  const toggleDone = (message = '') => {
    setVisibleDone(!visibleDone);
    setDoneMessage(message);
  };

  const [markPaidModal, setMarkPaidModal] = useState({
    visible: false,
    data: null,
  });
  const toggleMarkPaid = (data = null) => {
    setMarkPaidModal({ visible: !get(markPaidModal, 'visible'), data: data });
  };

  const [sendInvoiceModal, setSendInvoiceModal] = useState({
    visible: false,
    data: null
  })
  const toggleSendInvoiceModal = (data = null) => {
    setSendInvoiceModal({
      visible: !get(sendInvoiceModal, 'visible'),
      data: data
    })
  }

  const handlePrint = (data) => {
    // <InvoiceCard data={data} />
    console.log("handle print", data)
  }


  const handleRange = (dates) => {
    if (dates) {
      const toDate = moment(dates[1]).format(YEAR_MONTH_DAY);
      const fromDate = moment(dates[0]).format(YEAR_MONTH_DAY);
      getBillingList(
        get(filter, 'page'),
        fromDate,
        toDate,
        'null',
        get(filter, 'status'),
        get(filter, 'name')
      );
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const markPaid = (values) => {
    const invoice_uuid = get(markPaidModal, 'data.id');
    markInvoicePaid(invoice_uuid, values)
      .then((result) => {
        setLoading(false);
        toggleMarkPaid();
        toggleDone('Marked as Paid');
        getBillingList(
          get(filter, 'page'),
          get(filter, 'fromDate'),
          get(filter, 'toDate'),
          get(filter, 'days'),
          get(filter, 'status'),
          get(filter, 'name')
        );
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getBillingList = (
    page,
    fromDate,
    toDate,
    days,
    status = null,
    name = ''
  ) => {
    setLoading(true);
    getBillings(fromDate, toDate, status, page, name).then((result) => {
      setLoading(false);
      setBillingsResult({
        billings: get(result, 'data'),
        pagination: get(result, 'meta.pagination'),
      });
      setFilter({ page, fromDate, toDate, days, status, name });
    });
  };
  const onSearch = (value) => {
    getBillingList(
      get(filter, 'page'),
      get(filter, 'fromDate'),
      get(filter, 'toDate'),
      get(filter, 'days'),
      get(filter, 'status'),
      value
    );
  };

  useEffect(() => {
    getBillingList(
      get(filter, 'page'),
      get(filter, 'fromDate'),
      get(filter, 'toDate'),
      get(filter, 'days'),
      get(filter, 'status'),
      get(filter, 'name')
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Spin spinning={loading}>
      <Row>
        <Col xs={24} lg={6} md={6}>
          <div className="screen-title">Billings</div>
        </Col>
        <Col xs={24} lg={18} md={18} >
          <Row align="end" gutter={[10,10]}>
            <Col>
              <Radio.Group
                options={filterDays}
                value={get(filter, 'days')}
                optionType="button"
                buttonStyle="solid"
                onChange={(event) => handleDays(get(event, 'target.value'))}
              />
            </Col>
            <Col>
              <RangePicker
                onChange={(dateStrings) => handleRange(dateStrings)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="search-appointment mt--10">
        <Col span={18} push={6} align="end">
          <Select
            value={get(filter, 'status')}
            onChange={(value) =>
              getBillingList(
                get(filter, 'page'),
                get(filter, 'fromDate'),
                get(filter, 'toDate'),
                get(filter, 'days'),
                value,
                get(filter, 'name')
              )
            }
          >
            {map(filterBillings, (filter, i) => (
              <Option key={i} value={get(filter, 'value')}>
                {get(filter, 'label')}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={6} pull={18}>
          <Search
            placeholder="Hospital"
            className="search-input"
            allowClear
            enterButton={false}
            suffix={false}
            size="large"
            onSearch={onSearch}
            prefix={<SearchOutlined style={{ fontSize: '12px' }} />}
          />
        </Col>
      </Row>
      <Table
        columns={billingsColumn({ toggleBillingInfo, toggleMarkPaid, toggleSendInvoiceModal, handlePrint })}
        dataSource={get(billingsResult, 'billings')}
        scroll={{ x: true }}
        pagination={{
          pageSize: get(billingsResult, 'pagination.per_page', 1),
          current: get(billingsResult, 'pagination.current_page', 1),
          total: get(billingsResult, 'pagination.total', 1),
          onChange: (val) => {
            getBillingList(
              val,
              get(filter, 'fromDate'),
              get(filter, 'toDate'),
              get(filter, 'days'),
              get(filter, 'status'),
              get(filter, 'name')
            );
          },

          position: ['bottomLeft'],
        }}
      />
      <Drawer
        className="main-drawer"
        destroyOnClose
        width={300}
        closable={false}
        onClose={() => toggleBillingInfo(null)}
        visible={get(billingInfo, 'visible')}
      >
        <InvoiceInfo
          toggleBillingInfo={toggleBillingInfo}
          data={get(billingInfo, 'data')}
        />
      </Drawer>
      <DoneModal
        message={doneMessage}
        visible={visibleDone}
        toggleDone={toggleDone}
      />
      <Modal
        className="custom-modal"
        centered={true}
        closable={false}
        destroyOnClose={true}
        visible={get(sendInvoiceModal, 'visible')}
        footer={null}
      >
        <SendInvoice
          toggleSendInvoiceModal={toggleSendInvoiceModal}
          data={get(sendInvoiceModal, 'data')}
        />
      </Modal>
      <Modal
        className="custom-modal"
        centered={true}
        closable={false}
        destroyOnClose={true}
        visible={get(markPaidModal, 'visible')}
        footer={null}
      >
        <div className="modal-children">
          <div className="title">Mark Invoice as Paid</div>
          <Form
            name="basic"
            layout="vertical"
            onFinish={markPaid}
            onFinishFailed={onFinishFailed}
            validateMessages={validateMessages}
            initialValues={{
              amount: get(markPaidModal, 'data.amount'),
            }}
          >
            <Form.Item
              label="Billing amount"
              name="amount"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                addonBefore="$"
                size="large"
                min={0}
                className="custom-input-number"
              />
            </Form.Item>
            <Form.Item label="Payment Reference Id" name="payment_reference_id">
              <Input className="custom-input" />
            </Form.Item>

            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  Mark Paid
                </Button>
                <Button type="default" onClick={() => toggleMarkPaid()}>
                  Cancel
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </Spin>
  );
};

export default requireAuth(BillingsScreen, [1]);
