import { Row, Col, Button, Tag } from 'antd';
import get from 'lodash/get';
import map from 'lodash/map';

// images
import edit_white from '../../assets/icons/edit_white.svg';

// Styles
import './styles.scss';
import AddressHelper from '../../utilities/addressHelper/AddressHelper';
import { workingHours } from '../../utilities/workingHours';

const HospitalDetails = ({ onClick, details }) => {
  return (
    <div className="hospital-component-style">
      <Row gutter={[16, 40]}>
        <Col span={8}>
          <div className="details-label">Hospital Name</div>
          <div className="medium">{get(details, 'name')}</div>
        </Col>
        <Col span={8}>
          <div className="details-label">Specialization</div>
          {map(get(details, 'specialisations'), (specilisation, i) => (
            <Tag color="magenta" key={i}>
              {get(specilisation, 'name')}
            </Tag>
          ))}
        </Col>
        <Col span={8}>
          <div className="details-label">About</div>
          <div className="medium">{get(details, 'description')}</div>
        </Col>
        <Col span={8}>
          <div className="details-label">City</div>
          <div className="medium">{get(details, 'city.name')}</div>
        </Col>
        <Col span={8}>
          <div className="details-label">Hospital Address</div>
          <div className="medium">{details && get(details?.address_1 &&  JSON.parse(details?.address_1), 'selectedAddress')}</div>
        </Col>
        <Col span={8}>
          <div className="details-label">Contact Number</div>
          <div className="medium">
            +{get(details, 'contact_code')} {get(details, 'contact_number')}
          </div>
        </Col>
        <Col span={8}>
          <div className="details-label">Fax Number</div>
          <div className="medium">{get(details, 'fax_number')}</div>
        </Col>

        <Col span={8}>
          <div className="details-label">Website url</div>
          <div className="medium">{get(details, 'website')}</div>
        </Col>
        <Col span={8}>
          <div className="details-label">Number of Doctors</div>
          <div className="medium">{get(details, 'doctor_count')}</div>
        </Col>
        {/* <Col span={8}>
          <div className="details-label">Billing Method</div>
          <div className="medium">{get(details, 'billing_method.name')}</div>
        </Col> */}

        <Col span={8}>
          <div className="details-label">Open Hours</div>
          {map(workingHours(get(details, 'working_hours')), (single, i) => (
            <div className="medium" key={i}>
              <Row>
                <Col span={6}>{get(single, 'dayName')}</Col>
                <Col>
                  <span>{get(single, 'from_time')} </span>
                  <span>to </span>
                  <span>{get(single, 'to_time')}</span>
                </Col>
              </Row>
            </div>
          ))}
          <div className="medium"></div>
        </Col>
        <Col span={8}>
          <div className="details-label">Sponsorship required</div>
          <div className="medium">
            {get(details, 'sponsership_required') ? 'Yes' : 'No'}
          </div>
        </Col>
        <Col span={24}>
          <Button
            type="primary"
            onClick={onClick}
            icon={
              <img
                src={edit_white}
                alt=""
                height="18px"
                style={{ marginRight: '4px' }}
              />
            }
          >
            Edit Details
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default HospitalDetails;
