import React, { useEffect, useState } from "react";
import { DatePicker, Input, Select, Form, Button, Radio, message } from "antd";
import moment from "moment";
import assign from "lodash/assign";
import map from "lodash/map";
import get from "lodash/get";
import ContactCodeSelector from "./ContactCodeSelector";
import {
  createEmergencyAppointment,
  getPatientDashboard,
  getPatientMembers,
} from "../../redux/actions/doctorAction";

const { Option } = Select;
const EmergencyFormFamily = ({
  doctor_uuid,
  hospital_uuid,
  filteredUser,
  closeFormModal,
  currentSlot,
  slotType,
  filter,
  getAppointments
}) => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    let newPayload = {};
    assign(newPayload, {
      patient: values,
    });
    let data = {};
    data["doctor_uuid"] = doctor_uuid;
    data["hospital_uuid"] = hospital_uuid;
    data["date"] = moment().format("YYYY-MM-DD");
    data["name"] = filteredUser?.name;
    data["email"] = filteredUser?.email;
    data["contact_number"] = filteredUser?.contact_number;
    data["contact_code"] = filteredUser?.contact_code;
    data["user_uuid"] = filteredUser?.id;
    data["consultation_type"] = 2;
    data["slot"] = currentSlot;
    data["type"] = slotType;
    createEmergencyAppointment(data, newPayload)
      .then((result) => {
        closeFormModal();
        message.success("Appointment Created Successfully");
        getAppointments(
          get(filter, "page"),
          get(filter, "fromDate"),
          get(filter, "toDate"),
          get(filter, "days"),
          get(filter, "status"),
          get(filter, "query")
        );
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const [membersData, setMembersData] = useState([]);
  useEffect(() => {
    getPatientMembers({hospital_uuid:hospital_uuid})
      .then((result) => {
        setMembersData(result);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, []);
  const [selectedMember, setSelectedMember] = useState(0);
  const [familymembers, setFamilyMembers] = useState([]);

  const handleSelectFamilyMember = (index) => {
    setSelectedMember(index);
    if (index === 0) {
      form.resetFields();
    } else {
      form.setFieldsValue({
        name: familymembers[index].name,
        title: familymembers[index].title,
        contact_code: familymembers[index].contact_code,
        contact_number: familymembers[index].contact_number,
        gender: familymembers[index].gender,
        relation: familymembers[index].relation,
        dob: moment(familymembers[index].dob, "YYYY-MM-DD HH:mm:ss"),
        age: 22,
      });
    }
  };
  const getFamilyMembers = () => {
    const result = getPatientDashboard(filteredUser)
      .then((result) => {
        setFamilyMembers([
          {
            name: "Other",
            id: "0",
          },
          ...get(result, "members"),
        ]);
        return result;
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  useEffect(async () => {
    // if (get(session, 'user')) {
    getFamilyMembers();
    // }
  }, []);
  return (
    <>
      <Form
        form={form}
        name="appointment"
        layout="vertical"
        onFinish={onFinish}
      >
        <div className="book-consultation-style">
          <div className="modal-title">Book Consultation For Family</div>
          <Form.Item>
            <Select
              className="custom-select"
              value={get(familymembers[selectedMember], "name")}
              defaultValue={"Select Family Member"}
              onChange={(value) => handleSelectFamilyMember(value)}
            >
              {map(familymembers, (filter, i) => (
                <Option key={i} value={i}>
                  {get(filter, "name")}( {get(filter, "relation", "New member")}{" "}
                  )
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Relation"
            name="relation"
            rules={[
              {
                required: true,
                message: "Please select Relation",
              },
            ]}
          >
            <Input
              size="large"
              placeholder="Enter relation"
              // disabled={
              //   get(session, 'user')
              //     ? get(familymembers[selectedMember], 'id') === 0
              //       ? false
              //       : true
              //     : false
              // }
            />
          </Form.Item>

          <div className="form-section">
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please Input Name",
                },
              ]}
            >
              <Input size="large" placeholder="Enter Name" />
            </Form.Item>
            <Form.Item
              label="Date of birth"
              name="dob"
              rules={[
                {
                  required: true,
                  message: "Please Input date of birth of patient",
                },
              ]}
            >
              <DatePicker
                className="custom-date"
                size={"large"}
                format={"DD-MM-YYYY"}
              />
            </Form.Item>
            <Form.Item
              label="Gender"
              name="gender"
              rules={[
                {
                  required: true,
                  message: "Please select gender",
                },
              ]}
            >
              <Radio.Group>
                <div className="select-area">
                  <Radio value={"M"}>Male</Radio>
                  <Radio value={"F"}>Female</Radio>
                </div>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Contact Number"
              name="contact_number"
              rules={[
                {
                  required: true,
                  message: "Please Input Contact number",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter Contact"
                addonBefore={ContactCodeSelector}
              />
            </Form.Item>{" "}
            <div className={`button-primary`}>
              <Form.Item>
                <Button type="primary" onClick={() => {}} htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};
export default EmergencyFormFamily;
