import { Row, Select, Spin, Table, Button, notification } from "antd";
import { useEffect, useState } from "react";
import CustomTag from "../../components/tag/CustomTag";
import get from "lodash/get";
import { getDoctorInvitations } from "../../redux/actions/doctorAction";
import { SEND_INVITE_STATUS } from "../../constants/messages";
import { invite } from "../../redux/actions/doctorAction";
import { getStaffInvitations } from "../../redux/actions/staffAction";
import assign from "lodash/assign";
import { inviteStaff } from '../../redux/actions/staffAction';
import { useSelector } from 'react-redux';
const { Option } = Select;

const PendingInvitationScreen = ({
  toggleAddDoctor,
  pendingInvitation,
  staffInvitation,
  toggleAddAssistant
}) => {
  const [loading, setLoading] = useState(false);
  const [invitationData, setInvitationData] = useState();
  const HospitalId = useSelector((state) => get(state, 'auth.currentHospital.id'));
  const doctorId = useSelector((state)=>get(state,'auth.user.id'))

  useEffect(() => {
    if (staffInvitation) {
      getStaffInvitations({hospitalId:HospitalId,doctorId : doctorId})
        .then((result) => {
          setInvitationData(result);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      getDoctorInvitations({hospitalId:HospitalId})
        .then((result) => {
          setInvitationData(result);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, []);

  const viewDetails = (details) => {
    setLoading(true);
    let inviteValue = {
      email: details.email,
      data: { name: details.data.name },
    };
    if (staffInvitation) {
      assign(inviteValue, { role: 5 });
      inviteStaff(inviteValue)
        .then(() => {
          setLoading(false);
          toggleAddAssistant();
          notification.success({
            message: SEND_INVITE_STATUS,
          });
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      invite(inviteValue)
        .then(() => {
          setLoading(false);
          notification.success({
            message: SEND_INVITE_STATUS,
          });
          pendingInvitation();
          // toggleAddDoctor();
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };
  const pendingInvitationColumn = () => [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Status",
      key: "status",
      render: (record) => <CustomTag status={get(record, "status")} />,
    },
    {
      title: "Action",
      render: (props) => {
        return (
          <div>
            <Button
              type="primary"
              className="view-button"
              onClick={() => viewDetails(props)}
            >
              ReInvite
            </Button>
          </div>
        );
      },
    },
  ];
  return (
    <Spin spinning={loading}>
      <div>
        <Row justify="space-between" style={{ alignItems: "baseline" }}></Row>
        <Table
          columns={pendingInvitationColumn()}
          dataSource={invitationData}
          //   pagination={{
          //     pageSize: get(doctorsResult, 'pagination.per_page', 1),
          //     current: get(doctorsResult, 'pagination.current_page', 1),
          //     total: get(doctorsResult, 'pagination.total', 1),
          //     onChange: (val) => {
          //       getDoctors(get(filter, 'specialisation_uuid'), val);
          //     },
          //     position: ['bottomLeft'],
          //   }}
        />
      </div>
    </Spin>
  );
};

export default PendingInvitationScreen;
