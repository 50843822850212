import React, { useState, useEffect } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Col, Row, Input, Radio, Button, Form, notification, Spin } from "antd";
import get from "lodash/get";
import { updateHospitalPatient, fetchHospitalPatientByUserId } from "../../../redux/actions/hospitalAction";

const HospitalPatientForm = ({
  closeNewHospitalPatient,
  setCurrentUserId,
  getHospitalPatients,
  currentUserId,
  filter,
  hospital_uuid,
  fetchHospitalPatients
}) => {
  const [form] = Form.useForm();
  const [editUser, setEditUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    updateHospitalPatient(hospital_uuid, currentUserId, values)
      .then(() => {
        notification.success({
          message: "Updated status successfully",
        });
        closeNewHospitalPatient();
        getHospitalPatients(
          get(filter, "page"),
          get(filter, 'fromDate'),
          get(filter, 'toDate'),
          get(filter, "days"),
          get(filter, "status"),
          get(filter, "name")
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    fetchHospitalPatientByUserId(hospital_uuid, currentUserId)
      .then((res) => {
        setEditUser(res);
        form.setFieldsValue({ user_name: res?.name, status: res?.is_active });
      })
      .finally(() => setLoading(false));
  }, [currentUserId, hospital_uuid, form]);

  const onUpdatePatientStatus = (values) => {
    setLoading(true);
    updateHospitalPatient(hospital_uuid, currentUserId, values)
      .then(() => {
        notification.success({
          message: "Updated status successfully",
        });
        closeNewHospitalPatient();
        fetchHospitalPatients(
          get(filter, "page"),
          get(filter, 'fromDate'),
          get(filter, 'toDate'),
          get(filter, "days"),
          get(filter, "status"),
          get(filter, "name")
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="add-drawer-content">
      {loading ? (
        <Spin tip="Loading..." />
      ) : (
        <>
          <Row justify="space-between">
            <Col className="drawer-title">Update patient status</Col>
            <Col>
              <CloseOutlined onClick={closeNewHospitalPatient} />
            </Col>
          </Row>

          <Form
            name="dynamic_form_nest_item"
            className="faqs_form"
            layout="vertical"
            onFinish={editUser?.id ? onUpdatePatientStatus : onFinish}
            autoComplete="off"
            form={form}
          >
            <Row style={{ display: "flex", marginBottom: 8 }} align="baseline" justify="space-between">
              <Col span={22}>
                <Form.Item
                  name="user_name"
                  label="User Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the User Name",
                    },
                  ]}
                >
                  <Input placeholder="User Name" className="custom-input" disabled />
                </Form.Item>

                <Form.Item
                  name="status"
                  label="Status"
                  rules={[
                    {
                      required: true,
                      message: "Please select the status",
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value={true}>Active</Radio>
                    <Radio value={false}>Inactive</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ marginRight: "8px" }}>
                {editUser?.id ? "Update" : "Submit"}
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
    </div>
  );
};

export default HospitalPatientForm;
