import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Col, Divider, Empty, Row, Spin, Tabs } from 'antd';

import availability_black from '../../../../assets/icons/availability_black.svg';
import { useState } from 'react';
import map from 'lodash/map';
import get from 'lodash/get';
import { useEffect } from 'react';
import { doctorSlots } from '../../../../redux/actions/doctorAction';
import { generateSlots } from '../../../../utilities/availabilityUtility';
import moment from 'moment';
import './style.scss';
import AvailabilitySlots from '../availabilitySlots/AvailabilitySlots';
import { useSelector } from 'react-redux';
import { sliceDate } from '../../../../utilities/sliceDate';
import { YEAR_MONTH_DAY } from '../../../../constants/constantsDateFormats';

const { TabPane } = Tabs;

const AvailabilityDetails = ({ doctor_uuid }) => {
  const hospital_uuid = useSelector((state) =>
    get(state, 'auth.currentHospital.id'),
  );
  const [loading, setLoading] = useState(true);
  const [activeKey, setActiveKey] = useState('0');
  const [availability, setAvailability] = useState();
  const [date, setDate] = useState(moment().format(YEAR_MONTH_DAY));
  const [tabTitle, setTabTitle] = useState([
    'Today',
    'Tomorrow',
    sliceDate(moment(moment(date)).add(2, 'd').format('llll')),
  ]);

  const nextTab = () => {
    setLoading(true);
    var currentTab = parseInt(activeKey);
    currentTab = currentTab + 1;
    setActiveKey(currentTab.toString());
    setDate(moment(moment(date)).add(1, 'd').format(YEAR_MONTH_DAY));
    setTabTitle([
      ...tabTitle,
      sliceDate(moment(moment(date)).add(3, 'd').format('llll')),
    ]);
  };
  const previousTab = () => {
    setLoading(true);
    var currentTab = parseInt(activeKey);
    currentTab = currentTab - 1;
    if (currentTab === -1) {
      currentTab = 0;
    }
    setDate(moment(moment(date)).subtract(1, 'd').format(YEAR_MONTH_DAY));
    setActiveKey(currentTab.toString());
  };

  // const handleTabClick = (key) => {
  //   var currentTab = parseInt(activeKey);
  //   setDate(moment(moment(date)).subtract(1, 'd').format(YEAR_MONTH_DAY));
  //   setActiveKey(currentTab.toString());
  // };

  useEffect(() => {
    setLoading(true);
    const values = {
      date: date,
      hospital_uuid: hospital_uuid,
      doctor_uuid: doctor_uuid,
    };
    doctorSlots(values)
      .then((result) => {
        setLoading(false);

        setAvailability(generateSlots(result, values.date));
      })
      .catch((error) => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, hospital_uuid]);

  return (
    <div className="doctor-availability">
      <div className="heading">
        <Row justify="space-between" align="middle">
          <Col>
            <Row gutter={16}>
              <Col>
                <img src={availability_black} alt="" height="14px" />
              </Col>
              <Col>
                <p className="medium-bold">Availability</p>
              </Col>
            </Row>
          </Col>
          <Col>
            <p className="medium-bold">500</p>
          </Col>
        </Row>
      </div>
      <Divider />
      <Row justify="space-between" style={{ alignItems: 'baseline' }}>
        <Col span={2}>
          <LeftOutlined style={{ fontSize: '12px' }} onClick={previousTab} />
        </Col>
        <Col span={18}>
          <Tabs
            defaultActiveKey="0"
            className="availability-tabs"
            activeKey={activeKey}
            // onTabClick={(key) => handleTabClick(key)}
          >
            {map(tabTitle, (tab, i) => (
              <TabPane
                tab={
                  <>
                    <div className="current-day">{tab}</div>
                    {parseInt(activeKey) === i && (
                      <div className="available-slots">
                        {get(availability, 'available_slots') === 0
                          ? 'No'
                          : get(availability, 'available_slots')}{' '}
                        Slots Available
                      </div>
                    )}
                  </>
                }
                key={i}
              >
                <Spin spinning={loading}>
                  {get(availability, 'available_slots') === 0 ? (
                    <Empty description="No Slots Available" />
                  ) : (
                    <AvailabilitySlots availability={availability} />
                  )}
                </Spin>
              </TabPane>
            ))}
          </Tabs>
        </Col>
        <Col span={2}>
          <RightOutlined style={{ fontSize: '12px' }} onClick={nextTab} />
        </Col>
      </Row>
    </div>
  );
};

export default AvailabilityDetails;
