import {
  CloseCircleFilled,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Spin,
  List,
  Divider,
  message,
  Checkbox,
} from "antd";
import React, { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { DatePicker, notification } from "antd";
import get from "lodash/get";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  getHospitalTvAds,
  deleteHospitalTvAds,
  updateHospitalTvAds,
  fetchHospitalTvAdById,
  postHospitalTvAds,
} from "../../redux/actions/hospitalAction";
import { DELETED_TV_ADS } from "../../constants/messages";
import SingleImageUpload from "../../components/mediaUpload/SingleImageUpload";
import SingleVideoUpload from "../../components/mediaUpload/SingleVideoUpload";
import { useForm } from "antd/lib/form/Form";

const TvAdsScreen = ({ hospital }) => {
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editAd, setEditAd] = useState(null);
  const [imageUser, setUserImage] = useState();
  const [videoUser, setUserVideo] = useState();
  const [form] = useForm();
  const timeFormat = "YYYY-MM-DD HH:mm:ss";


  const onSave = () => {
    setEdit(false);
  };
  const onEdit = (itemUuid, onEdit = false) => {
    setEdit(true);
    if (onEdit) {
      fetchHospitalTvAdById(itemUuid).then((res) => {
          let tv_ads_array =  JSON.parse(res?.tv_ads);

          const convertedTvAds = tv_ads_array?.map((tvad) => {
            const convertedTvAd = { ...tvad }; 
          if (convertedTvAd?.start_time) {
            convertedTvAd.start_time = moment(convertedTvAd.start_time,(timeFormat));
          }
        
          if (convertedTvAd?.end_time) {
            convertedTvAd.end_time = moment(convertedTvAd.end_time,(timeFormat));
          }
            return convertedTvAd;
          });

        let result = {
          ...res,
          tv_ads : convertedTvAds
        };
        form.setFieldsValue(result);
        setEditAd(result);
        setUserImage(res?.image);
        setUserVideo(res?.video);
      });
    }
  };
  const onClickBack = () => {
    setEdit(false);
    form.resetFields();
    setEditAd({});
    setUserImage({});
  };
  const [ads, setAds] = useState([]);

  const hospital_uuid = useSelector((state) =>
    get(state, "auth.currentHospital.id")
  );
  const createdBy = useSelector((state) => state?.auth?.id);

  const fetchAds = () => {
    getHospitalTvAds(hospital_uuid)
      .then((result) => {
        setLoading(false);
        setAds(result);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const postAds = (payload) => {};

  useEffect(() => {
    fetchAds();
  }, []);

  return (
    <Spin spinning={loading}>
      {edit ? (
        <EditAds
          onSave={onSave}
          setLoading={setLoading}
          ads={ads}
          editAd={editAd}
          postAds={postAds}
          fetchAds={fetchAds}
          hospital={hospital}
          loading={loading}
          createdBy={createdBy}
          onClickBack={onClickBack}
          imageUser={imageUser}
          setUserImage={setUserImage}
          videoUser={videoUser}
          setUserVideo={setUserVideo}
          form={form}
        />
      ) : (
        <ViewAds
          onEdit={onEdit}
          setLoading={setLoading}
          ads={ads}
          loading={loading}
          fetchAds={fetchAds}
          imageUser={imageUser}
          setUserImage={setUserImage}
          videoUser={videoUser}
          setUserVideo={setUserVideo}
        />
      )}
    </Spin>
  );
};

export default TvAdsScreen;

const EditAds = ({
  setLoading,
  loading,
  onSave,
  ads,
  fetchAds,
  hospital,
  createdBy,
  editAd,
  onClickBack,
  imageUser,
  setUserImage,
  videoUser,
  setUserVideo,
  form,
}) => {
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [defaultVideoUrl, setDefaultVideoUrl] = useState(false);
  const dateFormat = "DD-MM-YYYY";
  const timeFormat = "YYYY-MM-DD HH:mm:ss";
  

  const onFinish = (values) => {
    let data = {};
    data["start_time"] = moment(values?.start_time, timeFormat).format(
      timeFormat
    );
    // values?.start_time?.format(timeFormat);
    data["end_time"] = moment(values?.end_time, timeFormat).format(timeFormat);
    //  values?.end_time?.format(timeFormat);
    data["video_url"] = editAd ? editAd?.video_url : values?.video_url;
    data["default_video_url"] = editAd
      ? editAd?.default_video_url
      : values?.default_video_url;





    const convertedTvAds = values?.tv_ads?.map((tvad) => {
      const convertedTvAd = { ...tvad }; 

      
      if (!moment.isMoment(convertedTvAd?.start_time) || convertedTvAd?.start_time === null || convertedTvAd?.start_time === undefined) {
      message.error("Please Select Start Time");
      return "";
    }
    if (!moment.isMoment(convertedTvAd?.end_time) || convertedTvAd?.end_time === null || convertedTvAd?.end_time === undefined) {
      message.error("Please Select End Time");
      return "";
    }
    if(moment.isMoment(convertedTvAd?.start_time) && moment.isMoment(convertedTvAd?.end_time)){
      if(convertedTvAd.start_time.format(timeFormat) > convertedTvAd.end_time.format(timeFormat)){
        message.error("End Time is lesser than Start Time");
        return "";
      }
    }
    if (moment.isMoment(convertedTvAd?.start_time)) {
      convertedTvAd.start_time = convertedTvAd.start_time.format(timeFormat);
    }
  
    if (moment.isMoment(convertedTvAd?.end_time)) {
      convertedTvAd.end_time = convertedTvAd.end_time.format(timeFormat);
    }
      return convertedTvAd;
    });
    let obj = {...values,tvads:convertedTvAds};

    const data1 = {
      tv_name : values?.tv_name,
      tv_ads : JSON.stringify(obj?.tvads),
      default_video_url : values?.default_video_url
    }

    setLoading(true);
    postHospitalTvAds(data1)
      .then((result) => {
        setLoading(false);
        onSave();
        fetchAds();
      })
      .catch((error) => {
        setLoading(false);
      });
    // }
  };
  const onUpdateAds = (values) => {
    let data = {};
    data["isSponsor"] = componentDisabled;
    data["start_date"] = values?.start_date?.format("DD-MM-YYYY");
    data["end_date"] = values?.end_date?.format("DD-MM-YYYY");
    data["image"] = imageUser?.key;
    data["text"] = editAd ? editAd?.text : values?.text;
    data["video"] = videoUser?.key;
    data["createdBy"] = createdBy;
    const convertedDate1 = new Date(
      values?.start_date?.format("DD-MM-YYYY").split("-").reverse().join("-")
    );
    const convertedDate2 = new Date(
      values?.end_date?.format("DD-MM-YYYY").split("-").reverse().join("-")
    );
    if (values?.start_date?.format("DD-MM-YYYY") === undefined) {
      message.error("Please Select Start Date");
      return "";
    }
    if (values?.end_date?.format("DD-MM-YYYY") === undefined) {
      message.error("Please Select End Date");
      return "";
    }
    if (convertedDate1 > convertedDate2) {
      message.error("End Date is lesser than Start Date");
    } else {
      setLoading(true);
      updateHospitalTvAds(editAd?.uuid, data)
        .then((result) => {
          setLoading(false);
          onSave();
          fetchAds();
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, "0");
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month starts from 0 (January is 0)
  const year = currentDate.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;
  const [start_date, setStart_date] = useState(
    editAd?.id
      ? moment(editAd.start_date, "DD-MM-YYYY")
      : moment(formattedDate, "DD-MM-YYYY")
  );
  const [end_date, setEnd_date] = useState(
    editAd?.id
      ? moment(editAd.end_date, "DD-MM-YYYY")
      : moment(formattedDate, "DD-MM-YYYY")
  );
  const [componentDisabled, setComponentDisabled] = useState(editAd?.isSponsor);
  const onUploadLogoDone = (imageResponse) => {
    setUserImage(imageResponse);
  };
  const onUploadVideoDone = (videoResponse) => {
    setUserVideo(videoResponse);
  };
  
  return (
    <Form
      name="dynamic_form_nest_item"
      className="faqs_form"
      layout="vertical"
      onFinish={editAd?.id ? onUpdateAds : onFinish}
      autoComplete="off"
      form={form}
    >
      <Row
        style={{ display: "flex", marginBottom: 8 }}
        align="baseline"
        justify="space-between"
      >
        <Col span={22}>
          <Form.Item
            name={"tv_name"}
            // fieldKey={[fieldKey, "question"]}
            label="Tv Name or Number"
            rules={[
              {
                required: true,
                message: "Please enter the Tv Name or Number",
              },
            ]}
          >
            <Input
              // defaultValue={editAd?.text || ""}
              placeholder="Tv Name or Number"
              className="custom-input"
            />
          </Form.Item>
          <Form.List name="tv_ads">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <>
                    <Row
                      key={key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                      justify="space-between"
                    >
                      <Col span={22}>
                        <Form.Item
                          {...restField}
                          name={[name, "video_url"]}
                          fieldKey={[fieldKey, "video_url"]}
                          label="Video Url"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the Video Url!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Video Url"
                            className="custom-input"
                          />
                        </Form.Item>
                        <Form.Item
                        {...restField}
                        name={[name, "default_video_url"]}
                        fieldKey={[fieldKey, "default_video_url"]}
                          label=" is Default Video "
                          // initialValue={true}
                          valuePropName="checked"
                        >
                          <Checkbox
                           checked={defaultVideoUrl}
                            // checked={componentDisabled}
                            onChange={(e) =>
                              setComponentDisabled(e.target.checked)
                            }
                          ></Checkbox>
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, "play_order"]}
                          fieldKey={[fieldKey, "play_order"]}
                          label="Player Order"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the Player Order!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Player Order"
                            className="custom-input"
                          />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, "start_time"]}
                          fieldKey={[fieldKey, "start_time"]}
                          label="Start Time"
                        >
                          <DatePicker
                            showTime={true}
                            format="YYYY-MM-DD HH:mm:ss"
                            onChange={(date, dateString) =>
                              setStartTime(
                                moment(dateString, timeFormat).format(
                                  timeFormat
                                )
                              )
                            }
                            // format={dateFormat}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "end_time"]}
                          fieldKey={[fieldKey, "end_time"]}
                          label="End Time"
                        >
                          <DatePicker
                            showTime={true}
                            format="YYYY-MM-DD HH:mm:ss"
                            onChange={(date, dateString) =>
                              setEndTime(
                                moment(dateString, timeFormat).format(
                                  timeFormat
                                )
                              )
                            }
                            // format={dateFormat}
                          />
                        </Form.Item>

                        {/* <Form.Item
            name={"video_url"}
            // fieldKey={[fieldKey, "question"]}
            label=" Video Url "
            rules={[
              {
                required: true,
                message: "Please enter the video url",
              },
            ]}
          >
            <Input
              // defaultValue={editAd?.text || ""}
              placeholder="Video Url"
              className="custom-input"
            />
          </Form.Item>
          <Form.Item name="default_video_url" label=" is Default Video ">
            <Checkbox
              checked={componentDisabled}
              onChange={(e) => setComponentDisabled(e.target.checked)}
            ></Checkbox>
          </Form.Item>
          <Form.Item
            name={"play_order"}
            // fieldKey={[fieldKey, "question"]}
            label="Play Order"
            rules={[
              {
                required: true,
                message: "Please enter Play Order",
              },
            ]}
          >
            <Input
              // defaultValue={editAd?.text || ""}
              placeholder="Play Order"
              className="custom-input"
            />
          </Form.Item> */}

                        {/* <Form.Item name="start_time" label="Start Time ">
            <DatePicker
              showTime={true}
              format="YYYY-MM-DD HH:mm:ss"
              onChange={(date, dateString) =>
                setStartTime(moment(dateString, timeFormat).format(timeFormat))
              }
              // format={dateFormat}
            />
          </Form.Item>
          <Form.Item name="end_time" label="End Time ">
            <DatePicker
              showTime={true}
              format="YYYY-MM-DD HH:mm:ss"
              onChange={(date, dateString) =>
                setEndTime(moment(dateString, timeFormat).format(timeFormat))
              }
              // format={dateFormat}
            />
          </Form.Item> */}
                      </Col>
                      <Col>
                        <CloseCircleFilled
                          style={{ fontSize: "20px" }}
                          onClick={() => remove(name)}
                        />
                      </Col>
                    </Row>
                    <Divider />
                  </>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Tv Ads
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
      </Row>
      <Divider />
      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ marginRight: "8px" }}>
          {editAd?.id ? "Update" : "Submit"}
        </Button>
        <Button type="primary" onClick={onClickBack}>
          Back
        </Button>
      </Form.Item>
    </Form>
  );
};

const ViewAds = ({
  onEdit,
  ads,
  loading,
  setLoading,
  fetchAds,
  setUserImage,
}) => {
  const onClickEdit = (itemUuid) => {
    onEdit(itemUuid, true);
  };
  const onClickDelete = (itemUuid) => {
    deleteHospitalTvAds(itemUuid)
      .then((result) => {
        setLoading(false);
        notification.success({
          message: DELETED_TV_ADS,
        });
        fetchAds();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <>
      {ads && (
        <>
          <h3>Hospital Tv Ads</h3>
          <List
            itemLayout="horizontal"
            dataSource={ads}
            renderItem={(item) => (
              <>
                <List.Item>
                  <List.Item.Meta title="" description={item?.tv_name} />
                  <Button
                    type="primary"
                    onClick={() => onClickEdit(item?.uuid)}
                    style={{ marginRight: "8px" }}
                  >
                    Edit
                  </Button>
                  <Button
                    type="danger"
                    onClick={() => onClickDelete(item?.uuid)}
                    icon={<DeleteOutlined />}
                  ></Button>
                </List.Item>
              </>
            )}
          />
          <br />
          <Button type="primary" onClick={onEdit}>
            Create Tv Ads
          </Button>
        </>
      )}
    </>
  );
};
