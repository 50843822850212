import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, Form, Input } from 'antd';

import get from 'lodash/get';

import { useState } from 'react';
import { validateMessages } from '../../../constants/validation';
import { ContactCodeSelector } from '../../contactCodeSelector/ContactCodeSelector';
import { TitleSelector } from '../../titleSelector/TitleSelector';
import edit_white from '../../../assets/icons/edit_white.svg';

const EditAssistantForm = ({ details, toggleEditAssistant }) => {
  const [edit, setEdit] = useState(false);
  // const [loading, setLoading] = useState(false);

  const toggleEdit = () => {
    setEdit(!edit);
  };

  return (
    // <Spin spinning={loading}>
    <div className="add-drawer-content">
      <Row justify="space-between">
        <Col className="drawer-title">Assistant Profile</Col>
        <Col>
          <CloseOutlined onClick={toggleEditAssistant} />
        </Col>
      </Row>
      <div className="add-drawer-form">
        {edit ? (
          <EditAssistantDetails details={details} toggleEdit={toggleEdit} />
        ) : (
          <ViewAssitantDetails details={details} toggleEdit={toggleEdit} />
        )}
      </div>
    </div>
    // </Spin>
  );
};

export default EditAssistantForm;

const ViewAssitantDetails = ({ details, toggleEdit }) => {
  return (
    <>
      <Space size="large" direction="vertical">
        <div>
          <div className="details-label">Assistant Name</div>
          <div>
            {get(details, 'title')} {get(details, 'name')}
          </div>
        </div>
        <div>
          <div className="details-label">Work Email Address</div>
          <div>{get(details, 'email')}</div>
        </div>
        <div>
          <div className="details-label">Contact number</div>
          <div>
            +{get(details, 'contact_code')} {get(details, 'contact_number')}
          </div>
        </div>
        <Button
          type="primary"
          onClick={toggleEdit}
          icon={
            <img
              src={edit_white}
              alt=""
              height="18px"
              style={{ marginRight: '4px' }}
            />
          }
        >
          Edit details
        </Button>
      </Space>
    </>
  );
};

const EditAssistantDetails = ({ details, toggleEdit }) => {
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = (values) => {};

  return (
    <Form
      name="control-hooks"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      validateMessages={validateMessages}
      initialValues={{
        email: get(details, 'email'),
        title: get(details, 'title'),
        contact_code: get(details, 'contact_code'),
        contact_number: get(details, 'contact_number'),
        name: get(details, 'name'),
      }}
    >
      <Form.Item
        label="Assistant Name"
        name="name"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input className="custom-input" addonBefore={TitleSelector} />
      </Form.Item>
      <Form.Item
        label="Work Email"
        name="email"
        rules={[
          {
            required: true,
            type: 'email',
          },
        ]}
      >
        <Input className="custom-input" />
      </Form.Item>
      <Form.Item
        label="Contact Number"
        name="contact_number"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input className="custom-input" addonBefore={ContactCodeSelector} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};
