import lineup_link_grey from '../../assets/icons/lineup_link_grey.svg';
import appointments_grey from '../../assets/icons/appointments_grey.svg';
import get from 'lodash/get';
import DoctorProfile from '../../components/doctorprofile/DoctorProfile';
import { Col, Row, Space,Button } from 'antd';
import { Rate } from 'antd';
import { APPOINTMENTS } from '../routes';
import { Link } from 'react-router-dom';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { percentUtility } from '../../utilities/helpers';
import CustomTag from '../../components/tag/CustomTag';

export const doctorsColumn = ({
  toggleDoctorDetails,
  toggleLinkModal,
  hospitalSlug,
  removeDoctor,
  isDepartmentScreen
}) => [
  {
    title: 'Doctors',
    dataIndex: 'name',
    key: 'name',
    render: (name, record) => (
      <div
        onClick={() => toggleDoctorDetails(record)}
        style={{ cursor: 'pointer' }}
      >
        <DoctorProfile
          entry_name={name}
          title={get(record, 'title')}
          specializations={get(record, 'specialisations')}
          experience={get(record, 'doctor_detail.experience')}
          image={get(record?.image, 'url')}
        />
      </div>
    ),
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Ratings',

    key: 'ratings',
    render: (record) => (
      <>
        <Rate
          value={get(record, 'doctor_detail.rating')}
          disabled={true}
          style={{ fontSize: '14px', width: '110px' }}
        />
        <p className="x-small-grey">
          <a
            href={`${
              process.env.REACT_APP_WEBSITE_URL
            }/hospital/${hospitalSlug}/doctor/${get(record, 'doctor.id')}`}
            target="_blank"
            rel="noreferrer"
          >
            {get(record, 'doctor_detail.rating')} ({get(record, 'reviews')}{' '}
            Reviews)
          </a>
        </p>
      </>
    ),
  },
  {
    title: 'Appointement Details',

    key: 'appointment_details',
    render: (record) => {
      return (
        <>
          <Row>
            <Space size={15}>
              <Col className="w-50">
                <span className="large">
                  {get(
                    percentUtility(get(record, 'appointment_status')),
                    'total'
                  )}
                </span>
                <p className="x-small-grey">Appointments</p>
              </Col>
              <Col className="w-50">
                <span className="large">
                  {isNaN(
                    get(
                      percentUtility(get(record, 'appointment_status')),
                      'done'
                    )
                  )
                    ? '0'
                    : get(
                        percentUtility(get(record, 'appointment_status')),
                        'done'
                      )}
                </span>
                {'   '}
                <span className="green-highlight">
                  {isNaN(
                    get(
                      percentUtility(get(record, 'appointment_status')),
                      'donePercent'
                    )
                  )
                    ? '(0%)'
                    : `(${get(
                        percentUtility(get(record, 'appointment_status')),
                        'donePercent'
                      )}%)`}
                </span>
                <p className="x-small-grey">Fulfilled</p>
              </Col>
              <Col className="w-50">
                <span className="large">
                  {isNaN(
                    get(
                      percentUtility(get(record, 'appointment_status')),
                      'cancel'
                    )
                  )
                    ? '0'
                    : get(
                        percentUtility(get(record, 'appointment_status')),
                        'cancel'
                      )}
                </span>
                <span className="red-highlight">
                  {isNaN(
                    get(
                      percentUtility(get(record, 'appointment_status')),
                      'cancelPercent'
                    )
                  )
                    ? '(0%)'
                    : `(${get(
                        percentUtility(get(record, 'appointment_status')),
                        'cancelPercent'
                      )}%)`}
                </span>
                <p className="x-small-grey">Cancelled</p>
              </Col>
              <Col className="w-50">
                <span className="large">
                  {isNaN(
                    get(
                      percentUtility(get(record, 'appointment_status')),
                      'noshow'
                    )
                  )
                    ? '0'
                    : get(
                        percentUtility(get(record, 'appointment_status')),
                        'noshow'
                      )}
                </span>
                <span className="orange-highlight">
                  {isNaN(
                    get(
                      percentUtility(get(record, 'appointment_status')),
                      'noshowPercent'
                    )
                  )
                    ? '(0%)'
                    : `(${get(
                        percentUtility(get(record, 'appointment_status')),
                        'noshowPercent'
                      )}%)`}
                </span>
                <p className="x-small-grey">No Show</p>
              </Col>
            </Space>
          </Row>
        </>
      );
    },
  },
  {
    title: 'Actions',
    key: 'actions',

    render: (record) => (
      <Row>
        <Space size={20}>
          <Col align="middle" style={{ cursor: 'pointer' }}>
            <img
              src={appointments_grey}
              alt=""
              className="appointment-action-icon"
            />
            <p className="small">
              <Link to={APPOINTMENTS}>Appointements</Link>
            </p>
          </Col>
          <Col
            align="middle"
            style={{ cursor: 'pointer' }}
            onClick={() => toggleDoctorDetails(record)}
          >
            <ExclamationCircleOutlined />
            <p className="small">View Details</p>
          </Col>
          <Col
            align="middle"
            onClick={() => toggleLinkModal(record)}
            // onClick={() => props.toggleDoctorMedicalHistory()}
            style={{ cursor: 'pointer' }}
          >
            <img
              src={lineup_link_grey}
              alt=""
              className="appointment-action-icon"
            />
            <p className="small">Lineup Link</p>
          </Col>
          {
            !isDepartmentScreen && (
              <Col>
          <Button
        type="danger"
        onClick={() => removeDoctor(record)}
        icon={<DeleteOutlined />}
      >
      </Button>
          </Col>
            )
          }
        </Space>
      </Row>
    ),
  },
];
