import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import includes from 'lodash/includes';
import * as ROUTES from '../constants/routes';

const requireAuth =
  (ChildComponnent, roles = []) =>
  () => {
    const userRoles = useSelector((state) => get(state, 'auth.roles'));
    let isLoggedIn = useSelector((state) => get(state, 'auth.isLoggedIn'));
    if (roles) {
      if (!roles.some((item) => includes(userRoles, item))) {
        isLoggedIn = false;
      }
    }

    if (!isLoggedIn) {
      return <Redirect to={ROUTES.LOGIN} />;
    }

    return <ChildComponnent />;
  };

export default requireAuth;
