import {
  Col,
  Radio,
  Row,
  Space,
  Spin,
  DatePicker,
  Select,
  Input,
  Table,
  Drawer,
  Modal,
  notification,
} from "antd";
import { useEffect, useState } from "react";
import moment from "moment";
import { filterDays } from "../../constants/options";
import get from "lodash/get";
import "./styles.scss";
import { YEAR_MONTH_DAY } from "../../constants/constantsDateFormats";
import {
  getHospitalPatients,
  deleteHospitalMediaAd,
} from "../../redux/actions/hospitalAction";
import { useSelector } from "react-redux";
import { HospitalPatientsColumn } from "../../constants/table/hospitalPatientsColumn";
import { useParams } from "react-router";
import { DELETED_AD } from "../../constants/messages";
import ViewAdsForm from "../../components/drawerContent/viewAdsForm/ViewAdsForm";
import HospitalPatientForm from "../../components/drawerContent/HospitalPatientForm/HospitalPatientForm";

const { Option } = Select;
const { Search } = Input;

const { RangePicker } = DatePicker;
const HospitalPatientsScreen = () => {
  const params = useParams();
  const hospital_uuid = useSelector((state) =>
    get(state, "auth.currentHospital.id")
  );
  const [loading, setLoading] = useState(false);
  const [hospitalPatientsResult, setHospitalPatientsResult] = useState({
    hospitalPatients: [],
    pagination: null,
  });
  const [filter, setFilter] = useState({
    page: 1,
    fromDate: moment().format(YEAR_MONTH_DAY),
    toDate: moment().format(YEAR_MONTH_DAY),
    days: 1,
    status: null,
    name: "",
  });
  const handleDays = (days) => {
    const toDate = moment().format(YEAR_MONTH_DAY);
    const fromDate = moment(moment())
      .subtract(days, "d")
      .format(YEAR_MONTH_DAY);
    fetchHospitalPatients(
      get(filter, "page"),
      fromDate,
      toDate,
      days,
      get(filter, "status"),
      get(filter, "name")
    );
  };

  const [billingInfo, setBillingInfo] = useState({
    visible: false,
    data: null,
  });

  const [visibleDone, setVisibleDone] = useState(false);
  const [doneMessage, setDoneMessage] = useState("");

  const toggleDone = (message = "") => {
    setVisibleDone(!visibleDone);
    setDoneMessage(message);
  };

  const [markPaidModal, setMarkPaidModal] = useState({
    visible: false,
    data: null,
  });
  const toggleMarkPaid = (data = null) => {
    setMarkPaidModal({ visible: !get(markPaidModal, "visible"), data: data });
  };

  const handleRange = (dates) => {
    if (dates) {
      const toDate = moment(dates[1]).format(YEAR_MONTH_DAY);
      const fromDate = moment(dates[0]).format(YEAR_MONTH_DAY);
      fetchHospitalPatients(
        get(filter, "page"),
        fromDate,
        toDate,
        "null",
        get(filter, "status"),
        get(filter, "name")
      );
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const fetchHospitalPatients = (
    page,
    fromDate,
    toDate,
    days,
    status = null,
    name = ""
  ) => {
    setLoading(true);
    getHospitalPatients(
      hospital_uuid
      // fromDate, toDate, status, page, name
    )
      .then((result) => {
        console.log("result12", result);
        setLoading(false);
        setHospitalPatientsResult({
          hospitalPatients: result,
          pagination: get(result, "meta.pagination"),
        });
        setFilter({ page, fromDate, toDate, days, status, name });
      })
      .catch((err) => {
        setLoading(false);
      });

    // fetchHospitalTvAdById()
  };
  const onSearch = (value) => {
    fetchHospitalPatients(
      get(filter, "page"),
      get(filter, "fromDate"),
      get(filter, "toDate"),
      get(filter, "days"),
      get(filter, "status"),
      value
    );
  };

  useEffect(() => {
    fetchHospitalPatients(
      get(filter, "page"),
      get(filter, "fromDate"),
      get(filter, "toDate"),
      get(filter, "days"),
      get(filter, "status"),
      get(filter, "name")
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [openNewAd, setOpenNewAd] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);

  const createNewTvAds = () => {
    setOpenNewAd(true);
  };
  const closeNewHospitalPatient = () => {
    setOpenNewAd(false);
    setCurrentUserId(null);
  };
  const toggleEdit = (itemUuid) => {
    setOpenNewAd(true);
    setCurrentUserId(itemUuid);
  };
  const [openModal, setOpenModal] = useState(false);
  const [currentAd, setCurrentAd] = useState(null);
  const toggleViewAds = (data) => {
    setCurrentAd(data);
    setOpenModal(true);
    // currentTvId && history.push(`/media-ads/${currentTvId}`)
  };
  const onCloseModal = () => {
    setCurrentAd(null);
    setOpenModal(false);
  };
  const deleteTvAd = (itemUuid) => {
    deleteHospitalMediaAd(itemUuid)
      .then((result) => {
        setLoading(false);
        notification.success({
          message: DELETED_AD,
        });
        const days = 1;
        const toDate = moment().format(YEAR_MONTH_DAY);
        const fromDate = moment(moment())
          .subtract(days, "d")
          .format(YEAR_MONTH_DAY);
        fetchHospitalPatients(
          get(filter, "page"),
          fromDate,
          toDate,
          get(filter, "days"),
          get(filter, "status"),
          get(filter, "name")
        );
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={18} push={6}>
          <Row align="end">
            <Space>
              <Radio.Group
                options={filterDays}
                value={get(filter, "days")}
                optionType="button"
                buttonStyle="solid"
                onChange={(event) => handleDays(get(event, "target.value"))}
              />
              <RangePicker
                onChange={(dateStrings) => handleRange(dateStrings)}
              />
            </Space>
          </Row>
        </Col>
        <Col span={6} pull={18}>
          <div className="screen-title">Patients</div>
        </Col>
      </Row>

      <Table
        columns={HospitalPatientsColumn({
          deleteTvAd,
          toggleViewAds,
          toggleEdit,
        })}
        dataSource={get(hospitalPatientsResult, "hospitalPatients")}
        pagination={false}
        // pagination={{
        //   pageSize: get(hospitalPatientsResult, "pagination.per_page", 1),
        //   current: get(hospitalPatientsResult, "pagination.current_page", 1),
        //   total: get(hospitalPatientsResult, "pagination.total", 10),
        //   onChange: (val) => {
        //     fetchHospitalPatients(
        //       val,
        //       get(filter, "fromDate"),
        //       get(filter, "toDate"),
        //       get(filter, "days"),
        //       get(filter, "status"),
        //       get(filter, "name")
        //     );
        //   },

        //   position: ["bottomLeft"],
        // }}
      />
      <Drawer
        className="main-drawer"
        destroyOnClose
        width="25%"
        closable={false}
        onClose={closeNewHospitalPatient}
        visible={openNewAd}
      >
        <HospitalPatientForm
          closeNewHospitalPatient={closeNewHospitalPatient}
          data={get(billingInfo, "data")}
          tv_id={params?.currentTvId}
          currentUserId={currentUserId}
          setCurrentUserId={setCurrentUserId}
          fetchHospitalPatients={fetchHospitalPatients}
          filter={filter}
          hospital_uuid={hospital_uuid}
        />
      </Drawer>
    </Spin>
  );
};

export default HospitalPatientsScreen;
