/* eslint-disable no-template-curly-in-string */
export const validateMessages = {
  required: 'Please Enter ${label}!',
  types: {
    email: 'Please enter a valid email!',
    number: 'Please enter a valid number!',
    integer: '${label} must only contain integers!',
  },
  min: 6,
};
