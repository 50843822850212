import { Link, useHistory, useLocation } from 'react-router-dom';
import { Layout, Menu, Select } from 'antd';
import logo from '../../../assets/images/logo.svg';
import MenuKeys from '../../../constants/menu';
import map from 'lodash/map';
import get from 'lodash/get';
import includes from 'lodash/includes';
import './style.scss';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { switchHospital } from '../../../redux/actions/authAction';
import { DASHBOARD, getLeftPanelKey } from '../../../constants/routes';
import { CloseCircleOutlined, CloseOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Sider } = Layout;

export default function LayoutSider({ setIsLoading, showSidePanel, menuButtonClose }) {
  const [activeKey, setActiveKey] = useState('0');
  const roles = useSelector((state) => get(state, 'auth.roles'));
  const hospitalList = useSelector((state) => get(state, 'auth.hospitals'));
  const media = window.matchMedia(`(min-width: 1023px)`);
  const currentHospital = useSelector((state) =>
    get(state, "auth.currentHospital")
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const handleSwitchHospital = (value) => {
    setIsLoading(true);
    history.push(DASHBOARD);
    const hospital_id = { hospital_uuid: value };
    dispatch(switchHospital(hospital_id))
      .then(() => {
        setIsLoading(false);
        menuButtonClose();  // Close the menu after switching the hospital
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const location = useLocation();
  
  return (
    <Sider className={`left-side-panel ${showSidePanel ? "open" : "close"}`}>
      <div className="logo">
        <img src={logo} alt="logo" />
        {!media.matches && ( <CloseOutlined onClick={menuButtonClose}/>)}
      </div>
      {!includes(roles, 1) && (
        <Select
          defaultValue={get(currentHospital, "id")} 
          className="hospital-selector"
          onChange={(value) => handleSwitchHospital(value)}
        >
          {map(hospitalList, (hospital, i) => (
            <Option key={i} value={get(hospital, 'id')}>
              <div className="hospital-option">
                <img
                  src={get(hospital?.logo_url, "url")}
                  alt=""
                  style={{ width: "30px" }}
                />
                {get(hospital, "name")}
              </div>
            </Option>
          ))}
        </Select>
      )}

      <Menu
        selectedKeys={[getLeftPanelKey(get(location, "pathname"))]}
        className="sider-menu"
        mode="inline"
        onClick={({ key }) => { setActiveKey(parseInt(key)); menuButtonClose(); }}
      >
        {map(MenuKeys, (m, i) => {
          if (get(m, "subMenu")) {
            return (
              <Menu.SubMenu
                key={`${i}`}
                icon={<img src={get(m, 'icon')} alt="" />}
                title={get(m, 'name')}
              >
                {map(get(m, "child", []), (subm, x) => (
                  <Menu.Item
                    key={`${i}.${x}`}
                    icon={<img src={get(subm, 'icon')} alt="" />}
                    onClick={menuButtonClose}
                  >
                    <Link to={get(subm, "route")}>{get(subm, "name")}</Link>
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            );
          }
          return (
            get(m, "allowed_roles").some((role) => roles.includes(role)) && (
              <Menu.Item
                key={get(m, "key")}
                icon={
                  activeKey === i ? (
                    <img src={get(m, "active_icon")} alt="" />
                  ) : (
                    <img src={get(m, "inactive_icon")} alt="" />
                  )
                }
                onClick={({ key }) => { setActiveKey(parseInt(key)); menuButtonClose(); }}
              >
                <Link to={get(m, "route")}>{get(m, "name")}</Link>
              </Menu.Item>
            )
          );
        })}
      </Menu>
    </Sider>
  );
}
