import { Upload, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import get from 'lodash/get';
import remove from 'lodash/remove';
import { CONTACTUS_IMAGE_UPLOAD } from '../../constants/apiEndpoints';

const MultipleImageUpload = ({ onUploadDone, imageList }) => {
  const [fileList, setFileList] = useState(imageList ? imageList : []);
  const [preview, setPreview] = useState({
    visible: false,
    url: null,
  });

const [allFiles,setAllFiles] = useState([]);
  const handleChange = ({ file, fileList }) => {
    if (get(file, 'status') === 'uploading') {
      setFileList([...fileList]);
      return;
    }
    if (get(file, 'status') === 'done') {
      let response = get(file, 'response.data');
      let fileObj = {
        uid: get(response, 'key'),
        name: get(response, 'key'),
        status: 'done',
        url: get(response, 'url'),
      };
      let list = [...allFiles];
      list.push(fileObj);
      setAllFiles(list);
      console.log("allFiles",allFiles,list);
      setFileList(list); 
      onUploadDone(list); 
      return;
    }
  };

  const onPreviewImage = (file) => {
    setPreview({
      visible: true,
      url: get(file, 'url'),
    });
  };
  const onRemoveImage = (file) => {
    remove(fileList, ['uid', get(file, 'uid')]);
    setFileList([...fileList]);
    onUploadDone(fileList);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Upload
        listType="picture-card"
        fileList={fileList}
        action={CONTACTUS_IMAGE_UPLOAD}
        multiple
        accept="image/*"
        onChange={handleChange}
        onPreview={onPreviewImage}
        onRemove={onRemoveImage}
      >
        {fileList.length >= 5 ? null : uploadButton}
      </Upload>
      <Modal
        open={get(preview, 'visible')}
        title={''}
        footer={null}
        onCancel={() =>
          setPreview({
            visible: false,
            url: null,
          })
        }
      >
        <img
          alt="example"
          style={{ width: '100%' }}
          src={get(preview, 'url')}
        />
      </Modal>
    </>
  );
};

export default MultipleImageUpload;
