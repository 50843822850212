import { Col, Form, Space, Button, notification, InputNumber } from 'antd';
import { useState } from 'react';
import { validateMessages } from '../../../constants/validation';
import assign from 'lodash/assign';
import get from 'lodash/get';

// Styles
import './styles.scss';
import { updateHospitalDetails } from '../../../redux/actions/hospitalAction';
import { DETAILS_UPDATED } from '../../../constants/messages';

const optionsCancellation = [
  { name: 'Upto 30 min prior', value: 30 },
  { name: 'Upto 1 hours prior', value: 60 },
  { name: 'Upto 2 hours prior', value: 120 },
  { name: 'Upto 3 hours prior', value: 180 },
  { name: 'Upto 4 hours prior', value: 240 },
];

const optionsBooking = [
  { name: 'Upto 1 week in future', value: 7 },
  { name: 'Upto 2 weeks in future', value: 14 },
  { name: 'Upto 3 weeks in future', value: 21 },
  { name: 'Upto 4 weeks in future', value: 28 },
];
const Appointments = ({ setLoading, details, hospital_uuid, fetchDetails }) => {
  const [edit, setEdit] = useState(false);
  const onSave = () => {
    setEdit(false);
  };
  const onEdit = () => {
    setEdit(true);
  };

  return (
    <>
      {edit ? (
        <EditAppointmentDetails
          details={details}
          onSave={onSave}
          hospital_uuid={hospital_uuid}
          setLoading={setLoading}
          fetchDetails={fetchDetails}
        />
      ) : (
        <ViewAppointmentDetails details={details} onEdit={onEdit} />
      )}
    </>
  );
};

export default Appointments;

const EditAppointmentDetails = ({
  setLoading,
  onSave,
  hospital_uuid,
  details,
  fetchDetails,
}) => {
  const onFinish = (values) => {
    setLoading(true);
    assign(values, { step: 3 });
    updateHospitalDetails(hospital_uuid, values)
      .then((result) => {
        setLoading(false);
        onSave();
        notification.success({
          message: (DETAILS_UPDATED),
        });
        fetchDetails();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <Form
      name="basic"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      validateMessages={validateMessages}
      initialValues={{
        appointment_cancellation_duration:
          (get(details, 'appointment_cancellation_duration', 0)).toFixed(1),
        appointment_booking_duration:
          (get(details, 'appointment_booking_duration', 7)).toFixed(1),
      }}
    >
      <Col span={8}>
        <Form.Item
          label="Appointment cancellation duration"
          name="appointment_cancellation_duration"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber addonAfter="hours" size="large" />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label="Appointment booking duration"
          name="appointment_booking_duration"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber addonAfter="days" size="large" />
        </Form.Item>
      </Col>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

const ViewAppointmentDetails = ({ details, onEdit }) => {
  var cancel_duration = optionsCancellation.find((obj) => {
    return obj.value === get(details, 'appointment_cancellation_duration');
  });
  var booking_duration = optionsBooking.find((obj) => {
    return obj.value === get(details, 'appointment_booking_duration');
  });

  console.log('CANAASDSAD>>>', cancel_duration);

  return (
    <>
      <Space direction="vertical">
        <div className="details-label">Appointment cancellation duration</div>
        <div className="medium">{get(cancel_duration, 'name')}</div>

        <div className="details-label">Appointment booking duration</div>
        <div className="medium">{get(booking_duration, 'name')}</div>

        <Button type="primary" onClick={onEdit}>
          Edit Details
        </Button>
      </Space>
    </>
  );
};
