import get from 'lodash/get';
/**
 * Constants
 */
import * as TYPES from '../../constants/actionTypes';
import * as APIS from '../../constants/apiEndpoints';

export const login =
  (credentials) =>
  (dispatch, getState, { api }) => {
    return api
      .post(APIS.LOGIN, credentials)
      .then((result) => {
        if(result?.user?.roles?.length > 1){
          return result;
        }else{
        api.defaults.headers.common.authorization = `Bearer ${get(
          result,
          'access_token',
        )}`;

        dispatch({
          type: TYPES.LOGIN,
          data: result,
        });
        return result;
      }
      })
      .catch((error) => {
        throw error;
      });
  };

  export const loginMoreRoles =
  (credentials,roles) =>
  (dispatch, getState, { api }) => {
    return api
      .post(APIS.LOGIN, credentials)
      .then((result) => {
        if(result?.user?.roles?.length > 1){
          result.user = {...result?.user,roles :[roles]}
        api.defaults.headers.common.authorization = `Bearer ${get(
          result,
          'access_token',
        )}`;

        dispatch({
          type: TYPES.LOGIN,
          data: result,
        });
        return result;
      }
      })
      .catch((error) => {
        throw error;
      });
  };

export const switchHospital =
  (hospital) =>
  (dispatch, getState, { api }) => {
    return api.post(APIS.SWITCH, hospital).then((result) => {
      api.defaults.headers.common.authorization = `Bearer ${get(
        result,
        'access_token',
      )}`;

      dispatch({
        type: TYPES.SWITCH,
        data: result,
      });
    });
  };

export const logout =
  () =>
  (dispatch, getState, { api }) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: TYPES.LOGOUT,
      });
      resolve();
      api.defaults.headers.common = {};
    });
  };
