import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Input, Row, Spin, Table } from 'antd';
import { useEffect, useState } from 'react';
import get from 'lodash/get';
import AddAssistantForm from '../../components/drawerContent/addAssistantForm/AddAssistantForm';
import { assistantsColumn } from '../../constants/table/assistantsColumn';
import {
  deleteDelegates,
  getDelegates,
} from '../../redux/actions/doctorAction';
import SimpleModal from '../../components/modals/simpleModal/SimpleModal';
import DoneModal from '../../components/modals/doneModal/DoneModal';
import EditAssistantForm from '../../components/drawerContent/editAssistantForm/EditAssistantForm';
import requireAuth from '../../hocs/requireAuth';

const AssistantsScreen = () => {
  const [addAssistantDrawer, setAddAssistantDrawer] = useState({
    visible: false,
    edit: false,
    data: null,
  });
  const [loading, setLoading] = useState(false);
  const [assistants, setAssistants] = useState([]);
  const [assistantId, setAssistantId] = useState();
  const [message, setMessage] = useState();

  const toggleAddAssistant = (data = null) => {
    setAddAssistantDrawer({
      visible: !get(addAssistantDrawer, 'visible'),
      edit: !get(addAssistantDrawer, 'visible'),
      data,
    });
  };

  const fetchAssistants = () => {
    getDelegates()
      .then((result) => {
        console.log(result);
        setAssistants(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchAssistants();
  }, []);

  const removeAssistant = (id) => {
    setLoading(true);
    deleteDelegates(id)
      .then((result) => {
        setLoading(false);
        fetchAssistants();
        toggleDone('Assistant removed!');
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const [removeModal, setRemoveModal] = useState(false);
  const toggleRemove = (data = null) => {
    setRemoveModal(!removeModal);
    setAssistantId(get(data, 'id'));
  };

  const [doneModal, setDoneModal] = useState(false);
  const toggleDone = (message) => {
    setRemoveModal(false);
    setMessage(message);
    setDoneModal(!doneModal);
  };

  const [editAssistantDrawer, setEditAssistantDrawer] = useState({
    visible: false,
    edit: false,
    data: null,
  });

  const toggleEditAssistant = (data = null) => {
    setEditAssistantDrawer({
      visible: !get(editAssistantDrawer, 'visible'),
      edit: !get(editAssistantDrawer, 'visible'),
      data,
    });
  };
  return (
    <Spin spinning={loading}>
      <Row justify="space-between" style={{ alignItems: 'baseline' }}>
        <Col>
          <div className="screen-title">Assistants</div>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => toggleAddAssistant()}
            icon={<PlusOutlined />}
          >
            Add Assistant
          </Button>
          <Drawer
            className="main-drawer"
            destroyOnClose
            width="30%"
            closable={false}
            onClose={() => toggleAddAssistant(null)}
            visible={get(addAssistantDrawer, 'visible')}
          >
            <AddAssistantForm toggleAddAssistant={toggleAddAssistant} />
          </Drawer>
        </Col>
      </Row>
      <Row className="search-appointment">
        <Col span={18} push={6}></Col>
        <Col span={6} pull={18}>
          <Input
            placeholder="Assistant name or email"
            allowClear
            size="large"
            prefix={<SearchOutlined style={{ fontSize: '12px' }} />}
          />
        </Col>
      </Row>
      <Table
        columns={assistantsColumn({ toggleRemove, toggleEditAssistant })}
        dataSource={assistants}
        pagination={false}
      />
      <SimpleModal
        okText="Yes"
        closable={false}
        centered={true}
        visible={removeModal}
        onCancel={() => toggleRemove()}
        onOk={() => removeAssistant(assistantId)}
        text="Do you want to remove assistant?"
      />

      <DoneModal
        message={message}
        visible={doneModal}
        toggleDone={toggleDone}
      />
      <Drawer
        className="main-drawer"
        destroyOnClose
        width="30%"
        closable={false}
        onClose={() => toggleEditAssistant(null)}
        visible={get(editAssistantDrawer, 'visible')}
      >
        <EditAssistantForm
          toggleEditAssistant={toggleEditAssistant}
          details={get(editAssistantDrawer, 'data')}
        />
      </Drawer>
    </Spin>
  );
};

export default requireAuth(AssistantsScreen, [3]);
