import get from "lodash/get";
import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import ReactPlayer, { ReactPlayerProps } from "react-player";

const ViewAdsForm = ({ currentAd }) => {
  const details = useSelector((state) => get(state, "auth.user"));
  const [url, setUrl] = React.useState(currentAd?.video_url);

  return (
    <div>
      <ReactPlayer
        // ref={playerRef}
        url={url}
        // 'https://www.youtube.com/shorts/z-fPnvLymcA'
        // 'https://www.youtube.com/shorts/l0IYXvA1Xi4'
        // 'https://www.youtube.com/watch?v=LXb3EKWsInQ'
        controls={true}
      />
    </div>
  );
};
export default ViewAdsForm;
