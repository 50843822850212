import { Button, Drawer } from 'antd';
import { useState } from 'react';
import get from 'lodash/get';
import MarkUnavailablityForm from '../forms/markUnavailablityForm';
import moment from 'moment';
import { YEAR_MONTH_DAY } from '../../constants/constantsDateFormats';
import find from 'lodash/find';


const MarkUnavailability = ({ refreshCalendar, selectedDoctorId,availability }) => {
  const [drawer, setSrawer] = useState({
    visible: false,
  });

  const toggleDrawer = (data = null) => {
    setSrawer({
      visible: !get(drawer, 'visible'),
    });
  };
  const onSuccess = () => {
    toggleDrawer();
    refreshCalendar();
  };
  let date = moment();
  let dayAvailablity = find(availability, [
    'date',
    date.format(YEAR_MONTH_DAY),
  ]);
  return (
    <>
      <Button onClick={() => toggleDrawer()}>Mark Unavailability</Button>
      <Drawer
        destroyOnClose
        width="30%"
        onClose={() => toggleDrawer(null)}
        title="Mark Unavailability"
        visible={get(drawer, 'visible')}
      >
        <MarkUnavailablityForm
          toggleDrawer={toggleDrawer}
          onSuccess={onSuccess}
          selectedDoctorId={selectedDoctorId}
          dayAvailablity={dayAvailablity}
        />
      </Drawer>
    </>
  );
};

export default MarkUnavailability;
