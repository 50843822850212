import { Row, Col, Select, List, Empty } from 'antd';

import { DownloadOutlined, CheckOutlined } from '@ant-design/icons';

// Styles
import './styles.scss';
import { useEffect, useState } from 'react';
import { getHospitalInvoice } from '../../../redux/actions/hospitalAction';
import get from 'lodash/get';
import { map } from '@antv/util';
import AddressHelper from '../../../utilities/addressHelper/AddressHelper';

const { Option } = Select;

const Billings = ({ setLoading, hospital_uuid, details }) => {
  const optionsBilling = [
    'Per appointment',
    'Per hospital',
    'Upto 2 hours prior',
    'Upto 3 hours prior',
    'Upto 4 hours prior',
  ];

  console.log('DETAILS >>', details);

  const [billedInvoices, setBilledInvoices] = useState({
    data: [],
    pagination: null,
  });
  const [unbilledInvoices, setUnbilledInvoices] = useState([]);

  const getHospitalInvoiceData = (page = 1) => {
    getHospitalInvoice(hospital_uuid, page)
      .then((result) => {
        console.log('RESULT>>>>', result);
        setBilledInvoices({
          data: get(result, 'billedInvoice.data'),
          pagination: get(result, 'billedInvoice.meta.pagination'),
        });
        setUnbilledInvoices(get(result, 'unbilledInvoice'));
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getHospitalInvoiceData();
    //eslint-disable-next-line
  }, []);

  console.log('Billed invoices>>>>', billedInvoices);

  return (
    <div className="billing-componenet">
      <Row>
        <Col span={6}>
          <div className="select-custom input-sec">
            <div className="label">Billing type</div>
            <div>{get(details, 'billing_method.name')}</div>
          </div>
        </Col>
      </Row>
      <div>
        <div className="title">Unbilled Amount</div>
        {unbilledInvoices.length > 0 ? (
          <div className="unbilled-row">
            {' '}
            {map(unbilledInvoices, (data) => (
              <div className="unbilled-card">
                <div className="tag">Status : Pending</div>

                <div className="info">
                  <div className="price-title">
                    $<span>{get(data, 'amount')}</span>
                  </div>
                  <div className="appointements">
                    for {get(data, 'units')}{' '}
                    {data.hospital.billing_method.name.split(' ')[1]}s
                    <div className="divider"></div>
                    Per {data.hospital.billing_method.name.split(' ')[1]} $
                    {get(data, 'unit_amount')}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <Empty
            description="No unbilled invoices found."
            className="empty-result"
          />
        )}
      </div>
      <div className="divider" />
      <Row>
        <Col span={16}>
          <div className="title">Previous Billings</div>
          <List
            itemLayout="vertical"
            pagination={{
              pageSize: get(billedInvoices, 'pagination.per_page', 1),
              current: get(billedInvoices, 'pagination.current_page', 1),
              total: get(billedInvoices, 'pagination.total', 1),
              onChange: (val) => {
                getHospitalInvoiceData(val);
              },
            }}
            dataSource={get(billedInvoices, 'data')}
            renderItem={(data) => (
              <div className="previous-billing-card">
                <div className="header">
                  <div className="download">
                    Invoice : {get(data, 'invoice_number')} <DownloadOutlined />
                  </div>
                  <div className="tag">Status : Paid</div>
                </div>

                <div className="info">
                  <Row>
                    <Col span={6}>
                      <div className="text-info">Invoice Number</div>
                      <div className="text-title">
                        {get(data, 'invoice_number')}
                      </div>
                    </Col>
                    <Col span={6}>
                      <div className="text-info">Issue Date</div>
                      <div className="text-title">
                        {get(data, 'month_formatted')}, {get(data, 'year')}
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="text-info">
                        {get(data, 'hospital.name')}
                      </div>
                      <div className="text-title">
                        {AddressHelper({ details: get(data, 'hospital') })}
                      </div>
                    </Col>
                  </Row>

                  <div className="divider-inner" />

                  <Row>
                    <Col span={6}>
                      <div className="text-info">Total Amount</div>
                      <div className="text-title">
                        ${get(data, 'units') * get(data, 'unit_amount')}
                      </div>
                    </Col>
                    <Col span={6}>
                      <div className="text-info">
                        Total {data.hospital.billing_method.name.split(' ')[1]}s
                      </div>
                      <div className="text-title">{get(data, 'units')}</div>
                    </Col>
                    <Col span={12}>
                      <div className="text-info checkicon">
                        <span className="dark">Total Amount Paid</span>{' '}
                        <CheckOutlined />
                      </div>
                      <div className="text-title paid">
                        ${get(data, 'amount')}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
          />
        </Col>
      </Row>

      {/* <div className="button-section mt--60">
      <ButtonPrimary title="Save" onClick={props.onSave} />
      <ButtonDefault title="Reset" />
    </div> */}
    </div>
  );
};

export default Billings;
