/**
 * Constants
 */
import * as APIS from '../../constants/apiEndpoints';
import { axiosInstance } from '../../utilities/configureAxios';

export const inviteAdmin = (values) => {
  return axiosInstance.post(APIS.INVITE_STAFF, values).then((result) => {
    return result;
  });
};

export const fetchAdmins = () => {
  return axiosInstance.get(APIS.FETCH_ADMINS).then((result) => {
    return result;
  });
};


export const deleteHospitalAdmin =(id) =>{
  let url = APIS.DELETE_HOSPITAL_ADMIN.replace('{uuid}',id);
  return axiosInstance.delete(url).then((result)=>{
    return result;
  })
}