import get from 'lodash/get';
import map from 'lodash/map';
import * as TYPES from '../../constants/actionTypes';
import { axiosInstance } from '../../utilities/configureAxios';

const initialState = {
  user: null,
  token: null,
  isLoggedIn: false,
  currentHospital: null,
  hospitals: null,
  roles: null,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.LOGIN:
      return {
        ...state,
        token: get(action, 'data.access_token'),
        user: get(action, 'data.user'),
        isLoggedIn: true,
        currentHospital: get(action, 'data.currentHospital'),
        hospitals: get(action, 'data.hospitals'),
        roles: map(get(action, 'data.user.roles'), (role) => role.value),
        id: get(action, 'data.user.id'),
      };

    case TYPES.LOGOUT:
      return {
        ...state,
        user: null,
        token: null,
        isLoggedIn: false,
        currentHospital: null,
        hospitals: null,
        roles: null,
      };

    case TYPES.SWITCH:
      return {
        ...state,
        token: get(action, 'data.access_token'),
        user: get(action, 'data.user'),
        currentHospital: get(action, 'data.currentHospital'),
        roles: map(get(action, 'data.user.roles'), (role) => role.value),
        hospitals: get(action, 'data.hospitals'),
        id: get(action, 'data.user.id'),
      };

    case TYPES.UPDATE:
      return {
        ...state,
        user: get(action, 'data'),
      };

    // return {
    //   ...state,
    //   token: get(action, 'data.access_token'),
    //   user: get(action, 'data'),
    //   isLoggedIn: true,
    //   role: get(action, 'data.role_id'),
    // };

    case 'persist/REHYDRATE':
      if (get(action.payload, 'auth.token', null)) {
        axiosInstance.defaults.headers.common.authorization = `Bearer ${get(
          action.payload,
          'auth.token',
          null,
        )}`;
      }

      return state;

    default:
      return state;
  }
}
