import { CloseCircleFilled, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Spin, List, Divider } from 'antd';
import React, { useEffect, useState } from 'react';

import get from 'lodash/get';
import './style.scss';
import {
  getDoctorFaqs,
  postDoctorFaqs,
} from '../../redux/actions/doctorAction';

import { useSelector } from 'react-redux';
import {
  getHospitalFaqs,
  postHospitalFaqs,
} from '../../redux/actions/hospitalAction';

const Faqs = ({ hospital }) => {
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const onSave = () => {
    setEdit(false);
  };
  const onEdit = () => {
    setEdit(true);
  };

  const [faqs, setFaqs] = useState([]);

  const doctor_uuid = useSelector((state) => get(state, 'auth.id'));
  const hospital_uuid = useSelector((state) =>
    get(state, 'auth.currentHospital.id')
  );

  const fetchFaqs = () => {
    if (hospital) {
      getHospitalFaqs(hospital_uuid)
        .then((result) => {
          setLoading(false);
          setFaqs(result);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      getDoctorFaqs(doctor_uuid)
        .then((result) => {
          setLoading(false);
          setFaqs(result);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const postFaqs = (payload) => {};

  useEffect(() => {
    fetchFaqs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Spin spinning={loading}>
      {edit ? (
        <EditFaqs
          onSave={onSave}
          setLoading={setLoading}
          faqs={faqs}
          postFaqs={postFaqs}
          fetchFaqs={fetchFaqs}
          hospital={hospital}
        />
      ) : (
        <ViewFaqs onEdit={onEdit} setLoading={setLoading} faqs={faqs} />
      )}
    </Spin>
  );
};

export default Faqs;

const EditFaqs = ({ setLoading, onSave, faqs, fetchFaqs, hospital }) => {
  const onFinish = (values) => {
    setLoading(true);
    if (hospital) {
      postHospitalFaqs(get(values, 'faqs'))
        .then((result) => {
          setLoading(false);
          onSave();
          fetchFaqs();
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      postDoctorFaqs(get(values, 'faqs'))
        .then((result) => {
          setLoading(false);
          onSave();
          fetchFaqs();
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  return (
    <Form
      name="dynamic_form_nest_item"
      className="faqs_form"
      layout="vertical"
      onFinish={onFinish}
      autoComplete="off"
      initialValues={{ faqs: faqs }}
    >
      <Form.List name="faqs">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <>
                <Row
                  key={key}
                  style={{ display: 'flex', marginBottom: 8 }}
                  align="baseline"
                  justify="space-between"
                >
                  <Col span={22}>
                    <Form.Item
                      {...restField}
                      name={[name, 'question']}
                      fieldKey={[fieldKey, 'question']}
                      label="Question"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter the question!',
                        },
                      ]}
                    >
                      <Input placeholder="Question" className="custom-input" />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, 'answer']}
                      fieldKey={[fieldKey, 'answer']}
                      label="Answer"
                      rules={[
                        { required: true, message: 'Please enter the answer' },
                      ]}
                    >
                      <Input placeholder="Answer" className="custom-input" />
                    </Form.Item>
                  </Col>
                  <Col>
                    <CloseCircleFilled
                      style={{ fontSize: '20px' }}
                      onClick={() => remove(name)}
                    />
                  </Col>
                </Row>
                <Divider />
              </>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add FAQ
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

const ViewFaqs = ({ onEdit, faqs }) => {
  return (
    <>
      {faqs && (
        <>
          <List
            itemLayout="horizontal"
            dataSource={faqs}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={item.question}
                  description={item.answer}
                />
              </List.Item>
            )}
          />
          <br />
          <Button type="primary" onClick={onEdit}>
            {/* Edit Details */}
            Add Faq
          </Button>
        </>
      )}
    </>
  );
};
