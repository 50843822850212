import { assertExpression } from '@babel/types';
import * as APIS from '../../constants/apiEndpoints';
import { axiosInstance } from '../../utilities/configureAxios';
import { replaceMultiple } from '../../utilities/helpers';

export const validateStaffLink = (uuid) => {
  return axiosInstance
    .get(APIS.VALID_STAFF_LINK.concat(uuid))
    .then((result) => {
      return result;
    });
};

export const registerStaff = (values) => {
  return axiosInstance.post(APIS.REGISTER_STAFF, values).then((result) => {
    return result;
  });
};

export const inviteStaff = (values) => {
  return axiosInstance.post(APIS.INVITE_STAFF, values).then((result) => {
    return result;
  });
};
export const getStaffInvitations = (credentials) => {
  return axiosInstance.post(APIS.GET_STAFF_INVITATIONS, credentials).then((result) => {
    return result;
  });
};
export const updateUser = (values) => {
  return axiosInstance.put(APIS.UPDATE_USERS, values).then((result) => {
    return result;
  });
};

export const fetchDashboardStats = (from_date, to_date) => {
  var url = replaceMultiple(APIS.STAFF_DASHBOARD, {
    '{from_date}': from_date,
    '{to_date}': to_date,
  });
  return axiosInstance.get(url).then((result) => {
    return result;
  });
};

export const forgotPassword = (email) => {
  return axiosInstance.post(APIS.FORGOT_PASS, email).then((result) => {
    return result;
  });
};

export const validateForgotPassword = (uuid) => {
  let url = APIS.VALIDATE_FORGOT_PASS.concat(uuid);
  return axiosInstance.get(url).then((result) => {
    return result;
  });
};

export const changePassword = (values) => {
  return axiosInstance.post(APIS.CHANGE_PASS, values).then((result) => {
    return result;
  });
};
