import get from "lodash/get";
import { Popover } from "antd";
import "./addressHelperStyles.scss";

const AddressHelper = ({ details }) => {
  const content = (
    <span>
      {get(details?.address_1, "selectedAddress")}, {get(details?.address_2, "selectedAddress_2")},{" "}
      {get(details, "suburb")}, {get(details, "state")},{" "}
      {get(details, "country")}, {get(details, "pin_code")}
    </span>
  );
  return (
    <div>
      <Popover content={content} title="Location">
        <span
          className="location"
        >
      {get(details?.address_1, "selectedAddress")}, {get(details?.address_2, "selectedAddress_2")},{" "}
          {get(details, "suburb")}, {get(details, "state")},{" "}
          {get(details, "country")}, {get(details, "pin_code")}
        </span>
      </Popover>
    </div>
  );
};

export default AddressHelper;
