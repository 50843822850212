import { CloseOutlined } from '@ant-design/icons';
import { Col, Row, Spin, Form, Input, Button, notification } from 'antd';
import { useState } from 'react';
import { SEND_INVITE_STATUS } from '../../../constants/messages';
import { validateMessages } from '../../../constants/validation';
import { inviteStaff } from '../../../redux/actions/staffAction';
import assign from 'lodash/assign';
import PendingInvitationScreen from '../../../screens/PendingInvitationsScreen/PendingInvitationScreen';

const AddAssistantForm = ({ toggleAddAssistant }) => {
  const onFinish = (values) => {
    setLoading(true);
    let inviteValue = { email: values.email, data: { name: values.name } };
    assign(inviteValue, { role: 5 });

    inviteStaff(inviteValue)
      .then(() => {
        setLoading(false);
        toggleAddAssistant();
        notification.success({
          message: (SEND_INVITE_STATUS),
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const [loading, setLoading] = useState(false);
  const [showPendingInvitations,setShowPendingInvitations] = useState(false);
  const pendingInvitation=()=>{
    setShowPendingInvitations(!showPendingInvitations);
  }

  return (
    <Spin spinning={loading}>
      <div className="add-drawer-content">
        <Row justify="space-between">
          <Col className="drawer-title">Invite Assistant</Col>
          <Col>
            <CloseOutlined onClick={toggleAddAssistant} />
          </Col>
        </Row>
        <div className="add-drawer-form">
          <Form
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            validateMessages={validateMessages}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  type: 'email',
                },
              ]}
            >
              <Input className="custom-input" />
            </Form.Item>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input className="custom-input" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="full-button">
                Send Invite
              </Button>
            </Form.Item>
          </Form>
        </div>
        <Button onClick={pendingInvitation} type="primary">
          Pending Invitations
        </Button>
        {
          showPendingInvitations &&(
            <PendingInvitationScreen toggleAddAssistant={toggleAddAssistant} pendingInvitation={pendingInvitation} staffInvitation={true}/>
          )
        }
      </div>
    </Spin>
  );
};
export default AddAssistantForm;
