import { Tag } from "antd";
import "./style.scss";

const CustomTag = ({ status }) => {
  let text = null;
  let color = null;
  switch (status) {
    case "Pending":
      text = "Pending";
      color = "blue";
      break;
    case "Done":
      text = "Done";
      color = "green";
      break;
    case "No Show":
      text = "No Show";
      color = "blue";
      break;
    case "Cancelled":
      text = "Cancelled";
      color = "red";
      break;
    case "In complete":
      text = "In Complete";
      color = "grey";
      break;
    case "Active":
      text = "Active";
      color = "green";
      break;
    case "In Active":
      text = "In Active";
      color = "red";
      break;
    case "CheckedIn":
      text = "CheckedIn";
      color = "orange";
      break;

    case 0:
      text = "Pending";
      color = "orange";
      break;
    case 1:
      text = "Approved";
      color = "green";
      break;
    case 2:
      text = "Rejected";
      color = "red";
      break;
    default:
      text = "No status";
      color = "grey";
  }
  return (
    <Tag color={color} className="status-tag">
      {text}
    </Tag>
  );
};
export default CustomTag;
