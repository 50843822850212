import { Layout, Spin } from 'antd';
import get from 'lodash/get';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import LayoutHeader from './components/layout/LayoutHeader/LayoutHeader';
import LayoutSider from './components/layout/LayoutSider/LayoutSider';
import AuthRouter from './router/AuthRouter';
import SuperAdminRouter from './router/SuperAdminRouter';
import { Route, Switch } from 'react-router-dom';
import HelperComponent from './HelperComponent';
import LineUpScreen from './screens/LineUpScreen/LineUpScreen';
import CurrentTvAdsScreen from './screens/CurrentTvAdsScreen/CurrentTvAdsScreen';

const { Content } = Layout;
const NON_LAYOUT_PATHS = ["lineup","tv-ads"] ;

const AppRouter = () => {
  const isLoggedIn = useSelector((state) => get(state, 'auth.isLoggedIn'));
  const [isLoading, setIsLoading] = useState(false);
  const [showSidePanel, setShowSidePanel] = useState(false);

  const openMenuButton = () => {
    setShowSidePanel(true);
  };
  const menuButtonClose = () => {
    setShowSidePanel(false);
  };

console.log("routes",NON_LAYOUT_PATHS?.some(path=>window.location.pathname?.includes(path)));
  return (
    <Spin spinning={isLoading}>
      <div style={{}}>
        <Router>
          <Route path="/lineup/:id" component={LineUpScreen} />
          <Route path="/tv-ads/:currentTvId" component={CurrentTvAdsScreen} />


         

          {NON_LAYOUT_PATHS?.some(path=>window.location.pathname?.includes(path)) ? null :
          <>
          <HelperComponent setIsLoading={setIsLoading} />
          <Layout>
            {isLoggedIn && <LayoutSider setIsLoading={setIsLoading} showSidePanel={showSidePanel}  menuButtonClose={menuButtonClose}/>}

            <Layout>
              {isLoggedIn && <LayoutHeader setIsLoading={setIsLoading} openMenuButton={openMenuButton}/>}
              <Content className="main-content">
                <AuthRouter />
                <SuperAdminRouter />
              </Content>
            </Layout>
          </Layout>
          </>
}
</Router>


      
      
      </div>
    </Spin>
  );
};

export default AppRouter;
