import { UserOutlined } from '@ant-design/icons';
import { Col, Row, Space } from 'antd';
import get from 'lodash/get';
import Avatar from 'antd/lib/avatar/avatar';

// import remove_red from '../../assets/icons/remove_red.svg';

export const hospitalAgentsColumn = ({}) => [
  {
    title: 'Agent Name',
    dataIndex: 'name',
    key: 'name',
    render: (name, record) => (
      <Row>
        <Space>
          <Col>
            {get(record?.user, 'image') ? (
              <Avatar size={48} src={get(record?.user, 'image')} />
            ) : (
              <Avatar size={48} icon={<UserOutlined />} />
            )}
          </Col>
          <Col>
            <div className="medium-bold">{record?.user?.name}</div>
          </Col>
        </Space>
      </Row>
    ),
  },

  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    render: (email,record) =>{
      return(
        <>
      {record?.user?.email},
        </>
      )
    }
  },
  {
    title: 'Contact Number',
    dataIndex: 'contact_number',
    key: 'contact_number',
    render: (contact_number, record) =>{
      return(
        <>
      {`+${get(record?.user, 'contact_code')} ${record?.user?.contact_number}`},
        </>
      )
    }
  },
  {
    title: 'Hospital Name',
    dataIndex: 'hospital_name',
    key: 'hospital_name',
    render: (hospital_name, record) =>{
      return(
        <>
      {record?.hospital?.name}
      </>
      )
    }
  },
];
