/**
 * Constants
 */
import * as APIS from '../../constants/apiEndpoints';
import { isEmpty } from '@antv/util';
import { axiosInstance } from '../../utilities/configureAxios';
import { replaceMultiple, getUrl } from '../../utilities/helpers';

// export const getHospitals = (credentials) => {
//   return axiosInstance.post(APIS.DEPARTMENTS, credentials).then((result) => {
//     return result;
//   });
// };

export const getHospitals = (from_date, to_date, status, page, query) => {
  console.log('Status is', status);

  var url = getUrl(APIS.HOSPITAL_REQUESTS, {
    page,
  });
  if (from_date) {
    url = url.concat('&from_date=' + from_date + '&to_date=' + to_date);
  }
  if (status !== null) {
    url = url.concat('&status=' + status);
  }
  if (query) {
    url = url.concat('&query=' + query);
  }
  return axiosInstance.get(url).then((result) => {
    return result;
  });
};

export const getDetails = (hospital_uuid) => {
  let url = APIS.HOSPITAL_DETAILS.replace('{hospital_uuid}', hospital_uuid);
  return axiosInstance.get(url).then((result) => {
    return result;
  });
};

export const updateHospitalDetails = (hospital_uuid, payload) => {
  let url = APIS.HOSPITAL_DETAILS.replace('{hospital_uuid}', hospital_uuid);
  return axiosInstance.put(url, payload).then((result) => {
    return result;
  });
};

export const getHospitalFaqs = (hospital_uuid) => {
  let url = APIS.GET_HOSPITAL_FAQS.replace('{hospital_uuid}', hospital_uuid);
  return axiosInstance.get(url).then((result) => {
    return result;
  });
};

export const postHospitalFaqs = (payload) => {
  return axiosInstance.post(APIS.POST_HOSPITAL_FAQS, payload).then((result) => {
    return result;
  });
};
export const getHospitalAds = (hospital_uuid) => {
  let url = APIS.GET_HOSPITAL_ADS.replace('{hospital_uuid}', hospital_uuid);
  return axiosInstance.get(url).then((result) => {
    return result;
  });
};
export const fetchHospitalAdById = (add_uuid) => {
  let url = APIS.GET_HOSPITAL_AD.replace('{uuid}', add_uuid);
  return axiosInstance.get(url).then((result) => {
    return result;
  });
};

export const fetchHospitalNotificationById = (notification_uuid) => {
  let url = APIS.GET_HOSPITAL_NOTIFICATION.replace('{uuid}', notification_uuid);
  return axiosInstance.get(url).then((result) => {
    return result;
  });
};

export const deleteHospitalAds = (uuid) => {
  let url = APIS.DELETE_HOSPITAL_ADS.replace('{uuid}',uuid);
  return axiosInstance.delete(url).then((result) => {
    return result;
  });
};
export const postHospitalAds = (payload) => {
  return axiosInstance.post(APIS.POST_HOSPITAL_ADS, payload).then((result) => {
    return result;
  });
};
export const updateHospitalAds = (uuid,data) => {
  let url = APIS.UPDATE_HOSPITAL_ADS.replace('{uuid}',uuid);
  return axiosInstance.put(url,data).then((result) => {
    return result;
  });
};



export const deleteHospitalTvAds = (uuid) => {
  let url = APIS.DELETE_HOSPITAL_TVADS.replace('{uuid}',uuid);
  return axiosInstance.delete(url).then((result) => {
    return result;
  });
};
export const postHospitalTvAds = (payload) => {
  return axiosInstance.post(APIS.POST_HOSPITAL_TVADS, payload).then((result) => {
    return result;
  });
};
export const updateHospitalTvAds = (uuid,data) => {
  let url = APIS.UPDATE_HOSPITAL_TVADS.replace('{uuid}',uuid);
  return axiosInstance.put(url,data).then((result) => {
    return result;
  });
};

export const getHospitalTvAds = (hospital_uuid) => {
  let url = APIS.GET_HOSPITAL_TVADS.replace('{hospital_uuid}', hospital_uuid);
  return axiosInstance.get(url).then((result) => {
    return result;
  });
};

export const fetchHospitalTvAdById = (add_uuid) => {
  let url = APIS.GET_HOSPITAL_TVAD.replace('{uuid}', add_uuid);
  return axiosInstance.get(url).then((result) => {
    return result;
  });
};



export const getMediaTvs = (
  hospital_uuid,
  page,
  name,
  limit = 10
) => {
  let url1 = APIS.GET_MEDIA_TVS.replace('{hospital_uuid}', hospital_uuid);
  var url = getUrl(url1, {
    page,
    limit,
  });
  if (!isEmpty(name)) {
    url = url.concat('&name=' + name);
  }
  return axiosInstance.get(url).then((result) => {
    return result;
  });
};

export const getMediaAds = (
  tv_id,
  from_date,
  to_date,
  status,
  page,
  name,
  limit = 10
) => {
  let url1 = APIS.GET_MEDIA_ADS.replace('{tv_id}', tv_id);
  var url = getUrl(url1, {
    from_date,
    to_date,
    page,
    limit,
  });
  if (status !== null) {
    url = url.concat('&status=' + status);
  }
  if (!isEmpty(name)) {
    url = url.concat('&name=' + name);
  }
  return axiosInstance.get(url).then((result) => {
    return result;
  });
};


export const getHospitalPatients = (
  hospital_uuid,
  from_date,
  to_date,
  status,
  page,
  name,
  limit = 10
) => {
  let url1 = APIS.GET_HOSPITAL_PATIENTS.replace('{hospital_uuid}', hospital_uuid);
  var url = getUrl(url1);
  return axiosInstance.get(url).then((result) => {
    return result;
  });
};

export const updateHospitalPatient = (hospial_uuid,member_id,data) => {
  let url = APIS.UPDATE_HOSPITAL_PATIENT_STATUS.replace('{hospital_uuid}',hospial_uuid);
  let url2 = url.replace('{user_id}',member_id)
  return axiosInstance.put(url2,data).then((result) => {
    return result;
  });
};

export const fetchHospitalPatientByUserId = (hospital_uuid,user_id)=>{
  let url = APIS.FETCH_HOSPITAL_PATIENT_BY_USERID.replace('{user_id}', user_id);
  let url2 = url.replace('{hospital_uuid}',hospital_uuid);
  return axiosInstance.get(url2).then((result) => {
    return result;
  });
}



export const getAllTvAds = (
  tv_id,
  from_date,
  to_date,
  status,
  page,
  name,
  limit = 10
) => {
  let url1 = APIS.GET_TV_ADS.replace('{tv_id}', tv_id);
  var url = getUrl(url1, {
    from_date,
    to_date,
    page,
    limit,
  });
  if (status !== null) {
    url = url.concat('&status=' + status);
  }
  if (!isEmpty(name)) {
    url = url.concat('&name=' + name);
  }
  return axiosInstance.get(url).then((result) => {
    return result;
  });
};

export const postHospitalTvs = (payload) => {
  return axiosInstance.post(APIS.POST_HOSPITAL_TVS, payload).then((result) => {
    return result;
  });
};


export const deleteHospitalTv = (uuid) => {
  let url = APIS.DELETE_HOSPITAL_TV.replace('{uuid}',uuid);
  return axiosInstance.delete(url).then((result) => {
    return result;
  });
};


export const fetchHospitalTvById = (add_uuid) => {
  let url = APIS.GET_HOSPITAL_TV.replace('{uuid}', add_uuid);
  return axiosInstance.get(url).then((result) => {
    return result;
  });
};


export const updateHospitalTv = (uuid,data) => {
  let url = APIS.UPDATE_HOSPITAL_TV.replace('{uuid}',uuid);
  return axiosInstance.put(url,data).then((result) => {
    return result;
  });
};











export const postHospitalMediaAd = (payload) => {
  return axiosInstance.post(APIS.POST_MEDIA_TV, payload).then((result) => {
    return result;
  });
};


export const deleteHospitalMediaAd = (uuid) => {
  let url = APIS.DELETE_MEDIA_TV.replace('{uuid}',uuid);
  return axiosInstance.delete(url).then((result) => {
    return result;
  });
};


export const fetchHospitalMediaAdById = (add_uuid) => {
  let url = APIS.GET_MEDIA_TV.replace('{uuid}', add_uuid);
  return axiosInstance.get(url).then((result) => {
    return result;
  });
};


export const updateHospitalMediaAd = (uuid,data) => {
  let url = APIS.UPDATE_MEDIA_TV.replace('{uuid}',uuid);
  return axiosInstance.put(url,data).then((result) => {
    return result;
  });
};
















export const getHospitalNotifications = (hospital_uuid) => {
  let url = APIS.GET_HOSPITAL_NOTIFICATIONS.replace('{hospital_uuid}', hospital_uuid);
  return axiosInstance.get(url).then((result) => {
    return result;
  });
};
export const deleteHospitalNotifications = (uuid) => {
  let url = APIS.DELETE_HOSPITAL_NOTIFICATIONS.replace('{uuid}',uuid);
  return axiosInstance.delete(url).then((result) => {
    return result;
  });
};
export const postHospitalNotifications = (payload) => {
  return axiosInstance.post(APIS.POST_HOSPITAL_NOTIFICATIONS, payload).then((result) => {
    return result;
  });
};
export const updateHospitalNotifications = (uuid,data) => {
  let url = APIS.UPDATE_HOSPITAL_NOTIFICATIONS.replace('{uuid}',uuid);
  return axiosInstance.put(url,data).then((result) => {
    return result;
  });
};

export const getHospitalQuestions = (hospital_uuid) => {
  let url = APIS.GET_HOSPITAL_QUESTIONS.replace('{hospital_uuid}', hospital_uuid);
  return axiosInstance.get(url).then((result) => {
    return result;
  });
};
export const deleteHospitalQuestion = (uuid) => {
  let url = APIS.DELETE_HOSPITAL_QUESTION.replace('{uuid}',uuid);
  return axiosInstance.delete(url).then((result) => {
    return result;
  });
};
export const postHospitalQuestion = (payload) => {
  return axiosInstance.post(APIS.POST_HOSPITAL_QUESTIONS, payload).then((result) => {
    return result;
  });
};
export const updateHospitalQuestion = (uuid,data) => {
  let url = APIS.UPDATE_HOSPITAL_QUESTION.replace('{uuid}',uuid);
  return axiosInstance.put(url,data).then((result) => {
    return result;
  });
};

export const getHospitalInvoice = (hospital_uuid, page) => {
  let url = replaceMultiple(APIS.HOSPITAL_INVOICE, {
    '{hospital_uuid}': hospital_uuid,
    '{page}': page,
  });
  return axiosInstance.get(url).then((result) => {
    return result;
  });
};
