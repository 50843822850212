import { Col, Row, Space } from 'antd';
import get from 'lodash/get';
import Avatar from 'antd/lib/avatar/avatar';
import {ExclamationCircleOutlined } from '@ant-design/icons';


export const managersColumn = ({ toggleManagerDetails,getDeptManagerById }) => [
  {
    title: 'Manager name',
    key: 'name',
    render: (record) => (
      <Row>
        <Space>
          <Col>
            <Avatar size={48} src={get(record, 'image')} />
          </Col>
          <Col>
            <div className="medium-bold">{get(record, 'name')}</div>
          </Col>
        </Space>
      </Row>
    ),
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Contact Number',
    dataIndex: 'contact_number',
    key: 'contact_number',
  },

  {
    title: 'Actions',
    dataIndex: '',
    key: 'actions',
    align: 'center',
    render: (record) => (
      <div onClick={() => {
        toggleManagerDetails();
        getDeptManagerById(record);
      }} style={{ cursor: 'pointer' }}>
        <ExclamationCircleOutlined />
        <p className="small">View Details</p>
      </div>
    ),
  },
];
