import { UserOutlined } from '@ant-design/icons';
import { Col, Row, Space } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import map from 'lodash/map';
import get from 'lodash/get';

const DoctorProfile = ({
  title,
  entry_name,
  specializations,
  experience,
  image,
}) => {
  return (
    <Row>
      <Space>
        <Col>
          <Avatar
            size={48}
            icon={image ? <img src={image} alt="" /> : <UserOutlined />}
          />
        </Col>
        <Col>
          <div className="medium-bold">
            {title} {entry_name}
          </div>
          <div>
            {map(specializations, (specialization, i) => (
              <div
                className="x-small-purple-highlight"
                style={{ letterSpacing: '0.75px' }}
                key={i}
              >
                {' '}
                {get(specialization, 'name').toUpperCase()}{' '}
              </div>
            ))}
          </div>
          <div className="x-small-grey">{experience} years Exp.</div>
        </Col>
      </Space>
    </Row>
  );
};

export default DoctorProfile;
