import {
  CloseCircleFilled,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Spin,
  List,
  Divider,
  Checkbox,
  message,
  DatePicker,
} from "antd";
import { Modal } from 'antd';
import React, { useEffect, useState } from "react";
import get from "lodash/get";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  getHospitalNotifications,
  postHospitalNotifications,
  deleteHospitalNotifications,
  updateHospitalNotifications,
  fetchHospitalNotificationById,
} from "../../redux/actions/hospitalAction";
import { DELETED_NOTIFICATIONS } from "../../constants/messages";
import SingleImageUpload from "../../components/mediaUpload/SingleImageUpload";
import { useForm } from "antd/lib/form/Form";

const NotificationsScreen = ({ hospital }) => {
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editNotification, setEditNotification] = useState(null);
  const [imageUser, setUserImage] = useState();
  const [form] = useForm();
  const [notifications, setNotifications] = useState([]);

  const hospital_uuid = useSelector((state) =>
    get(state, "auth.currentHospital.id")
  );
  const createdBy = useSelector((state) => state?.auth?.id);

  const fetchNotifications = () => {
    setLoading(true);
    getHospitalNotifications(hospital_uuid)
      .then((result) => {
        setNotifications(result);
      })
      .catch((error) => {
        message.error("Failed to load notifications");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onEdit = async (itemUuid) => {
    setLoading(true);
    setEdit(true);

    if (!itemUuid) {
      // New notification creation
      setEditNotification(null);
      form.resetFields();
      setUserImage(null);
      setLoading(false);
      return;
    }

    try {
      const res = await fetchHospitalNotificationById(itemUuid);
      const result = {
        ...res,
        start_date: moment(res.start_date, "DD-MM-YYYY"),
        end_date: moment(res.end_date, "DD-MM-YYYY"),
      };
      form.setFieldsValue(result);
      setEditNotification(result);
      setUserImage(res.image);
    } catch (error) {
      message.error("Failed to fetch notification details");
    } finally {
      setLoading(false);
    }
  };

  const onClickBack = () => {
    setEdit(false);
    form.resetFields();
    setEditNotification(null);
    setUserImage(null);
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <Spin spinning={loading}>
      {edit ? (
        <EditNotifications
          onSave={() => setEdit(false)}
          setLoading={setLoading}
          notifications={notifications}
          editNotification={editNotification}
          fetchNotifications={fetchNotifications}
          hospital={hospital}
          createdBy={createdBy}
          onClickBack={onClickBack}
          imageUser={imageUser}
          setUserImage={setUserImage}
          form={form}
        />
      ) : (
        <ViewNotifications
          onEdit={onEdit}
          notifications={notifications}
          setLoading={setLoading}
          fetchNotifications={fetchNotifications}
        />
      )}
    </Spin>
  );
};

export default NotificationsScreen;

const EditNotifications = ({
  setLoading,
  onSave,
  notifications,
  fetchNotifications,
  hospital,
  createdBy,
  editNotification,
  onClickBack,
  imageUser,
  setUserImage,
  form,
}) => {
  const onFinish = async (values) => {
    const data = {
      is_sponsor: values.is_sponsor || false,
      start_date: values?.start_date?.format("DD-MM-YYYY"),
      end_date: values?.end_date?.format("DD-MM-YYYY"),
      image: imageUser?.key || values?.image,
      text: values?.text,
      createdBy: createdBy,
    };

    const start = new Date(values?.start_date?.format("DD-MM-YYYY"));
    const end = new Date(values?.end_date?.format("DD-MM-YYYY"));

    if (!start) {
      message.error("Please select Start Date");
      return;
    }
    if (start > end) {
      message.error("End Date cannot be earlier than Start Date");
      return;
    }

    setLoading(true);
    try {
      if (editNotification?.id) {
        await updateHospitalNotifications(editNotification?.uuid, data);
      } else {
        await postHospitalNotifications(data);
      }
      message.success("Notification saved successfully");
      fetchNotifications();
      onSave();
    } catch (error) {
      message.error("Failed to save notification");
    } finally {
      setLoading(false);
    }
  };

  const onUploadLogoDone = (imageResponse) => {
    setUserImage(imageResponse);
  };

  return (
    <Form
      form={form}
      name="notification_form"
      layout="vertical"
      onFinish={onFinish}
      autoComplete="off"
    >
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="text"
            label="Notification Text"
            rules={[
              { required: true, message: "Please enter notification text" },
            ]}
          >
            <Input placeholder="Text" />
          </Form.Item>
          <Form.Item name="image" label="Image">
            <SingleImageUpload
              onUploadDone={onUploadLogoDone}
              imageUrl={get(imageUser, "url")}
            />
          </Form.Item>
          <Form.Item name="is_sponsor" valuePropName="checked">
            <Checkbox>Sponsor</Checkbox>
          </Form.Item>
          <Form.Item name="start_date" label="Start Date">
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>
          <Form.Item name="end_date" label="End Date">
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
          {editNotification?.id ? "Update" : "Create"}
        </Button>
        <Button onClick={onClickBack}>Back</Button>
      </Form.Item>
    </Form>
  );
};

const ViewNotifications = ({
  onEdit,
  notifications,
  setLoading,
  fetchNotifications,
}) => {

const onClickDelete = (itemUuid) => {
  Modal.confirm({
    title: 'Are you sure you want to delete this notification?',
    content: 'This action cannot be undone.',
    okText: 'Yes, delete it',
    okType: 'danger',
    cancelText: 'Cancel',
    onOk: async () => {
      setLoading(true);
      try {
        await deleteHospitalNotifications(itemUuid);
        message.success(DELETED_NOTIFICATIONS);
        fetchNotifications();
      } catch (error) {
        message.error("Failed to delete notification");
      } finally {
        setLoading(false);
      }
    },
  });
};


  return (
    <>
      <h3>Hospital Notifications or Offers</h3>
      <List
        itemLayout="horizontal"
        dataSource={notifications}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                type="primary"
                onClick={() => onEdit(item?.uuid)}
                style={{ marginRight: 8 }}
              >
                Edit
              </Button>,
              <Button
                type="danger"
                onClick={() => onClickDelete(item?.uuid)}
                icon={<DeleteOutlined />}
              />,
            ]}
          >
            <List.Item.Meta description={item?.text} />
          </List.Item>
        )}
      />
      <Button type="primary" onClick={() => onEdit()}>
        Create Notification
      </Button>
    </>
  );
};
