import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Row, Table, Spin,notification } from 'antd';
import { useState, useEffect } from 'react';
import get from 'lodash/get';
// import filter from 'lodash/filter';
// import { hospitalAdmins } from '../../constants/dummydata';
import { hospitaladminsColumn } from '../../constants/table/hospitaladminsColumn';
import requireAuth from '../../hocs/requireAuth';
import AddAdminForm from '../../components/drawerContent/addAdminForm';
import { fetchAdmins,deleteHospitalAdmin } from '../../redux/actions/adminAction';
import { DELETED_HOSPITAL_ADMIN_SUCCESS } from "../../constants/messages";


const HospitalAdminsScreen = () => {
  const [loading, setLoading] = useState(false);
  const [adminsList, setAdminList] = useState({
    admins: [],
    selectedAdmins: [],
  });
  const [drawer, setDrawer] = useState({
    visible: false,
    edit: false,
    data: null,
  });

  const toggleAddAdmin = (data = null) => {
    setDrawer({
      visible: !get(drawer, 'visible'),
      edit: !get(drawer, 'visible'),
      data,
    });
  };

  const getAdmins = () => {
    setLoading(true);
    fetchAdmins()
      .then((result) => {
        setAdminList({
          admins: result,
          selectedAdmins: result,
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAdmins();
  }, []);

  // const onSearchAdmins = (searchText) => {
  //   let admins = [];
  //   if (searchText) {
  //     admins = filter(get(adminsList, 'admins'), (ad) => {
  //       return (
  //         get(ad, 'name').toLowerCase().indexOf(searchText.toLowerCase()) >=
  //           0 ||
  //         get(ad, 'email').toLowerCase().indexOf(searchText.toLowerCase()) >=
  //           0 ||
  //         get(ad, 'contact_number')
  //           .toLowerCase()
  //           .indexOf(searchText.toLowerCase()) >= 0
  //       );
  //     });
  //   } else {
  //     admins = get(adminsList, 'admins');
  //   }

  //   setAdminList({
  //     admins: get(adminsList, 'admins'),
  //     selectedAdmins: admins,
  //   });
  // };
  const removeAdmin=(record)=>{
    deleteHospitalAdmin(record?.id)
      .then((result) => {
        console.log(result);
        notification.success({
          message: DELETED_HOSPITAL_ADMIN_SUCCESS,
        });
        fetchAdmins();
      })
      .catch((error) => {
        console.log(error);
      });

  }

  return (
    <Spin spinning={loading}>
      <div className="hospitaladmins-screen">
        <Row justify="space-between" style={{ alignItems: 'baseline' }}>
          <Col>
            <div className="screen-title">Hospital Admin</div>
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={() => toggleAddAdmin()}
              icon={<PlusOutlined />}
            >
              Add Admins
            </Button>
          </Col>
        </Row>

        <Table
          columns={hospitaladminsColumn({removeAdmin})}
          dataSource={get(adminsList, 'selectedAdmins')}
          pagination={false}
        />
        <Drawer
          className="main-drawer"
          destroyOnClose
          width="30%"
          closable={false}
          onClose={() => toggleAddAdmin(null)}
          visible={get(drawer, 'visible')}
        >
          <AddAdminForm toggleDrawer={toggleAddAdmin} />
        </Drawer>
      </div>
    </Spin>
  );
};

export default requireAuth(HospitalAdminsScreen, [2]);
