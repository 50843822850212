import { useState } from 'react';
import edit_white from '../../../assets/icons/edit_white.svg';
import { Row, Col, Input, Form, Button, Image, notification } from 'antd';
// import ButtonPrimary from '../../buttons/buttonPrimary';
// import ModalPrimary from '../../modals/modalPrimary';
// import AddDoctorCard from '../addDoctorCard';
import get from 'lodash/get';
import assign from 'lodash/assign';
// import Avatar from '../../uploadLogo';

// Styles
import './styles.scss';
import { validateMessages } from '../../../constants/validation';
import SingleImageUpload from '../../mediaUpload/SingleImageUpload';
import ColorPicker from '../../colorPicker';
import { updateHospitalDetails } from '../../../redux/actions/hospitalAction';
import { DETAILS_UPDATED } from '../../../constants/messages';

// const { Option } = Select;
// const { TextArea } = Input;

const LandingEdit = ({ details, hospital_uuid, setLoading, fetchDetails }) => {
  const [edit, setEdit] = useState(false);
  const onSave = () => {
    setEdit(false);
  };
  const [imageResponse,setImageResponse] = useState();
  const [bannerResponse,setBannerResponse] = useState();

  const onEdit = () => {
    setEdit(true);
    setImageResponse(details?.logo_url)
    setBannerResponse(details?.banner_url)
  };
  // const doctorData = [
  //   {
  //     imgUrl:
  //       'https://images.unsplash.com/photo-1612349316228-5942a9b489c2?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzR8fGRvY3RvcnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
  //     title: 'Dr. Jacob Jones',
  //     specialization: 'CARDIOLOGIST',
  //     experience: '20 Years Exp.',
  //   },
  //   {
  //     imgUrl:
  //       'https://images.unsplash.com/photo-1594824476967-48c8b964273f?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mzd8fGRvY3RvcnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
  //     title: 'Dr. Jacob Jones',
  //     specialization: 'CARDIOLOGIST',
  //     experience: '20 Years Exp.',
  //   },
  //   {
  //     imgUrl:
  //       'https://images.unsplash.com/photo-1622253692010-333f2da6031d?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NTB8fGRvY3RvcnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
  //     title: 'Dr. Jacob Jones',
  //     specialization: 'CARDIOLOGIST',
  //     experience: '20 Years Exp.',
  //   },
  // ];

  return (
    <>
      {edit ? (
        <EditLanding
          details={details}
          onSave={onSave}
          hospital_uuid={hospital_uuid}
          setLoading={setLoading}
          imageResponse={imageResponse}
          setImageResponse={setImageResponse}
          bannerResponse={bannerResponse}
          setBannerResponse={setBannerResponse}
        />
      ) : (
        <ViewLanding details={details} onEdit={onEdit} 
        imageResponse={imageResponse}
          setImageResponse={setImageResponse}
          bannerResponse={bannerResponse}
          setBannerResponse={setBannerResponse}
        />
      )}
    </>
  );
};
export default LandingEdit;

// const [isModalVisible, setIsModalVisible] = useState(false);
// const showModal = () => {
//   setIsModalVisible(true);
// };
// const handleOk = () => {
//   setIsModalVisible(false);
// };
// const handleCancel = () => {
//   setIsModalVisible(false);
// };

// return (
//   <div className="landing-edit-component">
//     <div className="title">Branding</div>
//     <div className="form">
//       <Row gutter={50}>
//         <Col span={6}>
//           <div className="input-multiple input-sec">
//             <div className="label">Upload logo</div>
//             <div className="avatar-upload">
//               <Avatar />
//             </div>
//           </div>
//         </Col>
//         <Col span={6}>
//           <div className="brand-colors input-sec">
//             <div className="label">Choose brand colors</div>

//             <div className="add-color">
//               <div className="item">
//                 <div className="box">+</div>
//                 {props.colorActive1 && (
//                   <div
//                     className="primary-color box"
//                     style={{ backgroundColor: '#79CBBD' }}
//                   />
//                 )}
//                 <div className="text">Primary color</div>
//               </div>
//               <div className="item">
//                 <div className="box">+</div>
//                 {props.colorActive2 && (
//                   <div
//                     className="secondary-color box"
//                     style={{ backgroundColor: '#F58639' }}
//                   />
//                 )}
//                 <div className="text">Secondary color</div>
//               </div>
//             </div>
//           </div>
//         </Col>

//         <Col span={6}>
//           <div className="input-multiple input-sec">
//             <div className="label">Your custom Url</div>
//             <Row>
//               <Col span={19} push={5}>
//                 <Input
//                   placeholder="myhospital.in"
//                   value="myhospital.in"
//                   className="hospital-url"
//                 />
//               </Col>
//               <Col span={5} pull={19}>
//                 <Input
//                   placeholder="https://"
//                   value="https://"
//                   className="static-url"
//                 />
//               </Col>
//             </Row>
//           </div>
//         </Col>
//       </Row>
//       <div className="doctor-section">
//         <Row gutter={50}>
//           <Col span={24}>
//             <div className="add-doctor">
//               <div className="title">
//                 Top doctors to be shown on hospital homepage
//               </div>
//               <button onClick={showModal} className="btn-add-doctor">
//                 + Add Doctor
//               </button>
//             </div>
//           </Col>
//         </Row>
//         <Row gutter={70} className="mt--20">
//           {doctorData.map((data) => (
//             <Col span={6}>
//               <div className="doctor-card">
//                 <div className="doctor-image">
//                   <img src={data.imgUrl} alt="" />
//                 </div>
//                 <div className="doctor-data">
//                   <h4 className="title">{data.title}</h4>
//                   <div className="specialization">{data.specialization}</div>
//                   <div className="experience">{data.experience}</div>
//                 </div>
//               </div>
//             </Col>
//           ))}
//         </Row>
//       </div>{' '}
//       */}
//       <Row gutter={50}>
//         <Col span={10}>
//           <div className="textArea-custom">
//             <div className="title">About Hospital</div>
//             <TextArea
//               rows={4}
//               value="Amet minim mollit non deserunt ullamco aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco aliqua dolor do amet sint. "
//             />
//           </div>
//         </Col>
//         <Col span={8}>
//           <div className="textArea-custom">
//             <div className="title">Hospital Address</div>
//             <TextArea
//               rows={4}
//               value="Amet minim mollit non deserunt ullamco aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco aliqua dolor do amet sint. "
//             />
//           </div>
//         </Col>
//       </Row>
//       <div className="input-multiple">
//         <div className="title">Hospital Features</div>
//         <Row gutter={50}>
//           <Col span={6}>
//             <div className="input-number-custom input-sec">
//               <div className="label">Patients treated</div>
//               <InputNumber
//                 placeholder="Enter Number"
//                 min={1}
//                 max={4}
//                 defaultValue={1}
//                 onChange={onChange}
//               />
//             </div>
//           </Col>
//           <Col span={6}>
//             <div className="input-number-custom input-sec">
//               <div className="label">Specialities</div>
//               <InputNumber
//                 placeholder="Enter Number"
//                 min={1}
//                 max={4}
//                 defaultValue={1}
//                 onChange={onChange}
//               />
//             </div>
//           </Col>

//           <Col span={6}>
//             <div className="input-number-custom input-sec">
//               <div className="label">Total Doctors</div>
//               <InputNumber
//                 placeholder="Enter Number"
//                 min={1}
//                 max={4}
//                 defaultValue={1}
//                 onChange={onChange}
//               />
//             </div>
//           </Col>
//           <Col span={6}>
//             <div className="input-number-custom input-sec">
//               <div className="label">Locations</div>
//               <InputNumber
//                 placeholder="Enter Number"
//                 min={1}
//                 max={4}
//                 defaultValue={1}
//                 onChange={onChange}
//               />
//             </div>
//           </Col>
//         </Row>
//       </div>
//       <Row>
//         <Col span={24}>
//           <div className="title">Hospital Speciality</div>
//         </Col>
//         <Col span={6}>
//           <div className="select-custom input-sec">
//             <div className="label">Specialisation</div>
//             <Select
//               mode="tags"
//               style={{ width: '100%' }}
//               placeholder="Multiple Selection"
//               getPopupContainer={(trigger) => trigger.parentNode} // to avoid dropdown scrolling
//             >
//               {children}
//             </Select>
//           </div>
//         </Col>
//       </Row>
//       <div className="hospital-image-upload">
//         <Row>
//           <Col span={24}>
//             <div className="title">Upload Hospital Image</div>
//           </Col>
//         </Row>
//         <Upload
//           action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
//           listType="picture"
//           defaultFileList={[...fileList]}
//           className="upload-list-inline"
//         >
//           <ButtonPrimary title="Upload" onClick={props.onSave} />
//         </Upload>
//       </div>
//       <div className="button-section mt--60">
//         <ButtonPrimary title="Save" onClick={props.onSave} />
//         <ButtonDefault title="Reset" />
//       </div>{' '}
//       */}
//     </div>

//     <ModalPrimary
//       width={750}
//       visible={isModalVisible}
//       onOk={handleOk}
//       onCancel={handleCancel}
//       okText="Done"
//     >
//       <AddDoctorCard />
//     </ModalPrimary>
//   </div>
// );
const ViewLanding = ({ onEdit, details }) => {
  return (
    <>
     <div className="title">Branding</div>
<Row gutter={[40, 20]}>
  <Col span={8}>
    <div className="details-label">Hospital Logo</div>
    <Image src={get(details?.logo_url, 'url')} />
  </Col>
  <Col span={8}>
    <div className="details-label">Brand colors</div>
    <Row>
      <Col span={5} align="middle">
        <div
          className="color-div"
          style={{
            backgroundColor: get(details, 'brand_color.primary'),
          }}
        />
      </Col>
      <Col span={8} align="middle">
        <div
          className="color-div"
          style={{
            backgroundColor: get(details, 'brand_color.secondary'),
          }}
        />
      </Col>
    </Row>
    <Row>
      <Col span={6} className="small">
        Primary Color
      </Col>
      <Col span={6} className="small" align="middle">
        Secondary Color
      </Col>
    </Row>
  </Col>
  <Col span={8}>
    <div className="details-label">Custom Url</div>
    <Input.Group compact>
      <Input style={{ width: '20%' }} value="https://" />
      <Input
        style={{ width: '80%' }}
        value={`smart-meet-ui.vercel.app/hospital/${get(details, 'slug')}`}
        // ${process.env.REACT_APP_FRONTEND_URL}
      />
    </Input.Group>
  </Col>
  <Col span={24}>
    <div className="title">Hospital Image</div>
  </Col>
  <Col span={24}>
    <Image src={get(details?.banner_url, 'url')} width={500} />
  </Col>
  <Col span={24}>
    <Button
      type="primary"
      onClick={onEdit}
      icon={
        <img
          src={edit_white}
          alt=""
          height="18px"
          style={{ marginRight: '4px' }}
        />
      }
    >
      Edit Details
    </Button>
  </Col>
</Row>
    </>
  );
};

const EditLanding = ({
  setLoading,
  loading,
  details,
  onSave,
  hospital_uuid,
  fetchDetails,
  imageResponse,
  setImageResponse,
  bannerResponse,
  setBannerResponse
}) => {
  const [primaryColor, setPrimaryColor] = useState(
    get(details, 'brand_color.primary'),
  );
  const [secondaryColor, setSecondaryColor] = useState(
    get(details, 'brand_color.secondary'),
  );
  // const [imageLogo, setImageLogo] = useState(get(details,'logo_url')
  //   {
  //   key: get(details, 'logo'),
  //   url: get(details, 'logo_url'),
  // }
  // );
  // const [imageBanner, setImageBanner] = useState(get(details,'banner_url')
  //   {
  //   key: get(details, 'banner'),
  //   url: get(details, 'banner_url'),
  // }
  // );

  const onUploadBannerDone = (imageResponse) => {
    setBannerResponse(imageResponse);
  };
  const onUploadLogoDone = (imageResponse) => {
    // setImageLogo(imageResponse);
    setImageResponse(imageResponse);
  };
  const onFinish = (values) => {
    assign(values, {
      logo: get(imageResponse, 'key'),
    });
    assign(values, {
      banner: get(bannerResponse, 'key'),
    });
    assign(values, {
      brand_color: { primary: primaryColor, secondary: secondaryColor },
    });
    assign(values, { step: 2 });
    // assign(values,{logo:get(imageResponse,'key')})

    setLoading(true);
    updateHospitalDetails(hospital_uuid, values)
      .then((result) => {
        setLoading(false);
        fetchDetails();
        onSave();
        notification.success({
          message: (DETAILS_UPDATED),
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <div className="title">Branding</div>
      <Form
        name="basic"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        validateMessages={validateMessages}
        initialValues={{ slug: get(details, 'slug') }}
      >
        <Row>
          <Col span={8}>
            <Form.Item name="Image" label="Upload Logo">
              <SingleImageUpload
                onUploadDone={onUploadLogoDone}
                imageUrl={get(imageResponse, 'url')}
                setImageUser={setImageResponse}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <div className="label">Choose brand colors</div>
            <Row>
              <Col span={6} align="middle">
                <ColorPicker
                  setBrandColor={setPrimaryColor}
                  brandColor={primaryColor}
                />
              </Col>
              <Col span={12} align="middle">
                <ColorPicker
                  setBrandColor={setSecondaryColor}
                  brandColor={secondaryColor}
                />
              </Col>
            </Row>
            <Row>
              <Col span={6} className="info" align="middle">
                Primary Color
              </Col>
              <Col span={12} className="info" align="middle">
                Secondary Color
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Custom url"
              name="slug"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                className="custom-input"
        // value={`https://smart-meet-ui.vercel.app/hospital/${get(details, 'slug')}`}
                addonBefore="smart-meet-ui.vercel.app/hospital/"
                disabled={true}
              />
            </Form.Item>
          </Col>
          <div className="hospital-image-upload">
            <Row>
              <Col span={24}>
                <div className="title">Upload Hospital Image</div>
              </Col>
            </Row>
            <Col span={8}>
              <Form.Item name="Image">
                <SingleImageUpload
                  onUploadDone={onUploadBannerDone}
                  imageUrl={get(bannerResponse, 'url')}
                  setImageUser={setBannerResponse}
                />
              </Form.Item>
            </Col>
          </div>
          <Col span={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
