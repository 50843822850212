import { Spin, Row, Col, Card, Space, DatePicker, Table, Drawer, Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import get from 'lodash/get';
import round from 'lodash/round';
import isNaN from 'lodash/isNaN';

import requireAuth from '../../hocs/requireAuth';
import DashboardGraph from '../../components/dasboardGraph';
import { fetchDashboardStats } from '../../redux/actions/staffAction';
import total_appointments from '../../assets/icons/total_appointments.svg';
import appointments_fulfilled from '../../assets/icons/appointments_fulfilled.svg';
import appointments_noshow from '../../assets/icons/appointments_noshow.svg';
import appointments_cancelled from '../../assets/icons/appointments_cancelled.svg';
import moment from 'moment';

import { Radio } from 'antd';
import './style.scss';
import { filterDays } from '../../constants/options';
import {
  fetchAppointments,
  updateAppointmentStatus,
} from '../../redux/actions/doctorAction';
import { appointmentsColumn } from '../../constants/table/appointmentsColumn';
import { useSelector } from 'react-redux';
import SimpleModal from '../../components/modals/simpleModal/SimpleModal';
import RescheduleForm from '../../components/drawerContent/rescheduleForm/RescheduleForm';
import ConsultationForm from '../../components/drawerContent/consultationForm.js/ConsultationForm';
import MedicalHistory from '../../components/drawerContent/medicalHistory/MedicalHistory';
import ReasonModal from '../../components/modals/reasonModal/ReasonModal';
import DoneModal from '../../components/modals/doneModal/DoneModal';
import { useHistory } from 'react-router';
import { YEAR_MONTH_DAY } from '../../constants/constantsDateFormats';

const { RangePicker } = DatePicker;

const StatsCard = ({ title, value, icon, percent, percentType }) => {
  return (
    <Card className="stats-card">
      <div className="container">
        <div className="icon-container">
          <img src={icon} alt="" />
        </div>
        <div className="text-container">
          <div>
            <div className="data">
              <div className="large-bold">{isNaN(value) ? 0 : value}</div>
              {percent >= 0 && (
                <div className={`${percentType} percent-text`} >{percent} %</div>
              )}
            </div>
            <div className="medium-grey-bold">{title}</div>
          </div>
        </div>
      </div>
    </Card>
  );
};

const Dashboard = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [dashboardStats, setDashboardStats] = useState();

  const [appointmentId, setAppointmentId] = useState();
  const [message, setMessage] = useState();
  const [appointmentsResult, setAppointmentsResult] = useState({
    appointments: [],
    pagination: null,
  });

  const [dashboardFilter, setDashboardFilter] = useState({
    fromDate: moment().format(YEAR_MONTH_DAY),
    toDate: moment().format(YEAR_MONTH_DAY),
    days: 1,
  });

  const handleDays = (days) => {
    const toDate = moment().format(YEAR_MONTH_DAY);
    const fromDate = moment(moment()).subtract(days, 'd').format(YEAR_MONTH_DAY);

    getDashboardStats(fromDate, toDate, days);
  };

  const handleRange = (dates) => {
    if (dates) {
      const toDate = moment(dates[1]).format(YEAR_MONTH_DAY);
      const fromDate = moment(dates[0]).format(YEAR_MONTH_DAY);
      getDashboardStats(fromDate, toDate);
    }
  };

  const [filter, setFilter] = useState({
    page: 1,
    // fromDate: moment().format(YEAR_MONTH_DAY),
    fromDate: moment(moment()).subtract(7, 'd').format(YEAR_MONTH_DAY),
    toDate: moment().format(YEAR_MONTH_DAY),
    days: 1,
    status: null,
  });
  const hospital_uuid = useSelector((state) =>
    get(state, 'auth.currentHospital.id')
  );
  const userId = useSelector((state) => get(state, 'auth.id'));
  const roles = useSelector((state) => get(state, 'auth.roles'));

  const media = window.matchMedia(`(min-width: 1023px)`);

  useEffect(() => {
    getDashboardStats(
      get(dashboardFilter, 'fromDate'),
      get(dashboardFilter, 'toDate'),
      1
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (roles.includes(3)) {
      getAppointments(
        get(filter, 'page'),
        get(filter, 'fromDate'),
        get(filter, 'toDate'),
        get(filter, 'days'),
        get(filter, 'status')
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDashboardStats = (fromDate, toDate, days) => {
    setLoading(true);
    fetchDashboardStats(fromDate, toDate)
      .then((result) => {
        setLoading(false);
        setDashboardStats(result);
        setDashboardFilter({ fromDate, toDate, days });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getPercent = (count, total) => {
    let percentage = round((count / total) * 100, 2);
    return isNaN(percentage) ? 0 : percentage;
  };

  let complete = parseInt(
    get(dashboardStats, 'appointmentTotals.0.total_complete', 0)
  );
  let cancelled = parseInt(
    get(dashboardStats, 'appointmentTotals.0.total_cancelled', 0)
  );
  let noShow = parseInt(
    get(dashboardStats, 'appointmentTotals.0.total_noShow', 0)
  );

  let total = complete + noShow + cancelled;

  const [consultationForm, setConsultationForm] = useState({
    visible: false,
    edit: false,
    data: null,
  });

  const toggleConsultationForm = (data = null) => {
    setConsultationForm({
      visible: !get(consultationForm, 'visible'),
      edit: !get(consultationForm, 'visible'),
      data,
    });
  };

  const [rescheduleDrawer, setRescheduleDrawer] = useState({
    visible: false,
    edit: false,
    data: null,
  });

  const toggleReschedule = (data = null) => {
    setRescheduleDrawer({
      visible: !get(rescheduleDrawer, 'visible'),
      edit: !get(rescheduleDrawer, 'visible'),
      data,
    });
  };

  const [cancelModal, setCancelModal] = useState(false);
  const toggleCancel = (data = null) => {
    setCancelModal(!cancelModal);
    setAppointmentId(get(data, 'id'));
  };

  const [reasonModal, setReasonModal] = useState(false);
  const toggleReason = () => {
    setCancelModal(false);
    setReasonModal(!reasonModal);
  };

  const [doneModal, setDoneModal] = useState(false);
  const toggleDone = (message) => {
    setCancelModal(false);
    setReasonModal(false);
    setMessage(message);
    setDoneModal(!doneModal);
  };

  const [drawer, setDrawer] = useState({
    visible: false,
    edit: false,
    data: null,
  });

  const toggleMedicalHistory = (data = null) => {
    setDrawer({
      visible: !get(drawer, 'visible'),
      edit: !get(drawer, 'visible'),
      data,
    });
  };

  const getAppointments = (page, fromDate, toDate, days, status = null) => {
    setLoading(true);
    fetchAppointments(fromDate, toDate, status, page)
      .then((result) => {
        setLoading(false);
        setAppointmentsResult({
          appointments: get(result, 'data'),
          pagination: get(result, 'meta.pagination'),
        });
        setFilter({ page, fromDate, toDate, days, status });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const cancelAppointment = (reason) => {
    setLoading(true);
    const payload = { status: 4, cancel_reason: reason };

    updateAppointmentStatus(appointmentId, payload)
      .then((result) => {
        setLoading(false);
        toggleDone('Appointment Cancelled!');
        getAppointments(
          get(filter, 'page'),
          get(filter, 'fromDate'),
          get(filter, 'toDate'),
          get(filter, 'days'),
          get(filter, 'status')
        );
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleViewAll = () => {
    history.push('/appointments');
  };

  const refreshDashboardData = () => {
    window.location.reload();
  }

  return (
    <Spin spinning={loading}>
      <Row gutter={[10, 10]}>
        <Col xs={24} lg={6}>
          <div className='buttons-gap justify-between'>
            <div className="screen-title">Dashboard</div>
            {!media.matches &&
              <Button
                type="secondary"
                icon={<ReloadOutlined />}
                onClick={refreshDashboardData}
                className='refresh-btn'
              >
                Refresh data
              </Button>}
          </div>
        </Col>
        <Col xs={24} lg={18}>
          <Row align="end" gutter={[10, 10]}>
            {media.matches && <Col>
              <Button
                type="secondary"
                icon={<ReloadOutlined />}
                onClick={refreshDashboardData}
                className='refresh-btn'
              >
                Refresh data
              </Button>
            </Col>}
            <Col>
              <Radio.Group
                options={filterDays}
                value={get(dashboardFilter, 'days')}
                optionType="button"
                buttonStyle="solid"
                onChange={(event) => handleDays(get(event, 'target.value'))}
              />
            </Col>
            <Col>
              <RangePicker
                onChange={(dateStrings) => handleRange(dateStrings)}
              />
            </Col>

          </Row>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className='xs-mt-20' justify={`${media.matches ? "" : "center"}`}>
        <Col>
          <StatsCard
            title="Total  appointments"
            value={total}
            icon={total_appointments}
          />
        </Col>
        <Col>
          <StatsCard
            title="Appointments  fullfilled"
            value={complete}
            icon={appointments_fulfilled}
            percentType="success"
            percent={getPercent(complete, total)}
          />
        </Col>
        <Col>
          <StatsCard
            title="Appointments  cancelled"
            value={cancelled}
            icon={appointments_cancelled}
            percentType="danger"
            percent={getPercent(cancelled, total)}
          />
        </Col>
        <Col>
          <StatsCard
            title="Appointments  noShow"
            value={noShow}
            icon={appointments_noshow}
            percentType="warning"
            percent={getPercent(noShow, total)}
          />
        </Col>
      </Row>
      {get(dashboardStats, 'dayStats', []).length > 0 && (
      <h4>Daily Status</h4>
      )}
      <Row>
        <Col xl={24}>
          {console.log("daily stats", dashboardStats)}
          {get(dashboardStats, 'dayStats', []).length > 0 && (
            <DashboardGraph data1={get(dashboardStats, 'dayStats')} type='dayStats' />
          )}
        </Col>
      </Row>
      <Row>
      {get(dashboardStats, 'monthlyStats', []).length > 0 && (
      <h4 style={{marginTop:"24px"}}>Monthly Status</h4>
      )}
        <Col xl={24}>
          {get(dashboardStats, 'monthlyStats', []).length > 0 && (
            <DashboardGraph data1={get(dashboardStats, 'monthlyStats')} type='monthlyStats' />
          )}
        </Col>
      </Row>
      {/* {roles.includes(3) && (
        <>
          <Table
            columns={appointmentsColumn({
              userId,
              toggleCancel,
              toggleReschedule,
              toggleMedicalHistory,
              toggleConsultationForm,
            })}
            dataSource={get(appointmentsResult, 'appointments').slice(0, 5)}
            pagination={false}
          />

          <div className="appointments-link" onClick={handleViewAll}>
            View All
          </div>

          <Drawer
            className="main-drawer"
            destroyOnClose
            width="50%"
            closable={false}
            onClose={() => toggleMedicalHistory(null)}
            visible={get(drawer, 'visible')}
          >
            <MedicalHistory
              toggleMedicalHistory={toggleMedicalHistory}
              patient_uuid={get(drawer, 'data.patient.id')}
              patient_details={get(drawer, 'data.patient')}
              appointment_id={get(drawer, 'data.appointment_id')}
              setLoading={setLoading}
            />
          </Drawer>
          <Drawer
            className="main-drawer"
            destroyOnClose
            width="50%"
            closable={false}
            onClose={() => toggleConsultationForm(null)}
            visible={get(consultationForm, 'visible')}
          >
            <ConsultationForm
              toggleConsultationForm={toggleConsultationForm}
              appointment_uuid={get(consultationForm, 'data.id')}
              appointment_details={get(consultationForm, 'data')}
              getAppointments={getAppointments}
              filter={filter}
            />
          </Drawer>
          <Drawer
            className="main-drawer"
            destroyOnClose
            width="40%"
            closable={false}
            onClose={() => toggleReschedule(null)}
            visible={get(rescheduleDrawer, 'visible')}
          >
            <RescheduleForm
              toggleReschedule={toggleReschedule}
              doctor_uuid={get(rescheduleDrawer, 'data.doctor.id')}
              hospital_uuid={hospital_uuid}
              appointment_uuid={get(rescheduleDrawer, 'data.id')}
              getAppointments={getAppointments}
              filter={filter}
            />
          </Drawer>
          <SimpleModal
            okText="Yes"
            closable={false}
            centered={true}
            visible={cancelModal}
            onCancel={() => toggleCancel()}
            onOk={() => toggleReason()}
            text="Do you want to cancel appointment?"
          />
          <ReasonModal
            visible={reasonModal}
            onDone={cancelAppointment}
            onCancel={() => toggleReason()}
            title="Reason to Cancel Appointment?"
          />
          <DoneModal
            message={message}
            visible={doneModal}
            toggleDone={toggleDone}
          />
        </>
      )} */}
    </Spin>
  );
};
export default requireAuth(Dashboard, [1, 2, 3, 5]);
