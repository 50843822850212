import React, {useEffect} from 'react';
import { styled } from '@mui/material';
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import PlayerControls from './PlayerControls';
import PlayerOverlay from './PlayerOverlay';
import { INITIAL_STATE, reducer } from './Player.reducer';
import Button from '@mui/material/Button';

const StyledPlayer = styled('div')<ReactPlayerProps>`
  position: relative;
  aspect-ratio: 16/9;
  border-radius: 8px;

  video,
  .react-player__preview {
    border-radius: 8px;
  }

  // defined from script, if props light is true then is visible
  .react-player__preview:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.1), transparent);
  }
  

  &:hover {
    .video-player__controls {
      opacity: 1;
    }
  }

  .video-player__controls {
    opacity: ${({ state }) => (state?.light ? '0' : state.playing ? '0' : '1')};
  }
`;

const Player: React.FC<ReactPlayerProps>=(props) => {
  const { light,videos } = props;
  const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);
  const playerRef = React.useRef<ReactPlayer>(null);
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  

  const [videosList, setVideosList] = React.useState<any>([]
  //   [
  //   {
  //     priority: 1,
  //     videoLink: 'https://res.cloudinary.com/oneplayer/video/upload/v1658029376/highquality_videos/Vikram_Hitlist___Rolex_Sir_Promo___Kamal_Haasan___Suriya___Vijay_Sethupathi___Lokesh_Kanagaraj_ott5jd.mp4'
  //   },
  //    {
  //     priority: 2,
  //     videoLink: 'https://res.cloudinary.com/oneplayer/video/upload/v1658029378/highquality_videos/VIKRAM_-_The_First_Glance___KamalHaasan232___Kamal_Haasan___Lokesh_Kanagaraj___Anirudh_Ravichander_jeyjix.mp4'
  //   },
  //    {
  //     priority: 3,
  //     videoLink: 'https://res.cloudinary.com/oneplayer/video/upload/v1658029378/highquality_videos/Fury_of_NTR30_-_Telugu___NTR___Koratala_Siva___Anirudh_Ravichander_blmvij.mp4'
  //   },
  //    {
  //     priority: 4,
  //     videoLink: 'https://res.cloudinary.com/oneplayer/video/upload/v1658029378/highquality_videos/Mass_Maharaja_RAVITEJA_Joins_Mega154___Megastar_Chiranjeevi___Director_Bobby___DSP___Trend_Telugu_eyxp8k.mp4'
  //   },
  //    {
  //     priority: 5,
  //     videoLink: 'https://res.cloudinary.com/oneplayer/video/upload/v1658029378/highquality_videos/God_Father_First_Look___Megastar_Chiranjeevi___Mohan_Raja___Thaman_S___R_B_Choudary___N_V_Prasad_stnz7x.mp4'
  //   },
  //    {
  //     priority: 6,
  //     videoLink: 'https://res.cloudinary.com/oneplayer/video/upload/v1658029380/highquality_videos/AGENT_Teaser___Akhil_Akkineni_Mammootty___Surender_Reddy___Anil_Sunkara_gmvj5m.mp4'
  //   },
  // ]
  );
  useEffect(()=>{
    setVideosList(videos);
    if(videos?.length > 0){
      setUrl(videos?.[0]?.videoLink)
      // setUrl(videos?.[0]?.video_link)
      handlePlay();
      }
  },[videos])

  const [ url, setUrl] = React.useState('');
  

  const handlePreview = () => {
    dispatch({ type: 'PLAY' });
    dispatch({ type: 'LIGHT', payload: false });
  };

  const handlePause = () => {
    dispatch({ type: 'PAUSE' });
  };

  const handlePlay = () => {
    dispatch({ type: 'PLAY' });
  };

  const handleEnded = () => {
    for(let i=0; i< videosList?.length; i++) {
      if(url === videosList?.[i]?.videoLink) {
        if(i + 1 === videosList.length) {
          setUrl(videosList?.[0]?.videoLink);
        } else {
        setUrl(videosList?.[i+1]?.videoLink);
        }
      }
    }
    
    handlePlay();
  };

  const playBegining = () => {
      setUrl(videosList?.[0]?.videoLink);
        handlePlay();
  }

  const handleProgress = (progress: { playedSeconds: number }) => {
    dispatch({ type: 'SEEK', payload: progress.playedSeconds });
  };

  const handleDuration = (duration: number) => {
    dispatch({ type: 'DURATION', payload: duration });
  };
  return (
    <StyledPlayer state={state} ref={wrapperRef}>
       <Button variant="contained"
       onClick={() => playBegining()}
       >       Play from beginning</Button>
      <ReactPlayer
        ref={playerRef}
        url={url}
        width="100%"
        height="100%"
        light={light}
        playIcon={
          <PlayArrowRounded
            sx={{
              color: 'white',
              fontSize: '6rem',
            }}
          />
        }
        controls={state.controls}
        loop={state.loop}
        muted={state.muted}
        playing={state.playing}
        playbackRate={state.playbackRate}
        volume={state.volume}
        onPlay={handlePlay}
        onEnded={handleEnded}
        onPause={handlePause}
        onDuration={handleDuration}
        onProgress={handleProgress}
        onClickPreview={handlePreview}
      />
      <PlayerOverlay state={state} />
      {!state.controls && !state.light && (
        <PlayerControls state={state} dispatch={dispatch} playerRef={playerRef} wrapperRef={wrapperRef} />
      )}
    </StyledPlayer>
  );
};

export default Player;
