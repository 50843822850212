import React from "react";
import {
    Input,
    Form,
    Button
  } from 'antd';
import moment from "moment"; 
import get from "lodash/get";
import ContactCodeSelector from './ContactCodeSelector';
import { createEmergencyAppointment } from "../../redux/actions/doctorAction";


const OutsideForm=({filteredUser,doctor_uuid,hospital_uuid,closeFormModal,currentSlot,slotType})=>{
    const session={user : filteredUser};
    const onFinish=(values)=>{
        let data ={};
        data["doctor_uuid"] =doctor_uuid;
        data["hospital_uuid"] = hospital_uuid;
        data["date"] = moment().format("YYYY-MM-DD");
        data["name"] = values?.name;
        data["email"] = values?.email;
        data["contact_number"] = values?.contact_number;
        data["contact_code"] = values?.contact_code;
        data["user_uuid"] =filteredUser?.id;
        data["slot"] = currentSlot;
        data["type"] = slotType
        createEmergencyAppointment(data).then((result)=>{
          closeFormModal();
      }).catch((err)=>{
          console.log("error",err);
      })
    }
    return(
        <>
        <Form
        name="appointment"
        layout="vertical"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        initialValues={
          get(session, 'user')
            ? {
                email: get(session, 'user.email'),
                contact_number: get(session, 'user.contact_number'),
                contact_code: get(session, 'user.contact_code'),
                name: get(session, 'user.name'),
                title: get(session, 'user.title'),
              }
            : {}
        }
      >
        <div className="book-consultation-style">
          <div className="modal-title">Book Consultation</div>

          {/* <div className="select-mode checkbox-inner-style"> */}
          {/* <div className="text-info">
              For whom you wanna book consulation{' '}
              <span className="text-danger">*</span>
            </div> */}
          {/* </div> */}

          <div className="form-section">
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please Input Name',
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter Name"
                // addonBefore={TitleSelector}
              />
            </Form.Item>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please Input email Address',
                },
                {
                  type: 'email',
                  message: 'Invalid Email Format',
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter Email"
                disabled={get(session, 'user') ? true : false}
              />
            </Form.Item>

            <Form.Item
              label="Contact Number"
              name="contact_number"
              rules={[
                {
                  required: true,
                  message: 'Please Input Contact number',
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter Contact"
                addonBefore={ContactCodeSelector}
                // addonBefore={addonBefore={ContactCodeSelector}}
              />
            </Form.Item>
            <div className={`button-primary`}>
              <Form.Item>
                <Button type="primary" onClick={() => {}} htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
        </>
    )
}
export default OutsideForm;