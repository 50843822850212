import { Col, Row, TimePicker } from 'antd';
import moment from 'moment';
import get from 'lodash/get';
import map from 'lodash/map';
import indexOf from 'lodash/indexOf';
import { CloseOutlined, DownOutlined } from '@ant-design/icons';

import React from 'react';
import './style.scss';

// const days = [
//   'Sunday',
//   'Monday',
//   'Tuesday',
//   'Wednesday',
//   'Thursday',
//   'Friday',
//   'Saturday',
// ];

const days = [
  { name: 'S', value: 0 },
  { name: 'M', value: 1 },
  { name: 'T', value: 2 },
  { name: 'W', value: 3 },
  { name: 'T', value: 4 },
  { name: 'F', value: 5 },
  { name: 'S', value: 6 },
];
const format = 'HH:mm';

export default function SingleWorkingHours({
  openHours,
  onChange,
  index,
  remove,
}) {
  const onSelect = (day) => {
    const currentIndex = indexOf(get(openHours, 'days', []), day.value);

    if (currentIndex !== -1) {
      // Remove
      openHours.days.splice(currentIndex, 1);
      const newDays = { ...openHours, days: openHours.days };
      onChange(newDays, index);
    } else {
      //add
      const newDays = { ...openHours, days: [...openHours.days, day.value] };
      onChange(newDays, index);
    }
  };

  return (
    <Row type="flex" align="middle" className="singleTime" gutter={3}>
      <Col span={12}>
        <Row gutter={3} align="center">
          {map(days, (day) => (
            <Col className="gutter-row" span={3}>
              <DayIcon
                day={day}
                selected={openHours.days.indexOf(day.value) !== -1}
                onClick={onSelect}
              />
            </Col>
          ))}
        </Row>
      </Col>
      <Col span={5}>
        <TimePicker
          value={moment(openHours.from_time, format)}
          minuteStep={15}
          format={format}
          showNow={false}
          allowClear={false}
          size="small"
          suffixIcon={
            <DownOutlined style={{ color: 'black', fontSize: '12px' }} />
          }
          onChange={(time, timeString) => {
            const newTime = { ...openHours, from_time: timeString };
            onChange(newTime, index);
          }}
        />
      </Col>
      <Col span={5}>
        <TimePicker
          value={moment(openHours.to_time, format)}
          minuteStep={15}
          format={format}
          allowClear={false}
          suffixIcon={
            <DownOutlined style={{ color: 'black', fontSize: '12px' }} />
          }
          size="small"
          showNow={false}
          onChange={(time, timeString) => {
            const newTime = { ...openHours, to_time: timeString };
            onChange(newTime, index);
          }}
        />
      </Col>
      {index > 0 && (
        <Col span={2} align="middle" style={{ fontSize: '12px' }}>
          <CloseOutlined onClick={() => remove(index)} />
        </Col>
      )}
    </Row>
  );
}

function DayIcon({ day, selected, onClick }) {
  const onClickLocal = () => {
    onClick(day);
  };

  return (
    <div
      style={{
        background: selected ? '#9c6dff' : 'white',
        color: selected ? 'white' : 'black',
      }}
      className="day"
      onClick={onClickLocal}
    >
      {day.name}
    </div>
  );
}
