// import remove_red from '../../assets/icons/remove_red.svg';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';



const cityColums = ({ onClickEdit,removeCity }) => [
  {
    title: 'City',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
  },
  {
    title : 'Action',
    key : 'action',
    render :(record)=>{
      return(
        <div>
           <Button
        type="danger"
        onClick={() => removeCity(record)}
        icon={<DeleteOutlined />}
      >
      </Button>
        </div>
      )
    }

  }
];

export default cityColums;
