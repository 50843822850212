import send_invoice from "../../assets/icons/send_invoice.svg";
import send_reminder from "../../assets/icons/send_reminder.svg";
import get from "lodash/get";
import { Avatar, Button, Col, Row, Space } from "antd";
import CustomTag from "../../components/tag/CustomTag";
import {
  DeleteOutlined,
} from "@ant-design/icons";

export const IndividualTvAdsColumn = ({
  deleteTvAd,
  toggleViewAds,
  toggleEdit
}) => [
  {
    title: "Ad Name",
    key: "ad_name",
    render: (record) => (
      <Row>
        <Space>
          <Col>
            <div className="medium">{get(record, "ad_name")}</div>
          </Col>
        </Space>
      </Row>
    ),
  },
  {
    title: "Start Time",
    key: "start_time",
    render: (record) => (
      <Row>
        <Space>
          <Col>
            <div className="medium">{get(record, "start_time")}</div>
          </Col>
        </Space>
      </Row>
    ),
  },
  {
    title: "End Time",
    key: "end_time",
    render: (record) => (
      <Row>
        <Space>
          <Col>
            <div className="medium">{get(record, "end_time")}</div>
          </Col>
        </Space>
      </Row>
    ),
  },
  {
    title: "Display Order",
    key: "play_order",
    render: (record) => (
      <Row>
        <Space>
          <Col>
            <div className="medium">{get(record, "play_order")}</div>
          </Col>
        </Space>
      </Row>
    ),
  },
  // {
  //   title: "is Default Video",
  //   key: "is_default_video",
  //   render: (record) => (
  //     <Row>
  //       <Space>
  //         <Col>
  //           <div className="medium">{get(record, "is_default_video")}</div>
  //         </Col>
  //       </Space>
  //     </Row>
  //   ),
  // },
  {
    title: "Status",
    key: "is_active",
    render: (record) => <CustomTag status={record?.is_active=== true ? "Active" : "In Active"}/>,
  },
  {
    title: "Actions",
    key: "action",
    render: (record) => (
      <Row>
        <Space>
          <Button
            className="custom-action-button"
            onClick={() => {
              toggleViewAds(record);
            }}
            type="text"
          >
            <>
              <img
                src={send_invoice}
                alt=""
                className="appointment-action-icon"
              />
              <p className="small">View Ads</p>
            </>
          </Button>

          <Button
            className="custom-action-button"
            type="text"
            onClick={() => toggleEdit(record?.uuid)}
          >
            <img
              src={send_reminder}
              alt=""
              className="appointment-action-icon"
            />
            <p className="small">Edit</p>
          </Button>
          <>
            <Button
              className="custom-action-button"
              type="text"
              onClick={() => {
                deleteTvAd(record?.uuid);
              }}
              icon={<DeleteOutlined />}
            >
              <p className="small">Delete</p>
            </Button>
          </>
        </Space>
      </Row>
    ),
  },
];
