import send_reminder from "../../assets/icons/send_reminder.svg";
import get from "lodash/get";
import { Avatar, Button, Col, Row, Space } from "antd";
import CustomTag from "../../components/tag/CustomTag";

export const HospitalPatientsColumn = ({
  toggleEdit,
}) => [
  {
    title: "User Name",
    key: "name",
    render: (record) => (
      <Row>
        <Space>
          <Col>
            <div className="medium">{get(record, "name")}</div>
          </Col>
        </Space>
      </Row>
    ),
  },
  {
    title: "Status",
    key: "is_active",
    render: (record) => (
      <CustomTag status={record?.is_active === true ? "Active" : "In Active"} />
    ),
  },
  {
    title: "Actions",
    key: "action",
    render: (record) => (
      <Row>
        <Space>
          <Button
            className="custom-action-button"
            type="text"
            onClick={() => toggleEdit(record?.uuid)}
          >
            <img
              src={send_reminder}
              alt=""
              className="appointment-action-icon"
            />
            <p className="small">Edit</p>
          </Button>
        </Space>
      </Row>
    ),
  },
];
