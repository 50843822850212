import { CloseCircleFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Row, Col, Select } from "antd";
import { createNoteTemplete,getNoteTemplates } from "../../../redux/actions/doctorAction";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
const { Option } = Select;

const CustomizeTemplateForm = ({
  setSelectedTemplate,
  noteTemplates,
  setOpenTemplate,
  doctor_user_uuid,
  setNoteTemplates
}) => {
    const [loading,setLoading] = useState(false);
  const changeHandler = (name, value) => {
  };
  console.log("noteTemplates", noteTemplates);
  const onFinish = (values) => {
    let payload = {
        doctor_user_uuid : doctor_user_uuid,
        notetemplate : values?.notetemplate,
        template_name : values?.template_name
      }
      createNoteTemplete(payload).then((result)=>{
        setLoading(false);
       setOpenTemplate(false);
       getNoteTemplates(doctor_user_uuid).then((result)=>{
        setLoading(false);
        setNoteTemplates(result);
      })

      }).catch((error)=>{
        setLoading(false);
      })
  };
  const closeTemplate=()=>{
    setOpenTemplate(false);
  }
 
  return (
    <Form
      style={{ marginTop: "12px" }}
      // form={form}
      onFinish={onFinish}
    >
      <Form.Item
        label="Template Name"
        name="template_name"
        rules={[
          {
            required: true,
            message: "Template Name is required",
          },
        ]}
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        style={{ marginLeft: "48px" }}
        label="Note"
        name="notetemplate"
        rules={[
          {
            required: true,
            message: "Note is required",
          },
        ]}
      >
        <TextArea placeholder="Content" rows="6" />
      </Form.Item>
      <div style={{ display: "flex" }}>
        <Button
          type="primary"
          style={{ marginLeft: "auto",marginRight:"8px" }}
          htmlType="submit"
        >
          Submit
        </Button>
        <Button type="secondary" onClick={closeTemplate}>
          Close
        </Button>
      </div>
    </Form>
  );
};

export default CustomizeTemplateForm;
