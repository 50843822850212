import React from 'react';

import { geocodeByAddress } from 'react-places-autocomplete';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete';
import get from 'lodash/get';

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: '', show: false };
  }
  componentDidMount() {
    this.setState({ show: true });
  }

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    console.log({ address });
    geocodeByAddress(address)
      .then((results) => {
        let geo = results[0].geometry;

        console.log('@@@@ bound', geo.bounds);
        console.log(
          '###',
          geo.bounds.getNorthEast(),
          geo.bounds.getNorthEast()
        );

        // return geo.get
        return null;
      })
      .then((latLng) => console.log('Success'))
      .catch((error) => console.error('Error'));
  };

  getGeoMetry = (geometry) => {
    console.log(geometry[0].geometry);
    const geo = geometry[0].geometry;

    console.log(
      '###',
      geo.bounds.getNorthEast().lat(),
      geo.bounds.getSouthWest().lat()
    );
  };

  render() {
    // let defaultBounds = {
    //   north: 29.5018855,
    //   south: 29.4483659,
    //   east: 77.73914529999999,
    //   west: 77.67857029999999,
    // };

    return (
      // eslint-disable-next-line no-undef
      <div>
        <GooglePlacesAutocomplete
          autocompletionRequest={{
            types: ['(cities)'],
          }}
          selectProps={{
            onChange: (va) => {
              console.log({ va }, get(va, 'value.place_id'));
              // console.log("=====>",geocodeByPlaceId())
              geocodeByPlaceId(get(va, 'value.place_id'))
                .then((results) => this.getGeoMetry(results))
                .catch((error) => console.error(error));
            },
          }}
        />
      </div>

      // eslint-disable-next-line no-undef
    );
  }
}

export default LocationSearchInput;
