export const filterDays = [
  { label: 'Today', value: 0 },
  { label: '7 days', value: 7 },
  { label: '30 days', value: 30 },
];

export const filterPatients = [
  { label: 'All Patients', value: null },
  { label: 'Done', value: 3 },
  { label: 'Pending', value: 1 },
  { label: 'No Show', value: 2 },
  { label: 'Cancelled', value: 4 },
  { label: 'Incomplete', value: 0 },
];

export const filterApprovals = [
  { label: 'All', value: null },
  { label: 'Approved', value: 1 },
  { label: 'Rejected', value: 2 },
  { label: 'Pending', value: 0 },
];

export const filterBillings = [
  { label: 'All Billings', value: null },
  { label: 'Pending', value: 0 },
  { label: 'Paid', value: 1 },
];

export const billingTypeOptions = [
  { label: 'Per Appointment', value: 1 },
  { label: 'Per Hospital', value: 2 },
  { label: 'Per Doctor', value: 3 },
];
