import { isEmpty } from '@antv/util';
import * as APIS from '../../constants/apiEndpoints';
import { axiosInstance } from '../../utilities/configureAxios';
import { getUrl } from '../../utilities/helpers';

export const updateHospitalStatus = (hospital_uuid, payload) => {
  let url = APIS.UPDATE_HOSPITAL_STATUS.replace(
    '{hospital_uuid}',
    hospital_uuid
  );

  return axiosInstance.put(url, payload).then((result) => {
    return result;
  });
};

export const createCity = (payload) => {
  return axiosInstance.post(APIS.CITIES, payload).then((result) => {
    return result;
  });
};
export const getCityList = () => {
  return axiosInstance.get(APIS.CITIES).then((result) => {
    return result;
  });
};
export const getCommentsList =(page,query) =>{
  let url = getUrl(APIS.COMMENTS, {
    page,
  });
  if (query) {
    url = url.concat('&query=' + query);
  }
  return axiosInstance.get(url).then((result)=>{
    return result;
  })
}
export const deleteComment =(id) =>{
  let url = APIS.DELETE_COMMENT.replace('{uuid}',id);
  return axiosInstance.delete(url).then((result)=>{
    return result;
  })
}
export const getComment =(id) =>{
  let url = APIS.GET_COMMENT.replace('{uuid}',id);
  return axiosInstance.get(url).then((result)=>{
    return result;
  })
}
export const deleteCity =(id) =>{
  let url = APIS.DELETE_CITY.replace('{uuid}',id);
  return axiosInstance.delete(url).then((result)=>{
    return result;
  })
}
export const getBillings = (
  from_date,
  to_date,
  status,
  page,
  name,
  limit = 10
) => {
  var url = getUrl(APIS.INVOICE_LIST, {
    from_date,
    to_date,
    page,

    limit,
  });
  if (status !== null) {
    url = url.concat('&status=' + status);
  }
  if (!isEmpty(name)) {
    url = url.concat('&name=' + name);
  }
  return axiosInstance.get(url).then((result) => {
    return result;
  });
};
export const markInvoicePaid = (invoice_uuid, payload) => {
  var url = APIS.MARK_PAID.replace('{invoice_uuid}', invoice_uuid);
  return axiosInstance.put(url, payload).then((result) => {
    return result;
  });
};

export const updateHospitalBilling = (hospital_uuid, payload) => {
  var url = APIS.UPDATE_BILLINGS.replace('{hospital_uuid}', hospital_uuid);
  return axiosInstance.put(url, payload).then((result) => {
    return result;
  });
};
