import {
  CloseOutlined,
  PlusOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";
import { Col, Row, Input, Space } from "antd";
import get from "lodash/get";
import { Button, Form, Spin, List, Divider, message, Checkbox } from "antd";
import moment from "moment";
import { useForm } from "antd/lib/form/Form";
import React, { useState, useEffect } from "react";
import SingleImageUpload from "../../mediaUpload/SingleImageUpload";
import { DatePicker, notification } from "antd";
import {
  postHospitalMediaAd,
  updateHospitalMediaAd,
  fetchHospitalMediaAdById,
} from "../../../redux/actions/hospitalAction";
import { YEAR_MONTH_DAY } from "../../../constants/constantsDateFormats";


// import './style.scss';

const CreateTvAdsDetails = ({
  closeNewTvAd,
  data,
  tv_id,
  setCurrentAdId,
  fetchMediaAds,
  currentAdId,
  filter
}) => {
  console.log("INOIVE INFO>>>>>", data);
  const [form] = useForm();
  const [editAd, setEditAd] = useState(null);
  const [is_active, setIs_active] = useState(false);
  const [imageUser, setUserImage] = useState();
  const [componentDisabled, setComponentDisabled] = useState();
  const [activeComponentDisabled, setActiveComponentDisabled] = useState();
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [defaultVideoUrl, setDefaultVideoUrl] = useState(false);
  const [loading, setLoading] = useState(false);

  const timeFormat = "YYYY-MM-DD HH:mm:ss";
  const onFinish = (values) => {
    setLoading(true);
    let data = { ...values, tv_id: tv_id,
    start_time : moment(values?.start_time, timeFormat).format(
      timeFormat),
      end_time : moment(values?.end_time, timeFormat).format(
        timeFormat)
     };
    postHospitalMediaAd(data).then((res) => {
      const days = 1;
      const toDate = moment().format(YEAR_MONTH_DAY);
      const fromDate = moment(moment()).subtract(days, 'd').format(YEAR_MONTH_DAY);
      setLoading(false);
      notification.success({
        message: "Created Ad Successfully",
      });
      closeNewTvAd();
      setCurrentAdId(null);
    fetchMediaAds(
      get(filter, 'page'),
      fromDate,
      toDate,
      get(filter, 'days'),
      get(filter, 'status'),
      get(filter, 'name')
    );
      
    });
  };
  useEffect(() => {
    fetchHospitalMediaAdById(currentAdId).then((res) => {
      setEditAd(res);
      const convertedTvAd = { ...res };

      if (convertedTvAd?.start_time) {
        convertedTvAd.start_time = moment(convertedTvAd.start_time, timeFormat);
      }

      if (convertedTvAd?.end_time) {
        convertedTvAd.end_time = moment(convertedTvAd.end_time, timeFormat);
      }
      form.setFieldsValue(convertedTvAd);
    });
  }, [currentAdId]);

  const onUpdateAds = (values) => {
    setLoading(true);
    let data = { ...values,
      start_time : moment(values?.start_time, timeFormat).format(
        timeFormat),
        end_time : moment(values?.end_time, timeFormat).format(
          timeFormat)
       };
    updateHospitalMediaAd(currentAdId,data).then((res)=>{
      const days = 1;
      setLoading(false);
      notification.success({
        message: "Updated Ad Successfully",
      });
      closeNewTvAd();
      setCurrentAdId(null);
      const toDate = moment().format(YEAR_MONTH_DAY);
    const fromDate = moment(moment()).subtract(days, 'd').format(YEAR_MONTH_DAY);
    fetchMediaAds(
      get(filter, 'page'),
      fromDate,
      toDate,
      get(filter, 'days'),
      get(filter, 'status'),
      get(filter, 'name')
    );
     
    })
  };
  return (
    <div className="add-drawer-content">
      <Row justify="space-between">
        <Col className="drawer-title">TV Ads</Col>
        <Col>
          <CloseOutlined onClick={closeNewTvAd} />
        </Col>
      </Row>

      <Form
        name="dynamic_form_nest_item"
        className="faqs_form"
        layout="vertical"
        onFinish={editAd?.id ? onUpdateAds : onFinish}
        autoComplete="off"
        form={form}
      >
        <Row
          style={{ display: "flex", marginBottom: 8 }}
          align="baseline"
          justify="space-between"
        >
          <Col span={22}>
            <Form.Item
              name={"ad_name"}
              label="Ad Name"
              rules={[
                {
                  required: true,
                  message: "Please enter the Ad Name ",
                },
              ]}
            >
              <Input
                placeholder="Ad Name "
                className="custom-input"
              />
            </Form.Item>

            <Form.Item
              name={"video_url"}
              label="Video Url"
              rules={[
                {
                  required: true,
                  message: "Please enter the Video Url!",
                },
              ]}
            >
              <Input placeholder="Video Url" className="custom-input" />
            </Form.Item>
            <Form.Item
              name={"is_default_video"}
              label=" is Default Video "
              valuePropName="checked"
            >
              <Checkbox
                checked={defaultVideoUrl}
                onChange={(e) => setComponentDisabled(e.target.checked)}
              ></Checkbox>
            </Form.Item>

            <Form.Item
              name={"play_order"}
              label="Player Order"
              rules={[
                {
                  required: true,
                  message: "Please enter the Player Order!",
                },
              ]}
            >
              <Input
                type="number"
                placeholder="Player Order"
                className="custom-input"
              />
            </Form.Item>

            <Form.Item name={"start_time"} label="Start Time">
              <DatePicker
                showTime={true}
                format="YYYY-MM-DD HH:mm:ss"
                onChange={(date, dateString) =>
                  setStartTime(
                    moment(dateString, timeFormat).format(timeFormat)
                  )
                }
              />
            </Form.Item>
            <Form.Item name={"end_time"} label="End Time">
              <DatePicker
                showTime={true}
                format="YYYY-MM-DD HH:mm:ss"
                onChange={(date, dateString) =>
                  setEndTime(moment(dateString, timeFormat).format(timeFormat))
                }
              />
            </Form.Item>
            <Form.Item
              name={"is_active"}
              label=" is Active"
              valuePropName="checked"
            >
              <Checkbox
                checked={is_active}
                onChange={(e) => setActiveComponentDisabled(e.target.checked)}
              ></Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Divider />
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginRight: "8px" }}
          >
            {editAd?.id ? "Update" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default CreateTvAdsDetails;
