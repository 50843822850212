import { Col, Drawer, Input, Row, Select, Spin, Table,Button ,notification} from 'antd';

// import { filterPatients } from '../../constants/dummydata';
import map from 'lodash/map';
import { SearchOutlined } from '@ant-design/icons';
import { doctorsColumn } from '../../constants/table/doctorsColumn';
import './style.scss';
import { useEffect, useState } from 'react';
import get from 'lodash/get';
import AddDoctorDrawer from '../../components/drawer/AddDoctorDrawer';
import DoctorDetails from '../../components/drawerContent/doctordetails/index';

import { listDoctors, specList,getInvitations,deleteDoctor  } from '../../redux/actions/doctorAction';
import requireAuth from '../../hocs/requireAuth';
import LinkModal from '../../components/linkModal/LinkModal';
import { useSelector } from 'react-redux';
import { DELETED_DOCTOR_SUCCESS } from "../../constants/messages";


const { Option } = Select;

const Doctors = () => {
  const [specialisations, setSpecialisation] = useState([
    { name: 'All Specialities', id: null },
  ]);

  const [doctorsResult, setDoctorsResult] = useState({
    doctors: [],
    pagination: null,
  });
  const [loading, setLoading] = useState(false);

  const [doctorDetailsDrawer, setDoctorDetailsDrawer] = useState({
    visible: false,
    edit: false,
    data: null,
  });
  const [filter, setFilter] = useState({ specialisation_uuid: null, page: 1 });

  const [linkModal, setLinkModal] = useState({ data: null, visibility: false });

  const hospitalSlug = useSelector((state) =>
    get(state, 'auth.currentHospital.slug')
  );

  const toggleDoctorDetails = (data = null, edit = false) => {
    setDoctorDetailsDrawer({
      visible: !get(doctorDetailsDrawer, 'visible'),
      edit,
      data,
    });
  };

  const toggleLinkModal = (data = null) => {
    setLinkModal({ data: data, visibility: !get(linkModal, 'visibility') });
  };

  const getDoctors = (specialisation_uuid, page) => {
    setLoading(true);
    listDoctors(specialisation_uuid, page)
      .then((result) => {
        setLoading(false);
        setDoctorsResult({
          doctors: get(result, 'data'),
          pagination: get(result, 'meta.pagination'),
        });
        setFilter({ specialisation_uuid, page });
      })

      .catch((error) => {
        setLoading(false);
      });
  };
  // const [invitationData,setInvitationData] = useState

  useEffect(() => {
    getDoctors(get(filter, 'specialisation_uuid'), get(filter, 'page'));
    // hit api to get specialisation list
    specList().then((result) => {
      setSpecialisation([...specialisations, ...result]);
    });
    //eslint-disable-next-line
    
  }, []);

  const removeDoctor=(record)=>{
    deleteDoctor(record?.id)
      .then((result) => {
        console.log(result);
        notification.success({
          message: DELETED_DOCTOR_SUCCESS,
        });
        getDoctors(get(filter, 'specialisation_uuid'), get(filter, 'page'));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Spin spinning={loading}>
      <div>
        <Row justify="space-between" style={{ alignItems: 'baseline' }}>
          <Col>
            <div className="screen-title">Doctors</div>
          </Col>
          <Col>
            <AddDoctorDrawer />
            
          </Col>
        </Row>
        <Row className="search-doctor">
        <Col span={12} >
            <Input
              placeholder="Doctor, specialty, hospital or treatment"
              allowClear
              size="large"
              prefix={<SearchOutlined style={{ fontSize: '12px' }} />}
            />
          </Col>
          <Col span={12} style={{ textAlign: 'end' }}>
            <Select
              value={get(filter, 'specialisation_uuid')}
              onChange={(value) => getDoctors(value, get(filter, 'page'))}
            >
              {map(specialisations, (s, i) => (
                <Option key={i} value={get(s, 'id')}>
                  {get(s, 'name')}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Table
          columns={doctorsColumn({
            setLinkModal,
            toggleDoctorDetails,
            hospitalSlug,
            toggleLinkModal,
            removeDoctor
          })}
          scroll={{ x: true }}
          dataSource={get(doctorsResult, 'doctors')}
          pagination={{
            pageSize: get(doctorsResult, 'pagination.per_page', 1),
            current: get(doctorsResult, 'pagination.current_page', 1),
            total: get(doctorsResult, 'pagination.total', 1),
            onChange: (val) => {
              getDoctors(get(filter, 'specialisation_uuid'), val);
            },
            position: ['bottomLeft'],
          }}
        />

        <Drawer
          className="doctor-details-drawer hospital-details-drawer"
          destroyOnClose
          closable={true}
          onClose={() => toggleDoctorDetails(null)}
          visible={get(doctorDetailsDrawer, 'visible')}
        >
          <DoctorDetails
            // toggleDoctorDetails={toggleDoctorDetails}
            doctor_details={get(doctorDetailsDrawer, 'data')}
            edit={get(doctorDetailsDrawer, 'edit')}
          />
        </Drawer>

        <LinkModal linkModal={linkModal} toggleLinkModal={toggleLinkModal} />
      </div>
    </Spin>
  );
};

export default requireAuth(Doctors, [2]);
