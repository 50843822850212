import send_invoice from '../../assets/icons/send_invoice.svg';
import download_invoice from '../../assets/icons/download_invoice.svg';
import send_confirmation from '../../assets/icons/send_confirmation.svg';
import send_reminder from '../../assets/icons/send_reminder.svg';
import billing_info from '../../assets/icons/billing_info.svg';
import get from 'lodash/get';
import { Avatar, Button, Col, Row, Space } from 'antd';
import CustomTag from '../../components/tag/CustomTag';

export const billingsColumn = ({ toggleBillingInfo, toggleMarkPaid, toggleSendInvoiceModal, handlePrint }) => [
  {
    title: 'Hospital Name',
    key: 'hospital_name',
    render: (record) => (
      <Row>
        <Space>
          <Col>
            <Avatar
              size={48}
              icon={<img src={get(record, 'hospital.logo_url.url')} alt="" />}
            />
          </Col>
          <Col>
            <div className="medium">{get(record, 'hospital.name')}</div>
          </Col>
        </Space>
      </Row>
    ),
  },
  {
    title: 'Invoice number',
    key: 'invoice_number',
    render: (record) => {
      return <span>{get(record, 'invoice_number')}</span>;
    },
  },
  {
    title: 'Invoice month',
    key: 'invoice_month',
    render: (record) => {
      return (
        <span>
          {get(record, 'month_formatted')}, {get(record, 'year')}
        </span>
      );
    },
  },

  {
    title: 'Billing Type',
    key: 'billing_type',
    render: (record) => {
      return <span>{get(record, 'hospital.billing_method.name')}</span>;
    },
  },
  {
    title: 'Amount',
    key: 'amount',
    render: (record) => {
      return <span>${get(record, 'amount')}</span>;
    },
  },

  {
    title: 'Payment Status',
    key: 'payment_status',
    render: (record) => <CustomTag status={get(record, 'status')} />,
  },

  {
    title: 'Actions',
    key: 'action',
    render: (record) => (
      <Row>
        <Space>
          {get(record, 'status') === 0 ? (
            <Button
              className="custom-action-button"
                onClick={() => {
                  toggleSendInvoiceModal(record);
                }}
              type="text"
            >
              <>
                <img
                  src={send_invoice}
                  alt=""
                  className="appointment-action-icon"
                />
                <p className="small">Send Invoice</p>
              </>
            </Button>
          ) : (         
            <Button
              className="custom-action-button"
              onClick={() => handlePrint(record)}              
              type="text"
            >
              <>
                <img
                  src={download_invoice}
                  alt=""
                  className="appointment-action-icon"
                />
                <p className="small">Download Invoice</p>
              </>
            </Button>            
          )}

          {get(record, 'status') === 0 ? (
            <Button
              className="custom-action-button"
              type="text"
              onClick={() => toggleMarkPaid(record)}
            >
              <img
                src={send_reminder}
                alt=""
                className="appointment-action-icon"
              />
              <p className="small">Mark Paid</p>
            </Button>
          ) : (
            <Button
              className="custom-action-button"
              type="text"
              // onClick={() => {
              //   toggleMedicalHistory(record);
              // }}
            >
              <img
                src={send_confirmation}
                alt=""
                className="appointment-action-icon"
              />
              <p className="small">Send Confirmation</p>
            </Button>
          )}

          <>
            <Button
              className="custom-action-button"
              type="text"
              onClick={() => {
                toggleBillingInfo(record);
              }}
              // style={{
              //   opacity: get(record, 'status.value') === 1 ? 1 : 0.5,
              // }}
              // disabled={get(record, 'status.value') === 1 ? false : true}
            >
              <img
                src={billing_info}
                alt=""
                className="appointment-action-icon"
              />
              <p className="small">Billing Info</p>
            </Button>
          </>
        </Space>
      </Row>
    ),
  },
];
