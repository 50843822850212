import { Tabs, Card, Spin } from 'antd';
import { useEffect, useState } from 'react';
import HospitalDetails from '../../components/hospitalDetails';
import HospitalDetailEdit from '../../components/hospitalDetails/hospitalDetailEdit';
import LandingEdit from '../../components/hospitalDetails/landingEdit';
import Appointments from '../../components/hospitalDetails/appointments';
import Billings from '../../components/hospitalDetails/billings';
import get from 'lodash/get';
// Styles
import './styles.scss';
import { getDetails } from '../../redux/actions/hospitalAction';
import { useSelector } from 'react-redux';
import requireAuth from '../../hocs/requireAuth';
import Faqs from '../../components/faqs/Faqs';

const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

const HospitalDetailsScreen = (props) => {
  const currentHospital = useSelector((state) =>
    get(state, 'auth.currentHospital')
  );
  const [hospitalSection, setHospitalSection] = useState(false);
  const [details, setDetails] = useState();
  const [loading, setLoading] = useState(false);
  const hospital_uuid = useSelector((state) =>
    get(state, 'auth.currentHospital.id')
  );

  const homeEdit = () => {
    setHospitalSection(true);
  };
  const homeDetail = () => {
    setHospitalSection(false);
  };
  const fetchDetails = () => {
    setLoading(true);
    getDetails(get(currentHospital, 'id'))
      .then((result) => {
        setLoading(false);
        setDetails(result);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchDetails();
    //eslint-disable-next-line
  }, []);

  return (
    <Spin spinning={loading}>
      <div className="hospital-detail-style">
        <div className="screen-title">Hospital Details</div>

        <div className="tab-section">
          <Card>
            <Tabs defaultActiveKey="1" onChange={callback}>
              <TabPane tab="General" key="1">
                {!hospitalSection === true ? (
                  <HospitalDetails onClick={homeEdit} details={details} />
                ) : (
                  <HospitalDetailEdit
                    onSave={homeDetail}
                    details={details}
                    fetchDetails={fetchDetails}
                    loading={loading}
                    setLoading={setLoading}
                    hospital_uuid={hospital_uuid}
                  />
                )}
              </TabPane>
              <TabPane tab="Landing Page" key="2">
                <LandingEdit
                  details={details}
                  fetchDetails={fetchDetails}
                  loading={loading}
                  setLoading={setLoading}
                  hospital_uuid={hospital_uuid}
                />
              </TabPane>
              <TabPane tab="Appointments" key="3">
                <Appointments
                  details={details}
                  fetchDetails={fetchDetails}
                  loading={loading}
                  setLoading={setLoading}
                  hospital_uuid={hospital_uuid}
                />
              </TabPane>
              <TabPane tab="FAQs" key="4">
                <Faqs hospital={true} />
              </TabPane>
              <TabPane tab="Billings" key="5">
                <Billings
                  details={details}
                  setLoading={setLoading}
                  hospital_uuid={hospital_uuid}
                />
              </TabPane>
            </Tabs>
          </Card>
        </div>
      </div>
    </Spin>
  );
};

export default requireAuth(HospitalDetailsScreen, [2]);
