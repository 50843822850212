import {
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Drawer,
  Input,
  notification,
  Row,
  Spin,
  Table,
} from "antd";
import map from "lodash/map";
import { Tabs } from "antd";

import { managersColumn } from "../../constants/table/managersColumn";
import { doctorsColumn } from "../../constants/table/doctorsColumn";
import "./style.scss";
import get from "lodash/get";
import { useEffect, useState } from "react";

import AddManagerForm from "../../components/drawerContent/addManagerForm/AddManagerForm";
import DoctorDetails from "../../components/drawerContent/doctordetails/index";

import ManagerDetails from "../../components/drawerContent/managerdetails/ManagerDetails";
import AddDepartmentForm from "../../components/drawerContent/addDepartmentForm/AddDepartmentForm";
import requireAuth from "../../hocs/requireAuth";
import LinkModal from "../../components/linkModal/LinkModal";
import {
  addDoctorsToDepartment,
  getDepartmentManagers,
  getDepartments,
  listDoctorsDepartment,
  deleteDepartment,
  getDepManagerById,
} from "../../redux/actions/departmentAction";
import ModalPrimary from "../../components/modals/modalPrimary";
import AddDoctorCard from "../../components/hospitalDetails/addDoctorCard";
import {
  ADD_DOCTORS_SUCCESS,
  DELETED_DEPARTMENT_SUCCESS,
} from "../../constants/messages";

const { TabPane } = Tabs;

const DepartmentsScreen = () => {
  const [departments, setDepartments] = useState();
  const [managers, setManagers] = useState();
  const [loading, setLoading] = useState(true);
  const [doctorsLoading, setDoctorsLoading] = useState(true);
  const [managersLoading, setManagersLoading] = useState(true);
  const [currentDepartmentId, setCurrentDepartmentId] = useState();
  const [departmentDoctors, setDepartmentDoctors] = useState([]);
  const [isDepartmentScreen,setIsDepartmentScreen] = useState(true);

  const page = 1;

  const [addDepartmentDrawer, setAddDepartmentDrawer] = useState({
    visible: false,
    edit: false,
    data: null,
  });
  const [doctorDetailsDrawer, setDoctorDetailsDrawer] = useState({
    visible: false,
    edit: false,
    data: null,
  });

  const [managerDetailsDrawer, setManagerDetailsDrawer] = useState({
    visible: false,
    edit: false,
    data: null,
  });
  const [linkModal, setLinkModal] = useState({ data: null, visibility: false });

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [selectedDoctors, setSelectedDoctors] = useState();
  const [managerData,setManagerData] = useState();

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
    addSelectedDoctors();
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedDoctors([]);
  };

  const toggleDoctorDetails = (data = null) => {
    setDoctorDetailsDrawer({
      visible: !get(doctorDetailsDrawer, "visible"),
      edit: !get(doctorDetailsDrawer, "visible"),
      data,
    });
  };

  const toggleManagerDetails = (data = null) => {
    setManagerDetailsDrawer({
      visible: !get(managerDetailsDrawer, "visible"),
      edit: !get(managerDetailsDrawer, "visible"),
      data,
    });
  };

  const getDeptManagerById=(managerData)=>{
    getDepManagerById(managerData?.id,currentDepartmentId).then((result)=>
    setManagerData(result)
    ).catch((err)=>console.log("Err",err));
  }

  const toggleLinkModal = (data = null) => {
    setLinkModal({ data: data, visibility: !get(linkModal, "visibility") });
  };

  const toggleAddDepartment = (data = null) => {
    setAddDepartmentDrawer({
      visible: !get(addDepartmentDrawer, "visible"),
      edit: !get(addDepartmentDrawer, "visible"),
      data,
    });
  };

  const [addManagerDrawer, setAddManagerDrawer] = useState({
    visible: false,
    edit: false,
    data: null,
  });

  const toggleAddManager = (data = null) => {
    setAddManagerDrawer({
      visible: !get(addManagerDrawer, "visible"),
      edit: get(addManagerDrawer, "visible"),
      data,
    });
  };

  const fetchManagers = (currentDepartmentId) => {
    setManagersLoading(true);
    getDepartmentManagers(currentDepartmentId)
      .then((result) => {
        setManagers(result);
        setManagersLoading(false);
      })
      .catch((error) => {
        setManagersLoading(false);
      });
  };

  const fetchDepartment = () => {
    setLoading(true);
    getDepartments()
      .then((result) => {
        setLoading(false);
        setDepartments(result);
        setCurrentDepartmentId(get(result[0], "id"));
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const fetchDoctors = () => {
    if (currentDepartmentId) {
      setDoctorsLoading(true);
      listDoctorsDepartment({ page, currentDepartmentId })
        .then((result) => {
          // setLoading(false);
          setDepartmentDoctors(result.data);
          setDoctorsLoading(false);
        })
        .catch((error) => {
          setDoctorsLoading(false);
        });
    }
  };

  const addSelectedDoctors = () => {
    const values = { uuid: currentDepartmentId, doctor_uuid: selectedDoctors };
    addDoctorsToDepartment(values)
      .then((result) => {
        console.log(result);
        notification.success({
          message: ADD_DOCTORS_SUCCESS,
        });
        fetchDoctors();
      })
      .catch((error) => {});
  };

  useEffect(() => {
    fetchDepartment();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchDoctors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDepartmentId]);

  const handleDeptSubTab = (key) => {
    if (key === "2") {
      fetchManagers(currentDepartmentId);
    }
    if (key === "1") {
      console.log(
        "Values of currentDepartmentID in start",
        currentDepartmentId
      );
      fetchDoctors();
    }
  };

  const handleDeptTab = (key) => {
    const index = parseInt(key);
    setCurrentDepartmentId(get(departments[index], "id"));
  };
  const removeDepartment = (department) => {
    deleteDepartment(department?.id)
      .then((result) => {
        console.log(result);
        notification.success({
          message: DELETED_DEPARTMENT_SUCCESS,
        });
        fetchDepartment();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Spin spinning={loading}>
      <div className="departments-screen">
        <Row justify="space-between" style={{ alignItems: "baseline" }}>
          <Col>
            <div className="screen-title">Departments</div>
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={() => toggleAddDepartment()}
              icon={<PlusOutlined />}
            >
              Add department
            </Button>
          </Col>
        </Row>
        <div className="departments-container">
          <Tabs
            type="card"
            className="departments-tabs"
            onChange={(key) => handleDeptTab(key)}
          >
            {map(departments, (department, i) => (
              <TabPane
                tab={get(department, "name")}
                key={i}
                className="departments-tabpane"
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <div className="department-name">
                      {get(department, "name")}
                    </div>
                    <div>{get(department, "email")}</div>
                    <div>
                      +{get(department, "contact_code")}{" "}
                      {get(department, "contact_number")}
                    </div>
                  </div>
                  <div>
                    <Button
                      type="danger"
                      onClick={() => removeDepartment(department)}
                      icon={<DeleteOutlined />}
                      style={{ marginRight: "12px" }}
                    >
                    </Button>
                  </div>
                </div>
                <Divider />
                <Tabs
                  defaultActiveKey="1"
                  className="department-sub-tabs"
                  onChange={(key) => handleDeptSubTab(key)}
                >
                  <TabPane tab="Doctors" key="1">
                    <Spin spinning={doctorsLoading}>
                      <Row className="search-add-doctors">
                        <Col span={18} push={6} style={{ textAlign: "end" }}>
                          <Button
                            type="primary"
                            onClick={() => showModal()}
                            icon={<PlusOutlined />}
                          >
                            Add Doctor
                          </Button>
                        </Col>
                        <Col span={6} pull={18}>
                          <Input
                            placeholder="Doctor, specialty, hospital or treatment"
                            allowClear
                            size="large"
                            prefix={
                              <SearchOutlined style={{ fontSize: "12px" }} />
                            }
                          />
                        </Col>
                      </Row>
                    </Spin>
                    <Table
                      columns={doctorsColumn({
                        toggleLinkModal,
                        toggleDoctorDetails,
                        isDepartmentScreen
                      })}
                      scroll={{ x: true }}
                      dataSource={departmentDoctors}
                      pagination={{ position: ["bottomLeft"] }}
                    />
                  </TabPane>
                  <TabPane tab="Department managers" key="2">
                    <Spin spinning={managersLoading}>
                      <Row className="search-add-doctors">
                        <Col span={18} push={6} style={{ textAlign: "end" }}>
                          <Button
                            type="primary"
                            onClick={() => toggleAddManager()}
                            icon={<PlusOutlined />}
                          >
                            Add Manager
                          </Button>
                        </Col>
                      </Row>
                    </Spin>
                    <Table
                      columns={managersColumn({ toggleManagerDetails,getDeptManagerById })}
                      dataSource={managers}
                      pagination={false}
                      scroll={{ x: true }}
                    />
                  </TabPane>
                </Tabs>
              </TabPane>
            ))}
          </Tabs>
          <Drawer
            className="main-drawer"
            destroyOnClose
            width={350}
            closable={false}
            onClose={() => toggleAddDepartment(null)}
            visible={get(addDepartmentDrawer, "visible")}
          >
            <AddDepartmentForm
              toggleAddDepartment={toggleAddDepartment}
              fetchDepartment={fetchDepartment}
            />
          </Drawer>
          <Drawer
            className="main-drawer"
            destroyOnClose
            width={350}
            closable={false}
            onClose={() => toggleAddManager(null)}
            visible={get(addManagerDrawer, "visible")}
          >
            <AddManagerForm
              toggleAddManager={toggleAddManager}
              currentDepartmentId={currentDepartmentId}
            />
          </Drawer>
          <Drawer
            className="main-drawer hospital-details-drawer"
            destroyOnClose
            closable={true}
            onClose={() => toggleDoctorDetails(null)}
            visible={get(doctorDetailsDrawer, "visible")}
          >
            <DoctorDetails toggleDoctorDetails={toggleDoctorDetails} />
          </Drawer>
          <Drawer
            className="main-drawer"
            destroyOnClose
            width={350}
            closable={true}
            onClose={() => toggleManagerDetails(null)}
            visible={get(managerDetailsDrawer, "visible")}
          >
            <ManagerDetails toggleManagerDetails={toggleManagerDetails} managerData={managerData} />
          </Drawer>
          <ModalPrimary
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Done"
            destroyOnClose="true"
          >
            <AddDoctorCard
              departmentDoctors={departmentDoctors}
              selectedDoctors={selectedDoctors}
              setSelectedDoctors={setSelectedDoctors}
            />
          </ModalPrimary>
          <LinkModal linkModal={linkModal} toggleLinkModal={toggleLinkModal} />
        </div>
      </div>
    </Spin>
  );
};

export default requireAuth(DepartmentsScreen, [2]);
