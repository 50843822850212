import { Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import './style.scss';

const SimpleModal = ({ onOk, onCancel, okText, text, visible }) => {
  return (
    <Modal
      centered={true}
      closable={false}
      visible={visible}
      footer={null}
      width={300}
      className="simple-modal"
    >
      <div className="modal-children">
        <div className="title">{text}</div>
        <div className="buttons-gap">
        <Button type="text" onClick={() => onCancel()}>
          Cancel
        </Button>
        <Button type="primary" onClick={() => onOk()}>
          Yes
        </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SimpleModal;
