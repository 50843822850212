import { UserOutlined,DeleteOutlined } from '@ant-design/icons';
import { Col, Row, Space,Button } from 'antd';
import get from 'lodash/get';
import Avatar from 'antd/lib/avatar/avatar';

export const hospitaladminsColumn = (props) => [
  {
    title: 'Hospital Admin Name',
    dataIndex: 'name',
    key: 'name',
    render: (name, record) => (
      <Row>
        <Space>
          <Col>
            {get(record, 'image') ? (
              <Avatar size={48} src={get(record, 'image')} />
            ) : (
              <Avatar size={48} icon={<UserOutlined />} />
            )}
          </Col>
          <Col>
            <div className="medium-bold">{name}</div>
          </Col>
        </Space>
      </Row>
    ),
  },

  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {},
  {
    title: 'Contact Number',
    dataIndex: 'contact_number',
    key: 'contact_number',
    render: (contact_number, record) =>
      `+${get(record, 'contact_code')}-${contact_number}`,
  },
  {
    title : 'Action',
    key : 'action',
    render :(record)=>{
      return(
        <div>
           <Button
        type="danger"
        onClick={() => props.removeAdmin(record)}
        icon={<DeleteOutlined />}
      >
      </Button>
        </div>
      )
    }
  }
];
