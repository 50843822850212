import { Button, Col, Divider, Input, Row, Spin, Form, notification } from 'antd';
import forgot_password_illus from '../../assets/images/forgot_password_illus.svg';
import { useHistory } from 'react-router-dom';
import { CREATE_ACCOUNT, LOGIN } from '../../constants/routes';
import { useState } from 'react';
import { validateMessages } from '../../constants/validation';
import { forgotPassword } from '../../redux/actions/staffAction';
import get from 'lodash/get';

const ForgotPasswordScreen = () => {
  let history = useHistory();

  const [loading, setLoading] = useState(false);
  const onFinish = (values) => {
    setLoading(true);
    forgotPassword(values)
      .then((result) => {
        setLoading(false);
        notification.success({
          message: (`A recovery link has been sent to ${get(values, 'email')}`),
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleSignup = () => {
    history.push(CREATE_ACCOUNT);
  };
  const handleLogin = () => {
    history.push(LOGIN);
  };

  return (
    <Spin spinning={loading}>
      <div className="dashboard-auth-container">
        <div className="dashboard-inner-container">
          <Row>
            <Col xs={{span:24,order:2}} md={{span:12,order: 1}} className="form">
              <div className="title">Forgot Password?</div>
              <div className="subtitle">
                Don’t worry, it happens to the best of us.
              </div>

              <Form
                name="basic"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                validateMessages={validateMessages}
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: 'email',
                    },
                  ]}
                >
                  <Input className="custom-input" />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="full-button recover-btn"
                  >
                    Recover Password
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    className="full-button"
                    onClick={handleLogin}
                  >
                    Login
                  </Button>
                </Form.Item>
              </Form>
              <Divider />
              <p>
                <span className="grey-info">Don't have an account?</span> &nbsp;
                <span
                  className="purple-info"
                  onClick={handleSignup}
                  style={{ cursor: 'pointer', textDecoration: "underline" }}
                >
                  Signup
                </span>
              </p>
            </Col>
            <Col xs={{span:24,order:1}} md={{span:12,order:2}} className='login-img'>
              <img src={forgot_password_illus} alt="" />
            </Col>
          </Row>
        </div>
      </div>
    </Spin>
  );
};
export default ForgotPasswordScreen;
