import Modal from 'antd/lib/modal/Modal';
import done from '../../../assets/icons/done.svg';
import './style.scss';

const DoneModal = ({ message, visible, toggleDone }) => {
  return (
    <Modal
      footer={null}
      visible={visible}
      centered
      onCancel={() => toggleDone()}
      className="done-modal"
      width={400}
    >
      <div className="modal-content">
        <img src={done} alt="" />
        <div className="green-text">{message}</div>
      </div>
    </Modal>
  );
};

export default DoneModal;
