import { Col, Divider, Empty, Row, Space, Spin } from 'antd';
import './style.scss';
import appointments_black from '../../../assets/icons/appointments_black.svg';
import Avatar from 'antd/lib/avatar/avatar';
import { UserOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import map from 'lodash/map';
import get from 'lodash/get';
import moment from 'moment';

import isNaN from 'lodash/isNaN';

import EditDetails from './editDetails/EditDetails';
import ViewDetails from './viewDetails/ViewDetails';
import { useSelector } from 'react-redux';
import {
  fetchAppointments,
  singleDoctor,
} from '../../../redux/actions/doctorAction';
import AvailabilityDetails from './availabilityDetails/AvaiabilityDetails';
import CustomTag from '../../tag/CustomTag';
import { percentUtility } from '../../../utilities/helpers';
import { useHistory } from 'react-router';
import { YEAR_MONTH_DAY } from '../../../constants/constantsDateFormats';

const DoctorDetails = ({ doctor_details, edit }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [editDetails, setEditDetails] = useState(edit);
  const [imageUser, setUserImage] = useState();

  const [details, setDetails] = useState(doctor_details);
  const role = useSelector((state) => get(state, 'auth.roles'));
  const doctorId = useSelector((state) => get(state, 'auth.id'));
  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    if (role.includes(3)) {
      setLoading(true);
      singleDoctor(doctorId)
        .then((result) => {
          setDetails(result);
          setUserImage(result?.image);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
    getAppointments();
  }, [doctorId, role]);

  const getAppointments = (
    page = 1,
    fromDate = moment().format(YEAR_MONTH_DAY),
    toDate = moment().format(YEAR_MONTH_DAY),
    status = null,
    query = '',
  ) => {
    setLoading(true);
    fetchAppointments(fromDate, toDate, status, page, query)
      .then((result) => {
        setLoading(false);
        setAppointments(get(result, 'data').slice(0, 5));
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleViewAll = () => {
    history.push('/appointments');
  };

  return (
    <Spin spinning={loading}>
      <Row gutter={16} className="details">
        <Col span={role.includes(2) ? 12 : 24} className="doctor-details">
          {editDetails ? (
            <EditDetails
              setEditDetails={setEditDetails}
              details={details}
              editForm={true}
              setUserImage={setUserImage}
              imageUser={imageUser}
            />
          ) : (
            <ViewDetails setEditDetails={setEditDetails} details={details} />
          )}
        </Col>
        {role.includes(2) && (
          <Col span={12}>
            <AvailabilityDetails doctor_uuid={get(doctor_details, 'id')} />
            <div className="doctor-appointments">
              <Row gutter={16}>
                <Col>
                  <img src={appointments_black} alt="" height="14px" />
                </Col>
                <Col>
                  <p className="medium-bold">Appointments</p>
                </Col>
              </Row>
              <Divider />
              <Row justify="space-between">
                <Col>
                  <span className="large">
                    {' '}
                    {get(
                      percentUtility(get(details, 'appointment_status')),
                      'total',
                    )}
                  </span>
                  <p className="x-small-grey">Appointments</p>
                </Col>
                <Col>
                  <span className="large">
                    {isNaN(
                      get(
                        percentUtility(get(details, 'appointment_status')),
                        'done',
                      ),
                    )
                      ? '0'
                      : get(
                          percentUtility(get(details, 'appointment_status')),
                          'done',
                        )}
                  </span>
                  <span className="green-highlight">
                    {' '}
                    {isNaN(
                      get(
                        percentUtility(get(details, 'appointment_status')),
                        'donePercent',
                      ),
                    )
                      ? '(0%)'
                      : `(${get(
                          percentUtility(get(details, 'appointment_status')),
                          'donePercent',
                        )}%)`}
                  </span>
                  <p className="x-small-grey">Fulfilled</p>
                </Col>
                <Col>
                  <span className="large">
                    {' '}
                    {isNaN(
                      get(
                        percentUtility(get(details, 'appointment_status')),
                        'cancel',
                      ),
                    )
                      ? '0'
                      : get(
                          percentUtility(get(details, 'appointment_status')),
                          'cancel',
                        )}
                  </span>
                  <span className="red-highlight">
                    {' '}
                    {isNaN(
                      get(
                        percentUtility(get(details, 'appointment_status')),
                        'cancelPercent',
                      ),
                    )
                      ? '(0%)'
                      : `(${get(
                          percentUtility(get(details, 'appointment_status')),
                          'cancelPercent',
                        )}%)`}
                  </span>
                  <p className="x-small-grey">Cancelled</p>
                </Col>
                <Col>
                  <span className="large">
                    {' '}
                    {isNaN(
                      get(
                        percentUtility(get(details, 'appointment_status')),
                        'noshow',
                      ),
                    )
                      ? '0'
                      : get(
                          percentUtility(get(details, 'appointment_status')),
                          'noshow',
                        )}
                  </span>
                  <span className="orange-highlight">
                    {' '}
                    {isNaN(
                      get(
                        percentUtility(get(details, 'appointment_status')),
                        'noshowPercent',
                      ),
                    )
                      ? '(0%)'
                      : `(${get(
                          percentUtility(get(details, 'appointment_status')),
                          'noshowPercent',
                        )}%)`}
                  </span>
                  <p className="x-small-grey">No Show</p>
                </Col>
              </Row>
              <Divider />
              {appointments.length > 0 ? (
                <>
                  {' '}
                  {map(appointments, (appointment, i) => (
                    <Row justify="space-between" className="single-appointment">
                      <Col>
                        <Row>
                          <Space>
                            <Col>
                              <Avatar size={48} icon={<UserOutlined />} />
                            </Col>
                            <Col>
                              <div>
                                {get(appointment, 'patient.title')}{' '}
                                {get(appointment, 'patient.name')}
                              </div>
                              <div className="purple-highlight">
                                {get(appointment, 'appointment_id')}
                              </div>
                            </Col>
                          </Space>
                        </Row>
                      </Col>
                      <Col align="middle">
                        <Row gutter={16}>
                          <Col>
                            {' '}
                            {moment(get(appointment, 'date')).format('MMM Do')}
                          </Col>
                          <Col> {get(appointment, 'time')}</Col>
                        </Row>

                        <CustomTag status={get(appointment, 'status.name')} />
                      </Col>
                    </Row>
                  ))}
                  <Divider />
                  <div className="appointments-link" onClick={handleViewAll}>
                    View All
                  </div>
                </>
              ) : (
                <Empty description="No appointments for today" />
              )}
            </div>
          </Col>
        )}
      </Row>
    </Spin>
  );
};
export default DoctorDetails;
