import { UserOutlined } from '@ant-design/icons';
import { Col, Row, Space } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';

const PatientProfile = ({ patient_id, patient_name }) => {
  return (
    <Row>
      <Space>
        <Col>
          <Avatar size={48} icon={<UserOutlined />} />
        </Col>
        <Col>
          <div>{patient_name}</div>
          <div className="purple-highlight">{patient_id}</div>
        </Col>
      </Space>
    </Row>
  );
};

export default PatientProfile;
