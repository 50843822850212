
import "./style.scss";

const PatientAnswersDetails = ({
  appointment_details,
  questionsData
}) => {
  const appointmentPatientAnswers = appointment_details?.patient_answers
    ? JSON.parse(appointment_details?.patient_answers)
    : {};
  return (
    <div style={{ marginTop: "18px" }}>
      {questionsData?.map((questionData) => {
        return (
          <>
            {Object.keys(appointmentPatientAnswers)?.map((patientAnswer) => {
              if (patientAnswer === "IMAGE") {
                return appointmentPatientAnswers[patientAnswer]?.map(
                  (everyAnswer) => {
                    if (
                      Object.keys(everyAnswer)?.includes(
                        questionData?.question?.id.toString()
                      )
                    ) {
                      return (
                        <>
                          <h4>
                            question:<b> {questionData?.question?.question}</b>
                          </h4>
                          <img
                            src={everyAnswer[questionData?.question?.id]}
                            alt="avatar"
                            style={{ width: "100%" }}
                          />
                        </>
                      );
                    }
                  }
                );
              }
              if (patientAnswer === "FILE") {
                return appointmentPatientAnswers[patientAnswer]?.map(
                  (everyAnswer) => {
                    if (
                      Object.keys(everyAnswer)?.includes(
                        questionData?.question?.id.toString()
                      )
                    ) {
                      return (
                        <>
                          <h4>
                            question:<b> {questionData?.question?.question}</b>
                          </h4>
                          <a
                            href={everyAnswer?.[questionData?.question?.id]}
                            target="_blank"
                          >
                            {" "}
                            <strong>Open File</strong>
                          </a>
                        </>
                      );
                    }
                  }
                );
              }
              return appointmentPatientAnswers[patientAnswer]?.map(
                (everyAnswer) => {
                  if (
                    Object.keys(everyAnswer)?.includes(
                      questionData?.question?.id.toString()
                    )
                  ) {
                    return (
                      <>
                        <h4>
                          question:<b> {questionData?.question?.question}</b>
                        </h4>
                        <p>{everyAnswer[questionData?.question?.id]}</p>
                      </>
                    );
                  } else {
                  }
                }
              );
            })}
          </>
        );
      })}
    </div>
  );
};

export default PatientAnswersDetails;
