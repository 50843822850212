import { Button, Result } from 'antd';
import { useHistory } from 'react-router-dom';
import { LOGIN } from '../../constants/routes';
import './style.scss';
const CustomResult = ({ title, subTitle, status,fromstaff }) => {
  const history = useHistory();

  const handleLogin = () => {
    return history.replace(LOGIN);
  };
  const handleError = () => {
    return history.replace(LOGIN);
  };

  return (
    <div className="custom-result">
      <Result
        status={status}
        title={title}
        subTitle={subTitle}
        extra={status === 'success' ? 
            fromstaff !== "Staff" ? [
                <Button type="primary" onClick={handleLogin}>
                  Login
                </Button>,
              ]
              :
              [
                <Button type="primary" onClick={handleError}>
                  Go to website
                </Button>,
              ]

            : [
                <Button type="primary" onClick={handleError}>
                  Go to website
                </Button>,
              ]
        }
      />
    </div>
  );
};

export default CustomResult;
