import { axiosInstance } from '../../utilities/configureAxios';
import * as APIS from '../../constants/apiEndpoints';

export const contactDetails = (values,image) => {
  let formData = new FormData();
  formData.append("image",image)
  let url = APIS.POST_CONTACT_US
    return axiosInstance.post(url,values,formData).then((result) => {
      return result;
    });
  };