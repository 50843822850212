/**
 * Constants
 */
import * as APIS from '../../constants/apiEndpoints';
import { axiosInstance } from '../../utilities/configureAxios';
import {replaceMultiple } from '../../utilities/helpers';

export const addDepartment = (credentials) => {
  return axiosInstance.post(APIS.DEPARTMENTS, credentials).then((result) => {
    return result;
  });
};

export const getDepartments = () => {
  return axiosInstance.get(APIS.DEPARTMENTS).then((result) => {
    return result;
  });
};

export const getDepartmentManagers = (department_id) => {
  return axiosInstance
    .get(APIS.GET_DEPT_MANAGERS.replace('{department_id}', department_id))
    .then((result) => {
      return result;
    });
};

export const getDepManagerById = (user_id,department_id) => {
  let url = replaceMultiple(APIS.GET_DEPT_MANAGER_BY_ID, {
    '{user_id}': user_id,
    '{department_id}': department_id,
  });
  return axiosInstance.get(url).then((result) => {
    return result;
  });
};

export const listDoctorsDepartment = ({ page, currentDepartmentId }) => {
  let url1 = APIS.LISTDOCTORSDEPARTMENT.replace('{page}', page);
  let url = url1.replace('{department_uuid}', currentDepartmentId);

  return axiosInstance.get(url).then((result) => {
    return result;
  });
};

export const addDoctorsToDepartment = (values) => {
  return axiosInstance.put(APIS.ADDDOCTORS, values).then((result) => {
    return result;
  });
};

export const deleteDepartment =(id) =>{
  let url = APIS.DELETE_DEPARTMENT.replace('{uuid}',id);
  return axiosInstance.delete(url).then((result)=>{
    return result;
  })
}