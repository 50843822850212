export const SEND_INVITE_STATUS = 'Invite sent!';
export const UPDATE_WORKING_HOURS_STATUS = 'Working hours updated!';
export const SUCCESSFUL_REGISTRATION = 'Registered';
export const REGISTRATION_SUBTITLE =
  'You have been successfully registered for ';
export const INVALID_LINK = 'Invalid Link';
export const INVALID_LINK_SUBTITLE =
  'The link you have entered is invalid or expired';
export const ADD_DEPARTMENT_SUCCESS = ' deparment added successfully!';
export const INVITE_MANAGER_SUCCESS = 'Invite sent!';
export const INVITE_ADMIN_SUCCESS = 'Invite sent Successfully';
export const MARK_UNAVIALABILITY_SUCCESS = 'Unavailabilty marked successfully';
export const MARK_AVIALABILITY_SUCCESS = 'Availability marked successfully';
export const ADD_DOCTORS_SUCCESS =
  'Doctors added successfully to the deparment';
export const REGISTRATION_SUBTITLE_STAFF =
  'You have been successfully registered';
export const RESCHEDULE_APPOINTMENT = 'Appointment rescheduled to ';
export const DETAILS_UPDATED = 'Details Updated!';
export const DELETED_COMMENT_SUCCESS = "Deleted comment successfully";
export const DELETED_DOCTOR_SUCCESS = "Deleted Doctor successfully";
export const DELETED_CITY_SUCCESS = "Deleted City successfully";
export const DELETED_DEPARTMENT_SUCCESS = "Deleted Department successfully";
export const DELETED_HOSPITAL_ADMIN_SUCCESS = "Deleted Hospital Admin successfully";
export const DELETED_ADS = "Deleted Ads successfully";
export const DELETED_TV_ADS = "Deleted Tv Ads successfully";
export const DELETED_TV = "Deleted Tv successfully";
export const DELETED_AD = "Deleted Ad successfully";
export const DELETED_NOTIFICATIONS = "Deleted Notification successfully";
export const DELETED_QUESTION = "Deleted Question successfully";
export const SUCCESS_INCLUDE_QUESTIONS = "Added Questions successfully";
export const UPDATED_DOCTOR_DELAY = "Updated Doctor Delay successfully"
export const UPDATE_APPOINTMENT_STATUS = "Update appointment status successfully "
