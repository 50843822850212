import { CloseOutlined } from "@ant-design/icons";
import { Col, Row, Input, Space } from "antd";
import get from "lodash/get";
import { Button, Form, Spin, List, Divider, message, Checkbox,notification } from "antd";
import moment from "moment";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import SingleImageUpload from "../../mediaUpload/SingleImageUpload";
import {
  updateHospitalTv,
  fetchHospitalTvById,
  postHospitalTvs,
} from "../../../redux/actions/hospitalAction";

// import './style.scss';

const AddTvDetails = ({ closeOpenTv,getTvs,tvid,setTvId }) => {
  const [form] = useForm();
  const [editAd, setEditAd] = useState(null);
  const [is_active, setIs_active] = useState(false);
  const [tvlogo, setTvlogo] = useState();
  const [componentDisabled, setComponentDisabled] = useState();
  const [loading, setLoading] = useState(false);

  const onUploadLogoDone = (imageResponse) => {
    setTvlogo(imageResponse);
  };

  const onFinish = (values) => {
    const data = { ...values, tv_image: tvlogo?.thumbnail_key };
    postHospitalTvs(data)
      .then((result) => {
        setLoading(false);
        notification.success({
          message: "Created Tv Successfully",
        });
        closeOpenTv();
        getTvs();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(()=>{
    fetchHospitalTvById(tvid).then((res) => {
      setEditAd(res);
      setTvlogo({thumbnail_key:res?.tv_image?.key,thumbnail_url:res?.tv_image?.url});
      form.setFieldsValue(res);
  });
  },[tvid])

  const onUpdateAds = (values) => {
    setLoading(true);
    let data = {...values,image:tvlogo?.thumbnail_key};
    updateHospitalTv(tvid,data).then((res)=>{
      setLoading(false);
      notification.success({
        message: "Updated Tv Successfully",
      });
      setTvId(null);
      closeOpenTv();
      getTvs();
    })


  };
  return (
    <div className="add-drawer-content">
      <Row justify="space-between">
        <Col className="drawer-title">TV</Col>
        <Col>
          <CloseOutlined onClick={closeOpenTv} />
        </Col>
      </Row>

      <Form
        name="dynamic_form_nest_item"
        className="faqs_form"
        layout="vertical"
        onFinish={editAd?.id ? onUpdateAds : onFinish}
        autoComplete="off"
        form={form}
      >
        <Row
          style={{ display: "flex", marginBottom: 8 }}
          align="baseline"
          justify="space-between"
        >
          <Col span={22}>
            <Form.Item
              name={"tv_name"}
              label="Tv Name or Number"
              rules={[
                {
                  required: true,
                  message: "Please enter the Tv Name or Number",
                },
              ]}
            >
              <Input
                // defaultValue={editAd?.text || ""}
                placeholder="Tv Name or Number"
                className="custom-input"
              />
            </Form.Item>
            <Form.Item name="image" label=" Image ">
              <SingleImageUpload
                className="user-image"
                onUploadDone={onUploadLogoDone}
                imageUrl={get(tvlogo, "thumbnail_url")}
                setImageUser={setTvlogo}
              />
            </Form.Item>
            <Form.Item
              name={"is_active"}
              label=" is Active "
              // initialValue={true}
              valuePropName="checked"
            >
              <Checkbox
                checked={is_active}
                // checked={componentDisabled}
                onChange={(e) => setComponentDisabled(e.target.checked)}
              ></Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginRight: "8px" }}
            loading={loading}
          >
            {editAd?.id ? "Update" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default AddTvDetails;
