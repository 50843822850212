import React from "react";
import {
  DatePicker,
  Row,
  Spin,
  Form,
  Button,
  Empty,
  message,
  Input,
  Select,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  postClaim,
} from "../../redux/actions/claimAction";
const { Option } = Select;

function ViewClaimDetails({ appointment_details }) {
  const dateFormat = "YYYY-MM-DD";
  const [loading, setLoading] = useState(false);
  const onFinish = (values) => {
    const formValues = {
      ...values,
      appointment_id: appointment_details?.appointment_id,
    };
    setLoading(true);
    postClaim(formValues)
      .then((result) => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <Spin spinning={loading}>
      <div className="add-drawer-content">
        <h1>Claim Details</h1>
        <div className="add-drawer-form">
          <Form
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={appointment_details?.claim}
          >
            <Form.Item label="Type : " name="type">
              <p>{appointment_details?.claim?.type}</p>
            </Form.Item>
            <Form.Item label="Service Type : " name="service_type">
              <p>{appointment_details?.claim?.service_type}</p>
            </Form.Item>
            <Form.List name="items">
              {(items, { add, remove }) => (
                <div>
                  {items.map(({ name, fieldKey, key }, index) => {
                    return (
                      <>
                        <Form.Item
                          name={[index, "itemNumber"]}
                          label="itemNumber"
                        >
                          <div style={{ display: "flex" }}>
                            <p>
                              {
                                appointment_details?.claim?.items[index]
                                  ?.itemNumber
                              }
                            </p>
                          </div>
                        </Form.Item>
                        <Form.Item
                          name={[index, "amount"]}
                          label="Amount"
                        >
                          <div style={{ display: "flex" }}>
                            <p>
                              {appointment_details?.claim?.items[index]?.amount}
                            </p>
                          </div>
                        </Form.Item>
                      </>
                    );
                  })}
                </div>
              )}
            </Form.List>
            <Form.Item label="Recieved Amount" name="recieved_amount">
              <p>{appointment_details?.claim?.recieved_amount}</p>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Spin>
  );
}

export default ViewClaimDetails;
