import { CloseOutlined } from '@ant-design/icons';
import { Col, Input, Row, Tag, Image } from 'antd';
import get from 'lodash/get';
import map from 'lodash/map';
import AddressHelper from '../../../utilities/addressHelper/AddressHelper';
import { workingHours } from '../../../utilities/workingHours';
import './style.scss';

const HospitalDetails = ({ toggleHospitalDetails, details }) => {
  console.log('Details>>>', details);

  return (
    <div className="details-drawer-content">
      <Row justify="space-between">
        <Col className="details-drawer-title">Hospital Details</Col>
        <Col>
          <CloseOutlined onClick={toggleHospitalDetails} />
        </Col>
      </Row>
      <Row gutter={[16, 40]}>
        <Col span={8}>
          <div className="details-label">Hospital Name</div>
          <div className="medium">{get(details, 'name')}</div>
        </Col>
        <Col span={8}>
          <div className="details-label">Specialization</div>
          {map(get(details, 'specialisations'), (specilisation, i) => (
            <Tag color="magenta">{get(specilisation, 'name')}</Tag>
          ))}
        </Col>
        <Col span={8}>
          <div className="details-label">About</div>
          <div className="medium">{get(details, 'description')}</div>
        </Col>

        <Col span={8}>
          <div className="details-label">Hospital Address</div>
          <div className="medium">
            <AddressHelper details={details} />
          </div>
        </Col>
        <Col span={8}>
          <div className="details-label">Contact Number</div>
          <div className="medium">
            +{get(details, 'contact_code')} {get(details, 'contact_number')}
          </div>
        </Col>
        <Col span={8}>
          <div className="details-label">Fax Number</div>
          <div className="medium">{get(details, 'fax_number')}</div>
        </Col>

        <Col span={8}>
          <div className="details-label">Website url</div>
          <div className="medium">{get(details, 'website')}</div>
        </Col>
        <Col span={8}>
          <div className="details-label">Number of Doctors</div>
          <div className="medium">{get(details, 'doctor_count')}</div>
        </Col>
        <Col span={8}>
          <div className="details-label">Billing Method</div>
          <div className="medium">{get(details, 'billing_method.name')}</div>
        </Col>

        <Col span={8}>
          <div className="details-label">Open Hours</div>
          {map(workingHours(get(details, 'working_hours')), (single) => (
            <div className="medium">
              <Row>
                <Col span={6}>{get(single, 'dayName')}</Col>
                <Col>
                  <span>{get(single, 'from_time')} </span>
                  <span>to </span>
                  <span>{get(single, 'to_time')}</span>
                </Col>
              </Row>
            </div>
          ))}
          <div className="medium"></div>
        </Col>
        <Col span={8}>
          <div className="details-label">Sponsorship required</div>
          <div className="medium">
            {get(details, 'sponsership_required') ? 'Yes' : 'No'}
          </div>
        </Col>
      </Row>
      <div className="sub-title">Branding</div>
      <Row gutter={[16, 40]}>
        <Col span={8}>
          <div className="details-label">Hospital Logo</div>
          <div className="hospital-logo">
            {details?.logo_url?.url ? 
            <Image src={get(details?.logo_url, 'url')} height="100" width="100" />
            : null
}
          </div>
        </Col>
        <Col span={8}>
          <div className="details-label">Brand colors</div>
          <Row>
            <Col span={12}>
              <div
                className="color-div"
                style={{ backgroundColor: get(details, 'brand_color.primary') }}
              />
            </Col>
            <Col span={12}>
              <div
                className="color-div"
                style={{
                  backgroundColor: get(details, 'brand_color.secondary'),
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12} className="small">
              Primary Color
            </Col>
            <Col span={12} className="small">
              Secondary Color
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <div className="details-label">Custom Url</div>
          <Input.Group compact>
            <Input style={{ width: '25%' }} value="https://" />
            <Input
              style={{ width: '75%' }}
              value={`smartmeet.com/${get(details, 'slug')}`}
            />
          </Input.Group>
        </Col>
      </Row>
    </div>
  );
};

export default HospitalDetails;
