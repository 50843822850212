import React from "react";
import { Space } from "antd";
import get from "lodash/get";
import SingleImageUpload from "../../components/mediaUpload/SingleImageUpload";

const ViewContactDetails = ({ contactUsData }) => {
  return (
    <>
      <Space size="large" direction="vertical">
        <div>
          <div className="details-label">Name</div>
          <div>{get(contactUsData, "name")}</div>
        </div>
        <div>
          <div className="details-label">Email Address</div>
          <div>{get(contactUsData, "email")}</div>
        </div>
        <div>
          <div className="details-label">Contact number</div>
          <div>{get(contactUsData, "phone")}</div>
        </div>
        <div>
          <div className="details-label">Comments</div>
          <div>{get(contactUsData, "comments")}</div>
        </div>
        <div>
          <div className="details-label">Created By </div>
          <div>{get(contactUsData?.user, "name")}</div>
        </div>
        <div>
          <div className="details-label">Reference Image </div>
          <div>
            <SingleImageUpload
              className="user-image"
              imageUrl={get(contactUsData, "image_url")}
            />
          </div>
        </div>
      </Space>
    </>
  );
};
export default ViewContactDetails;
