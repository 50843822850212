import React, { useState } from "react";
import { TimePicker, Form, notification } from "antd";
import { Button } from "antd";
import { updateDoctorDelay } from "../../redux/actions/doctorAction";
import { UPDATED_DOCTOR_DELAY } from "../../constants/messages";
import moment from "moment";

const ShowDelayScreen = ({
  doctor_uuid,
  closeDelayModal,
}) => {
  const [loading, setLoading] = useState(false);
  const [duration, setDuration] = useState(0);
  const [startTime, setStartTime] = useState(moment());
  const [endTime, setEndTime] = useState(moment());

  const handleStartTimeChange = (time) => {
    setStartTime(time);
    if (time && endTime) {
      calculateDuration(time, endTime);
    }
  };

  const handleEndTimeChange = (time) => {
    setEndTime(time);
    if (startTime && time) {
      calculateDuration(startTime, time);
    }
  };

  const calculateDuration = (start, end) => {
    const durationInMinutes = end.diff(start, 'minutes');
    setDuration(durationInMinutes > 0 ? durationInMinutes : 0); // Ensure duration is non-negative
  };

  const getFormattedRange = (currentTime) => {
    return currentTime.format("HH:mm");
  };

  const submitAvailability = () => {
    setLoading(true);
    const values = {
      delay: duration,
      delay_time: `${getFormattedRange(startTime)} - ${getFormattedRange(endTime)}`,
    };

    updateDoctorDelay(doctor_uuid, values)
      .then(() => {
        setLoading(false);
        notification.success({
          message: UPDATED_DOCTOR_DELAY,
        });
        closeDelayModal();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div style={{ marginTop: "24px" }}>
      <h1>Select Appointment Delay</h1>
      <div style={{ display: "flex" }}>
        <div className="form-label">
          Select Start Time
          <div>
            <TimePicker
              value={startTime}
              onChange={handleStartTimeChange}
              format="HH:mm"
              style={{ marginBottom: "12px" }}
            />
          </div>
        </div>
        <div className="form-label" style={{ marginLeft: "12px" }}>
          Select End Time
          <div>
            <TimePicker
              value={endTime}
              onChange={handleEndTimeChange}
              format="HH:mm"
              style={{ marginBottom: "12px" }}
            />
          </div>
        </div>
      </div>
      <div>
        <Button htmlType="submit" type="primary" loading={loading} onClick={submitAvailability}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default ShowDelayScreen;
