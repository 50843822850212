import {
  Button,
  Col,
  Radio,
  Row,
  Space,
  notification,
  Spin,
  Select,
  Typography,
} from "antd";
import get from "lodash/get";
import SelectWorkingHours from "../selectWorkingHours";
import { useState, useEffect } from "react";
import {
  updateWorkingHours,
  getWorkingHours,
} from "../../redux/actions/doctorAction";
import { UPDATE_WORKING_HOURS_STATUS } from "../../constants/messages";
import { getTimeHelper } from "../../utilities/selectTimeHelper/getTimeHelper";
import { putTimeHelper } from "../../utilities/selectTimeHelper/putTimeHelper";
import { CloseOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { OptGroup } from "rc-select";
const { Option } = Select;

const AvailabilityForm = ({
  onSuccess,
  edit,
  toggleDrawer,
  selectedDoctorId,
  fetchWorkingHours,
  setSrawer,
  drawer,
}) => {
  const [loading, setLoading] = useState(false);
  const [duration, setDuration] = useState("15");
  const [openHours, setOpenHours] = useState([]);
  const [timezone, setTimezone] = useState("Australia/Sydney");
  const onChange = (newDays, index) => {
    const newOpenHours = [...openHours];
    newOpenHours[index] = newDays;
    setOpenHours(newOpenHours);
  };
  const renderOptions = () => {
    const getTimeZonesWithGMT = () => {
      const timezones = moment.tz.names();
      const timeZonesWithGMT = timezones.map((tz) => {
        const offset = moment.tz(tz).utcOffset();
        const sign = offset >= 0 ? "+" : "-";
        const hours = Math.floor(Math.abs(offset) / 60);
        const minutes = Math.abs(offset) % 60;
        const formattedOffset = `GMT${sign}${String(hours).padStart(
          2,
          "0"
        )}:${String(minutes).padStart(2, "0")}`;
        return { tz, formattedOffset };
      });
      return timeZonesWithGMT;
    };
    const timeZonesWithGMT = getTimeZonesWithGMT();
    let mappedValues = {};
    let regions = [];
    timeZonesWithGMT.forEach(({ tz, formattedOffset }) => {
      const splitTimezone = tz.split("/");
      const region = splitTimezone[0];
      if (!mappedValues[region]) {
        mappedValues[region] = [];
        regions.push(region);
      }
      mappedValues[region].push({ tz, formattedOffset });
    });
    return regions.map((region) => {
      const options = mappedValues[region].map(({ tz, formattedOffset }) => (
        <Option key={tz} value={tz}>
          {`${tz} (${formattedOffset})`}
        </Option>
      ));
      return (
        <OptGroup key={region} label={region}>
          {options}
        </OptGroup>
      );
    });
  };
  const durations = [
    { label: "15 min", value: 15 },
    { label: "30 min", value: 30 },
    { label: "1 Hr", value: 60 },
    { label: "2 Hr", value: 120 },
  ];
  const user = useSelector((state) => get(state, "auth.user"));

  const submitAvailability = () => {
    setLoading(true);
    const modified_availability = putTimeHelper(openHours);
    const values = {
      appointment_duration: duration,
      availability: modified_availability,
      timezone: timezone,
    };

    updateWorkingHours(
      values,
      selectedDoctorId ? selectedDoctorId : get(user, "id")
    )
      .then((result) => {
        setLoading(false);
        notification.success({
          message: UPDATE_WORKING_HOURS_STATUS,
        });
        setSrawer({
          visible: !get(drawer, "visible"),
        });
        onSuccess();
        fetchWorkingHours(
          selectedDoctorId ? selectedDoctorId : get(user, "id")
        );
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onDurationChange = (event) => {
    setDuration(event.target.value);
  };

  useEffect(() => {
    getWorkingHours(selectedDoctorId ? selectedDoctorId : get(user, "id")).then(
      (result) => {
        setOpenHours(getTimeHelper(get(result, "availability")));
        setDuration(get(result, "appointment_duration"));
        setTimezone(result?.time_zone);
      }
    );
    //eslint-disable-next-line
  }, []);

  const clickZones = (value) => {
    setTimezone(value);
  };

  return (
    <Spin spinning={loading}>
      {edit ? (
        <>
          <Row justify="space-between">
            <Col className="drawer-title">Edit Availability</Col>
            <Col>
              <CloseOutlined onClick={toggleDrawer} />
            </Col>
          </Row>
          <div className="profile-timezone">
            <Typography.Text> Select Time Zone </Typography.Text>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select Time Zone"
              optionFilterProp="children"
              value={timezone}
              onChange={clickZones}
              filterOption={(input, option) =>
                option.props.children
                  ?.toLowerCase()
                  .indexOf(input?.toLowerCase()) >= 0
              }
            >
              {renderOptions()}
            </Select>
          </div>

          <div className="add-drawer-content">
            <div className="avaiability-form">
              <Space direction="vertical" size="middle">
                <div className="form-label">Your Availability</div>
                <div>
                  <div className="small-label">
                    <Row>
                      <Col span={12} push={12}>
                        Work Hours
                      </Col>
                      <Col span={12} pull={12}>
                        Work days
                      </Col>
                    </Row>
                  </div>

                  <SelectWorkingHours
                    openHours={openHours}
                    setOpenHours={setOpenHours}
                    onChange={onChange}
                  />
                </div>
                <div className="form-label">Appoitment Duration</div>
                <Radio.Group
                  options={durations}
                  optionType="button"
                  buttonStyle="solid"
                  onChange={onDurationChange}
                  value={duration}
                  size="large"
                />

                <div>
                  <Button
                    htmlType="submit"
                    type="primary"
                    onClick={submitAvailability}
                  >
                    Save
                  </Button>
                </div>
              </Space>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="screen-title">Availability</div>
          <div className="availability-content">
            <Space direction="vertical" size="middle">
              <div className="form-title"><span style={{color:"red"}}>* </span>Select TimeZone</div>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select Time Zone"
                optionFilterProp="children"
                value={timezone}
                onChange={clickZones}
                filterOption={(input, option) =>
                  option.props.children
                    ?.toLowerCase()
                    .indexOf(input?.toLowerCase()) >= 0
                }
              >
                {renderOptions()}
              </Select>

              <div className="avaiability-form">
                <div className="form-label">Your Availability</div>
                <div>
                  <div className="small-label">
                    <Row>
                      <Col span={12} push={12}>
                        Work Hours
                      </Col>
                      <Col span={12} pull={12}>
                        Work days
                      </Col>
                    </Row>
                  </div>

                  <SelectWorkingHours
                    openHours={openHours}
                    setOpenHours={setOpenHours}
                    onChange={onChange}
                  />
                </div>
                <div className="form-label">Appoitment Duration</div>
                <Radio.Group
                  options={durations}
                  optionType="button"
                  buttonStyle="solid"
                  onChange={onDurationChange}
                  value={duration}
                  size="large"
                />
                <div>
                  <Button
                    htmlType="submit"
                    type="primary"
                    onClick={submitAvailability}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Space>
          </div>
        </>
      )}
    </Spin>
  );
};

export default AvailabilityForm;
