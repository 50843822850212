import medicalhistory from "../../assets/icons/medicalhistory.svg";
import start_consultation from "../../assets/icons/start_consultation.svg";
import cancel from "../../assets/icons/cancel.svg";
import reschedule from "../../assets/icons/reschedule.svg";
import get from "lodash/get";
import PatientProfile from "../../components/patientprofile/PatientProfile";
import { Button, Row, Space } from "antd";
import CustomTag from "../../components/tag/CustomTag";

export const appointmentsColumn = ({
  toggleConsultationForm,
  toggleMedicalHistory,
  toggleReschedule,
  toggleCancel,
  toggleCancelAppointmentNotes,
  userId,
  doctorId,
  roles,
  onClickFamily,
  onClickRaiseClaim,
  toggleUpdateStatusForm,
  onClickUpdateClaim,
  onClickViewClaim,
  togglePatientAnswersForm,
}) => [
  {
    title: "Patient Name",
    key: "patient_name",
    render: (record) => (
      <PatientProfile
        patient_name={get(record, "patient.name")}
        patient_id={get(record, "appointment_id")}
      />
    ),
  },
  {
    title: "Email",
    key: "email",
    render: (record) => {
      return <span>{get(record, "patient.email")}</span>;
    },
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Visit Time",
    dataIndex: "time",
    key: "time",
  },
  {
    title: "Doctor",
    key: "doctor",
    render: (record) => (
      <div>
        {get(record, "doctor.title")} {get(record, "doctor.name")}
      </div>
    ),
  },
  {
    title: "Status",
    key: "status",
    render: (record) => <CustomTag status={get(record, "status.name")} />,
  },

  {
    title: "Actions",
    key: "action",
    render: (record) => (
      <Row>
        <Space>
          {userId === get(record, "doctor.id") && (
            <>
              <Button
                className="custom-action-button"
                onClick={() => {
                  toggleConsultationForm(record);
                }}
                type="text"
                style={{
                  opacity: get(record, "status.value") === 1 ? 1 : 0.5,
                }}
                disabled={get(record, "status.value") === 1 ? false : true}
              >
                <>
                  <img
                    src={start_consultation}
                    alt=""
                    className="appointment-action-icon"
                  />
                  <p className="small">Start Consultation</p>
                </>
              </Button>

              <Button
                className="custom-action-button"
                onClick={() => {
                  togglePatientAnswersForm(record);
                }}
                type="text"
                style={{
                  opacity: get(record, "status.value") === 1,
                }}
                // disabled={get(record, "status.value") === 1 ? false : true}
              >
                <>
                  <img
                    src={start_consultation}
                    alt=""
                    className="appointment-action-icon"
                  />
                  <p className="small">View Patient Answers</p>
                </>
              </Button>
            </>
          )}
          <Button
            className="custom-action-button"
            type="text"
            onClick={() => {
              toggleMedicalHistory(record);
            }}
          >
            <img
              src={medicalhistory}
              alt=""
              className="appointment-action-icon"
            />
            <p className="small">Medical History</p>
          </Button>
          {(userId === get(record, "doctor.id") || roles?.includes(5)) && (
            <>
              <Button
                className="custom-action-button"
                type="text"
                onClick={() => {
                  toggleReschedule(record);
                }}
                style={{
                  opacity: get(record, "status.value") === 1 ? 1 : 0.5,
                }}
                disabled={get(record, "status.value") === 1 ? false : true}
              >
                <img
                  src={reschedule}
                  alt=""
                  className="appointment-action-icon"
                />
                <p className="small">Reschedule</p>
              </Button>
              <Button
                className="custom-action-button"
                type="text"
                onClick={() => {
                  toggleCancel(record);
                }}
                style={{
                  opacity: get(record, "status.value") === 1 ? 1 : 0.5,
                }}
                disabled={get(record, "status.value") === 1 ? false : true}
              >
                <>
                  <img
                    src={cancel}
                    alt=""
                    className="appointment-action-icon"
                  />
                  <p className="small">Cancel</p>
                </>
              </Button>
              <Button
                className="custom-action-button"
                type="text"
                onClick={() => {
                  toggleCancelAppointmentNotes(record);
                }}
                style={{
                  display:
                    get(record, "status.value") === 4 ? "inline" : "none",
                }}
              >
                <img
                  src={medicalhistory}
                  alt=""
                  className="appointment-action-icon"
                />
                <p className="small">
                  {get(record, "reschedule_reason") !== null
                    ? "Resheduled notes"
                    : "Cancellation notes"}
                </p>
              </Button>
              <Button
                type="primary"
                onClick={() => toggleUpdateStatusForm(record)}
              >
                Update Status
              </Button>
              <Button type="primary" onClick={() => onClickFamily(record)}>
                Add Family
              </Button>
              {record?.status?.value === 3 && (
                <>
                  {record?.claim?.status === 0 ? (
                    <Button
                      type="primary"
                      onClick={() => onClickUpdateClaim(record)}
                    >
                      Update Claim
                    </Button>
                  ) : (
                    <>
                      {record?.claim?.status === 2 ? (
                        <Button
                          type="primary"
                          onClick={() => onClickViewClaim(record)}
                        >
                          View Claim
                        </Button>
                      ) : (
                        <>
                          {record?.patient?.user_additional_details
                            ?.medicare_number ? (
                            <Button
                              type="primary"
                              onClick={() => onClickRaiseClaim(record)}
                            >
                              Raise Claim
                            </Button>
                          ) : (
                            <Button
                              disabled={
                                !record?.patient?.user_additional_details
                                  ?.medicare_number
                              }
                              title="This patient doesn't have Medicare Number"
                              type="primary"
                              onClick={() => onClickRaiseClaim(record)}
                            >
                              Raise Claim
                            </Button>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Space>
      </Row>
    ),
  },
];

export const dataSource = [
  {
    key: "1",
    patient_name: "Mike",
    patient_id: "#24-834712IN",
    patient_picture: "",
    email: "mike@gmail.com",
    date: "10/10/2020",
    visit_time: "09:15-09:45am",
    doctor: "Dr James Doe",
    specialization: "Cardiologist",
    status: "done",
    action: "Medical History",
  },
  {
    key: "2",
    patient_name: "Mike",
    email: "mike@gmail.com",
    date: "10/10/2020",
    patient_id: "#24-834712IN",
    patient_picture: "",
    visit_time: "09:15-09:45am",
    doctor: "Dr James Doe",
    status: "pending",
    specialization: "Cardiologist",
    action: "Medical History",
  },
  {
    key: "3",
    patient_name: "Mike",
    email: "mike@gmail.com",
    date: "10/10/2020",
    visit_time: "09:15-09:45am",
    doctor: "Dr James Doe",
    patient_id: "#24-834712IN",
    patient_picture: "",
    status: "no show",
    specialization: "Cardiologist",
    action: "Medical History",
  },
  {
    key: "4",
    patient_name: "Mike",
    email: "mike@gmail.com",
    date: "10/10/2020",
    visit_time: "09:15-09:45am",
    patient_id: "#24-834712IN",
    patient_picture: "",
    doctor: "Dr James Doe",
    status: "cancelled",
    specialization: "Cardiologist",
    action: "Medical History",
  },
  {
    key: "5",
    patient_name: "Mike",
    email: "mike@gmail.com",
    date: "10/10/2020",
    visit_time: "09:15-09:45am",
    patient_id: "#24-834712IN",
    patient_picture: "",
    doctor: "Dr James Doe",
    status: "done",
    specialization: "Cardiologist",
    action: "Medical History",
  },
];
