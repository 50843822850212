import { useEffect, useState } from 'react';
import { Select, Spin,message } from 'antd';
import requireAuth from '../../hocs/requireAuth';
import get from 'lodash/get';
import map from 'lodash/map';
import { getWorkingHours, listDoctors,changeDefault } from '../../redux/actions/doctorAction';

import './style.scss';

import AvailabilityForm from '../../components/forms/availabilityForm';
import AvailabilityCalendar from '../../components/availabilityCalendar';
import { useSelector } from 'react-redux';
import { isEmpty } from '@antv/util';
const { Option } = Select;

const AvailabilityScreen = () => {
  const [showAvailabiltyForm, setShowAvailabiltyForm] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDoctorId, setSelectedDoctorId] = useState('');
  const [doctorsList, setDoctorsList] = useState([]);
  const [loading, setLoading] = useState(false);

  const roles = useSelector((state) => get(state, 'auth.roles'));
  const userId = useSelector((state) => get(state, 'auth.id'));
  const user = useSelector((state)=>get(state,"auth.user"));

  const fetchWorkingHours = (id) => {
    console.log('Fetch work hours called');
    setSelectedDoctorId(id);
    getWorkingHours(id).then((result) => {
      if (get(result, 'availability', []).length > 0) {
        setShowCalendar(true);
        setShowAvailabiltyForm(false);
      } else {
        setShowAvailabiltyForm(true);
        setShowCalendar(false);
      }
    });
  };

  useEffect(() => {
    if (roles.includes(5)) {
      setLoading(true);
      listDoctors(null, 1, 100)
        .then((result) => {
          setDoctorsList([...result.data]);
          setSelectedDoctorId([result.data[0]?.id]);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
    if(roles.includes(3) && user?.is_default_login){
      changeDefault({id:user?.id}).then((result)=>{
        message.success("Please select your availability");
      }).catch((error)=>{
      })
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (roles.includes(5)) {
      if (!isEmpty(selectedDoctorId)) {
        fetchWorkingHours(selectedDoctorId);
      }
    } else {
      fetchWorkingHours(userId);
    }
    //eslint-disable-next-line
  }, [selectedDoctorId]);

  return (
    <div className="availability-screen">
      <Spin spinning={loading}>
        {roles.includes(5) && (
          <Select
            value={selectedDoctorId}
            onChange={(value) => fetchWorkingHours(value)}
            size="large"
            className="custom-select"
          >
            {map(doctorsList, (doctor, i) => (
              <Option key={i} value={get(doctor, 'id')}>
                {get(doctor, 'name')}
              </Option>
            ))}
          </Select>
        )}
        {showAvailabiltyForm && (
          <AvailabilityForm
            selectedDoctorId={selectedDoctorId}
            fetchWorkingHours={fetchWorkingHours}
          />
        )}
        {showCalendar && (
          <AvailabilityCalendar selectedDoctorId={selectedDoctorId} />
        )}
      </Spin>
    </div>
  );
};
export default requireAuth(AvailabilityScreen, [3, 5]);
