import { Modal } from 'antd';

// styles
import './styles.scss';

export default function ModalPrimary(props) {
  return (
    <Modal
      centered
      width={props.width}
      title={props.title}
      visible={props.visible}
      onOk={props.onOk}
      onCancel={props.onCancel}
      okText={props.okText}
      destroyOnClose={props.destroyOnClose}
      className="modal-primary-style"
    >
      {props.children}
    </Modal>
  );
}
