import get from 'lodash/get';
import map from 'lodash/map';

export const workingHours = (hours = []) => {
  return map(hours, (time) => {
    if (get(time, 'day') === 1) {
      return { ...time, dayName: 'Monday' };
    }
    if (get(time, 'day') === 2) {
      return { ...time, dayName: 'Tuesday' };
    }
    if (get(time, 'day') === 3) {
      return { ...time, dayName: 'Wednesday' };
    }
    if (get(time, 'day') === 4) {
      return { ...time, dayName: 'Thursday' };
    }
    if (get(time, 'day') === 5) {
      return { ...time, dayName: 'Friday' };
    }
    if (get(time, 'day') === 6) {
      return { ...time, dayName: 'Saturday' };
    }
    if (get(time, 'day') === 7) {
      return { ...time, dayName: 'Sunday' };
    }
  });
};
