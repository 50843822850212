import { Button, Drawer } from 'antd';
import { useState } from 'react';
import get from 'lodash/get';
import AddDoctorForm from '../drawerContent/addDoctorForm/AddDoctorForm';
import { PlusOutlined } from '@ant-design/icons';
import PendingInvitationScreen from '../../screens/PendingInvitationsScreen/PendingInvitationScreen';


const AddDoctorDrawer = () => {
  const [addDoctorDrawer, setAddDoctorDrawer] = useState({
    visible: false,
    edit: false,
    data: null,
  });

  const toggleAddDoctor = (data = null) => {
    setAddDoctorDrawer({
      visible: !get(addDoctorDrawer, 'visible'),
      edit: !get(addDoctorDrawer, 'visible'),
      data,
    });
  };
  const [showPendingInvitations,setShowPendingInvitations] = useState(false);
  const pendingInvitation=()=>{
    setShowPendingInvitations(!showPendingInvitations);
  }
  
  return (
    <>
      <Button
        type="primary"
        onClick={() => toggleAddDoctor()}
        icon={<PlusOutlined />}
      >
        Add Doctor
      </Button>
      <Drawer
        className="main-drawer"
        destroyOnClose
      width={350}
        closable={false}
        onClose={() => toggleAddDoctor(null)}
        visible={get(addDoctorDrawer, 'visible')}
      >
        <AddDoctorForm toggleAddDoctor={toggleAddDoctor} />
        <Button onClick={pendingInvitation} type="primary">
          Pending Invitations
        </Button>
        {
          showPendingInvitations &&(
            <PendingInvitationScreen toggleAddDoctor={toggleAddDoctor} pendingInvitation={pendingInvitation}/>
          )
        }
      </Drawer>
    </>
  );
};

export default AddDoctorDrawer;
