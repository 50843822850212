import { Button, Col, Row, Form, Input, Spin, notification } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import reset_password_illus from '../../assets/images/reset_password_illus.svg';
import { useEffect, useState } from 'react';
import { validateMessages } from '../../constants/validation';
import { useHistory, useParams } from 'react-router';
import {
  changePassword,
  validateForgotPassword,
} from '../../redux/actions/staffAction';
import assign from 'lodash/assign';
import { LOGIN } from '../../constants/routes';

const ResetPasswordScreen = () => {
  const { uuid } = useParams();
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  let history = useHistory();

  const onFinish = (values) => {
    setLoading(true);
    assign(values, { uuid: uuid });
    changePassword(values)
      .then((result) => {
        console.log('RESULT >>>>', result);
        setLoading(false);
        notification.success({
          message: ('Passwaord has been reset successfully.'),
        });
        history.push(LOGIN);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    validateForgotPassword(uuid)
      .then((result) => {
        setLoading(false);
        console.log(result);
      })
      .catch((error) => {
        setLoading(false);
        setShowError(true);
      });
  }, [uuid]);

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <Spin spinning={loading}>
      <div className="dashboard-auth-container">
        <div className="dashboard-inner-container">
          <Row align="center">
            <Col xs={24} md={12} className='login-img'>
              <img src={reset_password_illus} alt="" />
            </Col>
            <Col xs={24} md={12} className="form">
              <div className="title">Reset Password</div>

              <Form
                name="basic"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                validateMessages={validateMessages}
              >
                <Form.Item
                  label="Create Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                    },
                    {
                      min: 6,
                      message: 'Password must be atleast 6 characters',
                    },
                  ]}
                >
                  <Input.Password className="custom-input" />
                </Form.Item>
                <Form.Item
                  label=" Confirm Password"
                  name="confirm_password"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            'The two passwords that you entered do not match!'
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password className="custom-input" />
                </Form.Item>
                {/* <Form.Item>
                  <Checkbox>
                    <span className="grey-info">I accept the</span>{' '}
                    <span className="purple-info">Terms and Conditions</span>
                  </Checkbox>
                </Form.Item> */}
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="full-button"
                  >
                    Reset Password
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </Spin>
  );
};
export default ResetPasswordScreen;
