import filter from 'lodash/filter';
import moment from 'moment';
import get from 'lodash/get';

export const generateSlots = (slots = [], date = null) => {
  // var day = '';
  // const currentDate = moment().format(YEAR_MONTH_DAY);
  // console.log(currentDate);
  // if (date === currentDate) {
  //   day = 'Today';
  // } else if (moment(currentDate).diff(moment(date), 'days') === 1) {
  //   day = 'Tomorrow';
  // } else {
  //   const fullDate = moment(date).format('llll').split(',');
  //   day = fullDate[0] + ',' + fullDate[1];
  // }
  // console.log(day);

  const morningSlots = filter(slots, (slot) => {
    return moment(get(slot, 'slot_start'), 'HH:mm').isBefore(
      moment('12:00', 'HH:mm'),
    );
  });

  const afternoonSlots = filter(slots, (slot) => {
    return (
      moment(get(slot, 'slot_start'), 'HH:mm').isBefore(
        moment('16:00', 'HH:mm'),
      ) &&
      moment(get(slot, 'slot_start'), 'HH:mm').isSameOrAfter(
        moment('12:00', 'HH:mm'),
      )
    );
  });

  const eveningSlots = filter(slots, (slot) => {
    return (
      moment(get(slot, 'slot_start'), 'HH:mm').isBefore(
        moment('23:59', 'HH:mm'),
      ) &&
      moment(get(slot, 'slot_start'), 'HH:mm').isSameOrAfter(
        moment('16:00', 'HH:mm'),
      )
    );
  });

  return {
    // day: day,
    available_slots: slots.length,
    slots: [
      { slotName: 'Morning Slot', time: morningSlots },
      { slotName: 'Afternoon Slot', time: afternoonSlots },
      { slotName: 'Evening Slot', time: eveningSlots },
    ],
  };
};

// const availability = [
//     {
//       day: 'Today',
//       available_slots: 0,
//       slots: [
//         { slotName: 'Morning Slot', time: ['9:30', '11:00'] },
//         { slotName: 'Afternoon Slot', time: ['2:00', '2:30'] },
//         { slotName: 'Evening Slot', time: ['7:00'] },
//       ],
//     },
//   ];
