import { Modal, Form, Select, InputNumber, Space, Button, Drawer, Col } from 'antd';
import get from 'lodash/get';
import map from 'lodash/map';
import { useState, useEffect } from 'react';
import { billingTypeOptions } from '../../../constants/options';
import { validateMessages } from '../../../constants/validation';
import { getHospitalAgents } from '../../../redux/actions/hospitalAgentAction';
import AddHospitalAgentForm from '../../../screens/HospitalAgentsScreen/AddHospitalAgentForm';
import { PlusOutlined } from '@ant-design/icons';

const Option = { Select };

const BillingModal = ({ modal, title, okText, onCancel, onFinish }) => {
  const [hospitalAgents, setHospitalAgents] = useState();
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const [addAgentDrawer, setAddAgentDrawer] = useState({
    visible: false,
    edit: false,
    data: null,
  });
  const toggleAddHospitalAgent = (data = null) => {
    setAddAgentDrawer({
      visible: !get(addAgentDrawer, 'visible'),
      edit: !get(addAgentDrawer, 'visible'),
      data,
    });
  };

  useEffect(() => {
    getHospitalAgents().then((res) => {
      setHospitalAgents(res);
    }).catch((err) => {
      console.log("err", err);
    })
  }, [])
  return (
    <Modal
      className="custom-modal"
      centered={true}
      closable={false}
      visible={get(modal, 'visible')}
      footer={null}
      destroyOnClose
    >
      <div className="modal-children">
        <div className="title">{title}</div>
        <Form
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          validateMessages={validateMessages}
          initialValues={{
            billing_method: get(modal, 'data.billing_method.value'),
            billing_unit_amount: get(modal, 'data.billing_unit_amount'),
          }}
        >
          <Form.Item
            label="Billing type"
            name="billing_method"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select>
              {map(billingTypeOptions, (s, i) => (
                <Option key={i} value={get(s, 'value')}>
                  {get(s, 'label')}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Billing amount"
            name="billing_unit_amount"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber
              //addonBefore="$"
              formatter={value => `$ ${value}`}
              size="large"
              min={0}
              className="custom-input-number"
            />
          </Form.Item>
          {/* <div className="label">Select Hospital Agent</div>
            <Form.Item
             name="hospital_agent"
             rules={[
               {
                 required: true,
               },
             ]}
            >
              <Select
                showSearch
                placeholder="Select"
                // onChange={(value) => onChangeFormData(value, 'hospital_agent')}
                // value={props.data.hospital_agent}
              >
                {
                  hospitalAgents?.map((hospitalAgent)=>{
                    return(
                      <Option value={hospitalAgent?.uuid}>{hospitalAgent?.user?.name}</Option>
                    )
                  })
                }
              </Select>
            </Form.Item> */}


          <Drawer
            className="main-drawer"
            destroyOnClose
            width={350}
            closable={false}
            onClose={() => toggleAddHospitalAgent(null)}
            visible={get(addAgentDrawer, 'visible')}
          >
            <AddHospitalAgentForm toggleAddHospitalAgent={toggleAddHospitalAgent} hospitalDetails={get(modal, 'data')} />
          </Drawer>

          <Form.Item>
            <Space style={{ marginTop: "12px" }}>
              <Button
                type="primary"
                onClick={() => toggleAddHospitalAgent()}
                icon={<PlusOutlined />}
              >
                Add Agent
              </Button>
              <Button type="primary" htmlType="submit">
                {okText}
              </Button>
              <Button type="default" onClick={() => onCancel()}>
                Cancel
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default BillingModal;
