import React, { useState } from "react";
import { Select, Form, Button, Radio,Modal } from "antd";
import EmergencyFormFamily from "./EmergencyFormFamily";
import OutsideForm from "./OutsideForm";
const { Option } = Select;

const SelectionTypeForm = ({ membersData, clickSubmit,doctor_uuid,hospital_uuid,closeModal,currentSlot,slotType,getAppointments,filter }) => {
    const[filteredUser,setFilteredUser] = useState(null);
  const onChangeUser = (value) => {
    const filterMemberData = membersData?.filter(
      (member) => member?.id === value
    );
    setFilteredUser(filterMemberData?.[0]);
  };
  const [consultationType,setConsultationType] = useState(null);
  const onFinish = (values) => {
    setConsultationType(values?.consultation_type);
    closeModal();
    openModal();
  };
  const [openFormModal,setOpenFormModal] = useState(false);
  const openModal=()=>{
    setOpenFormModal(true);
  }
  const closeFormModal=()=>{
    setOpenFormModal(false);
  }
  return (
    <>
      <Form onFinish={onFinish}>
        <Form.Item
          name="user"
          rules={[
            {
              required: true,
              message: "Please select User",
            },
          ]}
        >
          <Select
          showSearch
            style={{ width: "100%" }}
            onChange={onChangeUser}
            // value={props.data.specialisations}
            placeholder="Multiple Selection"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            options=
            {membersData?.filter(member=> member?.name != null && member?.email !== null)?.map((member, index) => ({label : member?.name,value:member?.id})
              // {membersData?.filter(member=> member?.name != null && member?.is_primary === true && member?.relation === null)?.map((member, index) => ({label : member?.name,value:member?.id})
              )}
          >
            {/* {map(membersData, (member, index) => {
                
              if (member?.user != null && member?.is_primary === true && member?.relation === null ) {
                return (
                  <Option key={member?.id} value={member?.id}>
                    {member?.user?.name}
                  </Option>
                );
              }
            })} */}
          </Select>
        </Form.Item>
        <Form.Item
          name="consultation_type"
          rules={[
            {
              required: true,
              message: "Please select Relation",
            },
          ]}
        >
          <Radio.Group>
            <div className="select-area">
              <Radio value={1}>Family</Radio>
              <Radio value={2}>Outside</Radio>
            </div>
          </Radio.Group>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
      <Modal onCancel={closeFormModal} open={openFormModal} visible={openFormModal} footer={null}>
      {
        consultationType === 1 &&
        <EmergencyFormFamily doctor_uuid={doctor_uuid} hospital_uuid={hospital_uuid} filteredUser={filteredUser} closeFormModal={closeFormModal} currentSlot={currentSlot} slotType={slotType}  getAppointments={getAppointments} filter={filter}/>
      }
      {
        consultationType === 2 &&
        <OutsideForm doctor_uuid={doctor_uuid} hospital_uuid={hospital_uuid} filteredUser={filteredUser?.user} closeFormModal={closeFormModal} currentSlot={currentSlot} slotType={slotType}/>
      }
      </Modal>
    </>
  );
};
export default SelectionTypeForm;
