import PatientAnswersDetails from "../consultationForm.js/PatientAnswersDetails";
import { getAllPatientAnsweredQuestions } from "../../../redux/actions/doctorAction";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { Spin,Row,Col } from "antd";
import {
    CloseOutlined,
  } from "@ant-design/icons";

const PatientAnswersForm = ({ appointment_details,togglePatientAnswersForm }) => {
  const [questionsData, setQuestionsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const hospital_uuid = useSelector((state) =>
    get(state, "auth.currentHospital.id")
  );
  const doctor_user_uuid = useSelector((state) => state?.auth.user?.id);

  useEffect(() => {
    getAllPatientAnsweredQuestions(doctor_user_uuid, hospital_uuid)
      .then((result) => {
        setLoading(false);
        setQuestionsData(result);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  return (
    <Spin spinning={loading}>
    <div>
      <Row justify="space-between">
        <Col className="drawer-title">Patient Filled Questions</Col>
        <Col>
          <CloseOutlined onClick={togglePatientAnswersForm} />
        </Col>
      </Row>
    <PatientAnswersDetails
      appointment_details={appointment_details}
      questionsData={questionsData}
    />
    </div>
    </Spin>
  );
};

export default PatientAnswersForm;
