import { CloseCircleFilled, PlusOutlined } from '@ant-design/icons';

import { Button, Input, Form, Row, Col } from 'antd';

import MultipleImageUpload from '../../mediaUpload/newMultipleImageUpload';

const AttachmentsDynamicForm = ({ attachmentImages, setAttachmentImages,form }) => {
  attachmentImages ?? form.setFieldValue("attachments",attachmentImages);
  const onUploadDone = (imageResponse, index) => {
    const newAttachments = attachmentImages;
    newAttachments[index] = imageResponse;
    setAttachmentImages([...newAttachments]);
  };

  return (
    <Form.List name="attachments">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, fieldKey, ...restField }, index) => (
            <div key={key}>
              <Row align="space-between">
                <Col span={22}>
                  <Form.Item
                    name={[name, 'title']}
                    fieldKey={[fieldKey, 'title']}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter the heading for the attachment!',
                      },
                    ]}
                  >
                    <Input
                      placeholder="Title"
                      className="custom-input"
                      style={{ width: '250px' }}
                    />
                  </Form.Item>

                  <Form.Item>
                    <MultipleImageUpload
                      onUploadDone={(imageResponse) =>
                        onUploadDone(imageResponse, index)
                      }
                      imageList={attachmentImages[index]}
                    />
                  </Form.Item>

                  {/* </Form.Item> */}
                </Col>
                <Col>
                  <CloseCircleFilled
                    style={{ fontSize: '18px' }}
                    onClick={() => remove(name)}
                  />
                </Col>
              </Row>
            </div>
          ))}

          <Form.Item>
            <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
              Add Attachement
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export default AttachmentsDynamicForm;
