import {
  CloseOutlined,
  DownloadOutlined,
  DownOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Divider,
  Col,
  Row,
  Space,
  Input,
  Spin,
  Image,
  Form,
  Button,
  Empty,
} from "antd";
import { Collapse } from "antd";
// import { prescription } from '../../../constants/dummydata';
import map from "lodash/map";
import get from "lodash/get";
import filter from "lodash/filter";
import some from "lodash/some";
import includes from "lodash/includes";
import moment from "moment";
import "./style.scss";
// import MultipleImageUpload from '../../mediaUpload/MultiImageUpload';
import { useEffect, useState } from "react";
import {
  downloadMedicalHistory,
  getMedicalHistory,
  updateMedicalHistory,
} from "../../../redux/actions/doctorAction";

import NotesDynamicForm from "../consultationForm.js/NotesDynamicForm";
import AttachmentsDynamicForm from "../consultationForm.js/AttachmentsDynamicForm";
import { useSelector } from "react-redux";
import AddressHelper from "../../../utilities/addressHelper/AddressHelper";
import { DAY_MONTH_YEAR_SLASH } from "../../../constants/constantsDateFormats";
import { saveAs } from "file-saver";

const { Panel } = Collapse;
const { Search } = Input;

const MedicalHistory = ({
  toggleMedicalHistory,
  patient_details,
  patient_uuid,
  appointment_id,
}) => {
  const [loading, setLoading] = useState(false);
  const current_doctor_id = useSelector((state) => get(state, "auth.user.id"));
  const [medicalReps, setMedicalReps] = useState({ data: [], filterData: [] });

  useEffect(() => {
    setLoading(true);
    getMedicalHistory(patient_uuid)
      .then((result) => {
        setMedicalReps({
          data: get(result, "data"),
          filterData: get(result, "data"),
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
    //eslint-disable-next-line
  }, []);

  const fetchMedicalHistory = () => {
    setLoading(true);
    getMedicalHistory(patient_uuid)
      .then((result) => {
        setMedicalReps({
          data: get(result, "data"),
          filterData: get(result, "data"),
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const token = useSelector((state) => get(state, "auth.token"));

  const handleDownload = async (appointment_note_uuid) => {
    setLoading(true);
    const response = await downloadMedicalHistory(
      patient_uuid,
      appointment_note_uuid,
      token
    );
    saveAs(response?.data?.url, "Medical History");
    setLoading(false);
  };

  const onSearch = (value) => {
    let ndata = get(medicalReps, "data");
    let filterdata = filter(ndata, (data) => {
      return some(data.notes, (notes) => {
        return some(notes, (note) => {
          return (
            includes(note, value) ||
            includes(get(data, "appointment.doctor.name"), value) ||
            includes(get(data, "appointment.date"), value)
          );
        });
      });
    });
    setMedicalReps({ data: ndata, filterData: filterdata });
  };
  return (
    <Spin spinning={loading}>
      <div className="medical-history">
        <Row justify="space-between">
          <Col className="drawer-title">Medical History</Col>
          <Col>
            <CloseOutlined onClick={toggleMedicalHistory} />
          </Col>
        </Row>
        <div className="drawer-content">
          <Row>
            <Space size={40} align="flex-start">
              <Col>
                <Avatar size={64} icon={<UserOutlined />} />
              </Col>
              <Col>
                <div>{get(patient_details, "name")}</div>
                <div className="small">
                  {" "}
                  {moment(get(patient_details, "dob")).format(
                    DAY_MONTH_YEAR_SLASH
                  ) === null || "Invalid date"
                    ? "DOB not available"
                    : moment(get(patient_details, "dob")).format(
                        DAY_MONTH_YEAR_SLASH
                      )}
                </div>
                <div className="small">
                  {" "}
                  {console.log(get(patient_details, "age"))}
                  {get(patient_details, "age") === null
                    ? "Age not available"
                    : get(patient_details, "age") + "years old"}
                </div>
                <div className="small">
                  {" "}
                  {get(patient_details, "gender") === "M" ? "Male" : "Female"}
                </div>
              </Col>
              <Col>
                <div>ID: {appointment_id}</div>
                {get(patient_details, "address_details") && (
                  <Row className="small">
                    <Space size={4}>
                      Address:{" "}
                      <AddressHelper
                        details={get(patient_details, "address_details")}
                      />
                    </Space>
                  </Row>
                )}
              </Col>
            </Space>
          </Row>
          <Divider />
          <Row style={{ alignItems: "baseline" }}>
            <Col span={12} push={12}>
              {/* <Input
                placeholder="Search by doctor or Date of appointment"
                allowClear
                size="large"
                prefix={<SearchOutlined style={{ fontSize: '12px' }} />}
              /> */}
              <Search
                placeholder="Doctor, notes, or date"
                className="search-input"
                allowClear
                size="medium"
                onSearch={onSearch}
                prefix={<SearchOutlined style={{ fontSize: "12px" }} />}
              />
            </Col>
            <Col span={12} pull={12}>
              Medical Reports
            </Col>
          </Row>
          {get(medicalReps, "filterData", []).length > 0 ? (
            <>
              {" "}
              {map(get(medicalReps, "filterData", []), (singleReport, i) => (
                <Collapse>
                  <Panel
                    header={
                      <PanelHeader
                        appointment_details={singleReport}
                        handleDownload={handleDownload}
                      />
                    }
                    showArrow={false}
                  >
                    <PanelContent
                      notes={get(singleReport, "notes")}
                      attachments={get(singleReport, "attachments")}
                      patient_uuid={patient_uuid}
                      setLoading={setLoading}
                      setMedicalReports={setMedicalReps}
                      appointment_note_uuid={get(singleReport, "id")}
                      fetchMedicalHistory={fetchMedicalHistory}
                      current_doctor_id={current_doctor_id}
                      appointment_doctor_id={get(
                        singleReport?.appointment?.doctor,
                        "id"
                      )}
                    />
                  </Panel>
                </Collapse>
              ))}
            </>
          ) : (
            <Empty description="No medical history found" />
          )}
        </div>
      </div>
    </Spin>
  );
};

export default MedicalHistory;

const PanelHeader = ({ appointment_details, handleDownload }) => {
  console.log("DATA INSIDE PANEL HEADER>>>>", appointment_details);
  return (
    <div className="panel-header">
      <Row justify="space-between">
        <Col>
          <div className="x-small-grey" style={{ marginBottom: "20px" }}>
            Appointment Date
          </div>
          <div>{get(appointment_details, "appointment.date")}</div>
          <div className="small-grey">
            {get(appointment_details, "appointment.time")}
          </div>
          {get(appointment_details, "note_updated_by") && (
            <div>
              <span className="small-grey"> Last updated by</span>{" "}
              {get(appointment_details, "note_updated_by.title")}{" "}
              {get(appointment_details, "note_updated_by.name")}
            </div>
          )}
        </Col>
        <Col>
          <Row>
            <Col>
              <div className="x-small-grey" style={{ marginBottom: "20px" }}>
                Treated by
              </div>
              <Space>
                <Col>
                  <Avatar
                    size={48}
                    icon={
                      <img
                        src={get(
                          appointment_details,
                          "appointment.doctor.image.url"
                        )}
                        alt=""
                      />
                    }
                  />
                </Col>
                <Col>
                  <div>
                    {get(appointment_details, "appointment.doctor.title")}{" "}
                    {get(appointment_details, "appointment.doctor.name")}
                  </div>
                  <div className="blue-highlight">
                    {get(
                      appointment_details,
                      "appointment.doctor.doctor_detail.qualifications"
                    )}
                  </div>
                </Col>
              </Space>
            </Col>
            <Col>
              <DownOutlined style={{ fontSize: "12px" }} />
            </Col>
          </Row>
          <div style={{ textAlign: "center" }}>
            <DownloadOutlined
              style={{ fontSize: "20px" }}
              onClick={() => handleDownload(get(appointment_details, "id"))}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

const PanelContent = ({
  notes,
  attachments,
  setLoading,
  patient_uuid,
  setMedicalReports,
  appointment_note_uuid,
  fetchMedicalHistory,
  appointment_doctor_id,
  current_doctor_id,
}) => {
  const [editMode, setEditMode] = useState(false);

  return editMode ? (
    <EditMedicalHistory
      notes={notes}
      attachments={attachments}
      setLoading={setLoading}
      patient_uuid={patient_uuid}
      setMedicalReports={setMedicalReports}
      appointment_note_uuid={appointment_note_uuid}
      setEditMode={setEditMode}
      fetchMedicalHistory={fetchMedicalHistory}
      appointment_doctor_id={appointment_doctor_id}
      current_doctor_id={current_doctor_id}
    />
  ) : (
    <ViewMedicalHistory
      notes={notes}
      attachments={attachments}
      setEditMode={setEditMode}
      appointment_doctor_id={appointment_doctor_id}
      current_doctor_id={current_doctor_id}
    />
  );
};

const ViewMedicalHistory = ({
  notes,
  attachments,
  setEditMode,
  appointment_doctor_id,
  current_doctor_id,
}) => {
  const roles = useSelector((state) => get(state, "auth.roles"));
  return (
    <div className="panel-content">
      {map(notes, (note) => (
        <div className="reports">
          <div className="label">{get(note, "heading")}</div>
          <div className="report-content">
            <ul>
              {map(get(note, "note").split("\n"), (point) => (
                <li>{point}</li>
              ))}
            </ul>
          </div>
        </div>
      ))}
      <div className="attachments">
        {attachments.length > 0 && (
          <>
            <div className="label">Attachments</div>
            {map(attachments, (singleAttachment) => (
              <div className="single-attachement">
                <div className="attachment-title">
                  {get(singleAttachment, "title")}
                </div>
                {map(get(singleAttachment, "images"), (image) => (
                  <div className="image-container">
                    {image?.image_url ? (
                      <Image
                        src={get(image, "image_url")}
                        width={120}
                        height={120}
                      />
                    ) : null}
                  </div>
                ))}
              </div>
            ))}
          </>
        )}
      </div>

      <br />
      {!roles.includes(2) && (
        <Button
          type="primary"
          onClick={() => setEditMode(true)}
          disabled={appointment_doctor_id !== current_doctor_id}
        >
          Edit Medical History
        </Button>
      )}
    </div>
  );
};

const EditMedicalHistory = ({
  notes,
  attachments,
  setLoading,
  setMedicalReports,
  patient_uuid,
  appointment_note_uuid,
  setEditMode,
  fetchMedicalHistory,
}) => {
  const [attachmentImages, setAttachmentImages] = useState(
    map(attachments, (attachment) =>
      map(get(attachment, "images"), (img) => {
        return {
          name: get(img, "uuid"),
          status: "done",
          uid: get(img, "uuid"),
          url: get(img, "image_url"),
        };
      })
    )
  );

  const editMedicalHistory = (payload) => {
    setLoading(true);
    updateMedicalHistory(payload, patient_uuid, appointment_note_uuid)
      .then((result) => {
        setMedicalReports(get(result, "data"));
        setLoading(false);
        fetchMedicalHistory();
        setEditMode(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    // setLoading(true);

    const attachmentHeadings = get(values, "attachments");
    const finalAttachements = map(attachmentHeadings, (heading, i) => {
      return {
        title: get(heading, "title"),
        images: map(attachmentImages[i], (image) => get(image, "name")),
      };
    });
    const payload = {
      notes: get(values, "notes"),
      attachments: finalAttachements,
    };

    editMedicalHistory(payload);
  };

  return (
    <Form
      name="dynamic_form_nest_item"
      onFinish={onFinish}
      autoComplete="off"
      initialValues={{ notes: notes, attachments: attachments }}
    >
      <div className="current-label">Notes</div>
      <br />
      <NotesDynamicForm />
      <div className="current-label">Attachments </div>
      <br />
      <AttachmentsDynamicForm
        attachmentImages={attachmentImages}
        setAttachmentImages={setAttachmentImages}
      />
      <Space size={30}>
        <Button type="primary" htmlType="submit">
          Save Medical History
        </Button>
        <Button type="primary" onClick={() => setEditMode(false)}>
          Cancel
        </Button>
      </Space>
    </Form>
  );
};
