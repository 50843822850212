import { useState } from 'react';
import { Upload, Spin, Image } from 'antd';
import get from 'lodash/get';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { VIDEO_UPLOAD, CONTACTUS_VIDEO_UPLOAD } from '../../constants/apiEndpoints';

const SingleVideoUpload = (props) => {
  const { videoUrl, onUploadDone, setVideoUser } = props;

  const [loading, setLoading] = useState(false);
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const handleChange = (info) => {
    if (get(info, 'file.status') === 'uploading') {
      setLoading(true);
      return;
    }
    if (get(info, 'file.status') === 'done') {
      get(info, 'file.response');
      setLoading(false);
      onUploadDone(get(info, 'file.response.data'));
    } else {
      setLoading(false);
    }
  };

  const handleRemove = () => {
    setVideoUser(null);
  };

  return (
    <Spin spinning={loading}>
      {videoUrl ? (
        <div style={{ position: 'relative', width: 200, height: 200 }}>
          <video src={videoUrl} alt="Preview" width={200} height={200} controls />
          <div
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              cursor: 'pointer',
              background: '#fff',
              padding: '4px',
            }}
            onClick={handleRemove}
          >
            Remove
          </div>
        </div>
      ) : (
        <Upload
          name="file"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          accept="video/*" // Change to accept video file types
          action={CONTACTUS_VIDEO_UPLOAD} // Update to appropriate video upload API endpoint
          beforeUpload={() => {}}
          onChange={handleChange}
        >
          {videoUrl ? (
            <video src={videoUrl} style={{ width: '100%' }} controls />
          ) : (
            uploadButton
          )}
        </Upload>
      )}
    </Spin>
  );
};

export default SingleVideoUpload;
