import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Row, Form, Input, Spin, notification } from 'antd';
import { useState } from 'react';
import { ADD_DEPARTMENT_SUCCESS } from '../../../constants/messages';
import { validateMessages } from '../../../constants/validation';
import { addDepartment } from '../../../redux/actions/departmentAction';
import { ContactCodeSelector } from '../../contactCodeSelector/ContactCodeSelector';

const AddDepartmentForm = ({ toggleAddDepartment, fetchDepartment }) => {
  const onFinish = (values) => {
    setLoading(true);
    addDepartment(values)
      .then((result) => {
        toggleAddDepartment();
        setLoading(false);
        notification.success({
          message: (values.name + ADD_DEPARTMENT_SUCCESS),
        });
        fetchDepartment();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const [loading, setLoading] = useState(false);
  return (
    <Spin spinning={loading}>
      <div className="add-drawer-content">
        <Row justify="space-between">
          <Col className="drawer-title">Add department</Col>
          <Col>
            <CloseOutlined onClick={toggleAddDepartment} />
          </Col>
        </Row>
        <div className="add-drawer-form">
          <Form
            name="control-hooks"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            validateMessages={validateMessages}
            initialValues={{ contact_code: '91' }}
          >
            <Form.Item
              label="Department Name"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input className="custom-input" placeholder="Enter department" />
            </Form.Item>
            <Form.Item
              label="Department Email"
              name="email"
              rules={[
                {
                  required: true,
                  type: 'email',
                },
              ]}
            >
              <Input className="custom-input" placeholder="Enter mail" />
            </Form.Item>
            <Form.Item
              label="Contact Number"
              name="contact_number"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                className="custom-input"
                addonBefore={ContactCodeSelector}
              />
            </Form.Item>

            <Form.Item>
              <Button htmlType="submit" type="primary">
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Spin>
  );
};
export default AddDepartmentForm;
