import {
  Col,
  Radio,
  Row,
  Space,
  Spin,
  DatePicker,
  Select,
  Input,
  Table,
  Drawer,
  Modal,
  Button,
  Form,
  InputNumber,
  notification,
} from "antd";
import { useEffect, useState } from "react";
import moment from "moment";
import { filterBillings, filterDays } from "../../constants/options";
import get from "lodash/get";
import map from "lodash/map";
import {
  getBillings,
  markInvoicePaid,
} from "../../redux/actions/superAdminAction";
import { SearchOutlined } from "@ant-design/icons";
import "./styles.scss";
import { billingsColumn } from "../../constants/table/billingsColumn";
import DoneModal from "../../components/modals/doneModal/DoneModal";
import InvoiceInfo from "../../components/invoiceInfo/InvoiceInfo";
import SendInvoice, {
  InvoiceCard,
} from "../../components/sendInvoice/SendInvoice";
import requireAuth from "../../hocs/requireAuth";
import { validateMessages } from "../../constants/validation";
import { YEAR_MONTH_DAY } from "../../constants/constantsDateFormats";
import { MediaAndAdsColumn } from "../../constants/table/mediaAndAdsColumn";
import {
  getMediaAds,
  deleteHospitalMediaAd,
} from "../../redux/actions/hospitalAction";
import { useSelector } from "react-redux";
import { IndividualTvAdsColumn } from "../../constants/table/IndividualTvAdsColumn";
import CreateTvAdsDetails from "../../components/drawerContent/createTvAdsDetails/CreateTvAdsDetails";
import { useParams } from "react-router";
import { DELETED_AD } from "../../constants/messages";
import ViewAdsForm from "../../components/drawerContent/viewAdsForm/ViewAdsForm";

const { Option } = Select;
const { Search } = Input;

const { RangePicker } = DatePicker;
const IndividualTvAdsScreen = () => {
  const params = useParams();
  const hospital_uuid = useSelector((state) =>
    get(state, "auth.currentHospital.id")
  );
  const [loading, setLoading] = useState(false);
  const [billingsResult, setBillingsResult] = useState({
    billings: [],
    pagination: null,
  });
  const [filter, setFilter] = useState({
    page: 1,
    fromDate: moment().format(YEAR_MONTH_DAY),
    toDate: moment().format(YEAR_MONTH_DAY),
    days: 1,
    status: null,
    name: "",
  });
  const handleDays = (days) => {
    const toDate = moment().format(YEAR_MONTH_DAY);
    const fromDate = moment(moment())
      .subtract(days, "d")
      .format(YEAR_MONTH_DAY);
    getBillingList(
      get(filter, "page"),
      fromDate,
      toDate,
      days,
      get(filter, "status"),
      get(filter, "name")
    );
  };

  const [billingInfo, setBillingInfo] = useState({
    visible: false,
    data: null,
  });

  const toggleBillingInfo = (data = null) => {
    setBillingInfo({
      visible: !get(billingInfo, "visible"),
      data,
    });
  };

  const [visibleDone, setVisibleDone] = useState(false);
  const [doneMessage, setDoneMessage] = useState("");

  const toggleDone = (message = "") => {
    setVisibleDone(!visibleDone);
    setDoneMessage(message);
  };

  const [markPaidModal, setMarkPaidModal] = useState({
    visible: false,
    data: null,
  });
  const toggleMarkPaid = (data = null) => {
    setMarkPaidModal({ visible: !get(markPaidModal, "visible"), data: data });
  };

  const [sendInvoiceModal, setSendInvoiceModal] = useState({
    visible: false,
    data: null,
  });
  const toggleSendInvoiceModal = (data = null) => {
    setSendInvoiceModal({
      visible: !get(sendInvoiceModal, "visible"),
      data: data,
    });
  };

  const handlePrint = (data) => {
    // <InvoiceCard data={data} />
  };

  const handleRange = (dates) => {
    if (dates) {
      const toDate = moment(dates[1]).format(YEAR_MONTH_DAY);
      const fromDate = moment(dates[0]).format(YEAR_MONTH_DAY);
      getBillingList(
        get(filter, "page"),
        fromDate,
        toDate,
        "null",
        get(filter, "status"),
        get(filter, "name")
      );
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const markPaid = (values) => {
    const invoice_uuid = get(markPaidModal, "data.id");
    markInvoicePaid(invoice_uuid, values)
      .then((result) => {
        setLoading(false);
        toggleMarkPaid();
        toggleDone("Marked as Paid");
        getBillingList(
          get(filter, "page"),
          get(filter, "fromDate"),
          get(filter, "toDate"),
          get(filter, "days"),
          get(filter, "status"),
          get(filter, "name")
        );
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getBillingList = (
    page,
    fromDate,
    toDate,
    days,
    status = null,
    name = ""
  ) => {
    setLoading(true);
    getMediaAds(params?.currentTvId, fromDate, toDate, status, page, name).then(
      (result) => {
        setLoading(false);
        setBillingsResult({
          billings: get(result, "data"),
          pagination: get(result, "meta.pagination"),
        });
        setFilter({ page, fromDate, toDate, days, status, name });
      }
    ).catch((err)=>{
      setLoading(false)
    })

    // fetchHospitalTvAdById()
  };
  const onSearch = (value) => {
    getBillingList(
      get(filter, "page"),
      get(filter, "fromDate"),
      get(filter, "toDate"),
      get(filter, "days"),
      get(filter, "status"),
      value
    );
  };

  useEffect(() => {
    getBillingList(
      get(filter, "page"),
      get(filter, "fromDate"),
      get(filter, "toDate"),
      get(filter, "days"),
      get(filter, "status"),
      get(filter, "name")
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [openNewAd, setOpenNewAd] = useState(false);
  const [currentAdId, setCurrentAdId] = useState(null);

  const createNewTvAds = () => {
    setOpenNewAd(true);
  };
  const closeNewTvAd = () => {
    setOpenNewAd(false);
    setCurrentAdId(null);
  };
  const toggleEdit = (itemUuid) => {
    setOpenNewAd(true);
    setCurrentAdId(itemUuid);
  };
  const [openModal,setOpenModal] = useState(false);
  const [currentAd,setCurrentAd] = useState(null);
  const toggleViewAds = (data) => {
    setCurrentAd(data)
    setOpenModal(true);
    // currentTvId && history.push(`/media-ads/${currentTvId}`)
  };
  const onCloseModal = ()=>{
    setCurrentAd(null);
    setOpenModal(false);
  }
  const deleteTvAd = (itemUuid) => {
    deleteHospitalMediaAd(itemUuid)
      .then((result) => {
        setLoading(false);
        notification.success({
          message: DELETED_AD,
        });
        const days = 1;
        const toDate = moment().format(YEAR_MONTH_DAY);
        const fromDate = moment(moment())
          .subtract(days, "d")
          .format(YEAR_MONTH_DAY);
        getBillingList(
          get(filter, "page"),
          fromDate,
          toDate,
          get(filter, "days"),
          get(filter, "status"),
          get(filter, "name")
        );
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={18} push={6}>
          <Row align="end">
            <Space>
              <Radio.Group
                options={filterDays}
                value={get(filter, "days")}
                optionType="button"
                buttonStyle="solid"
                onChange={(event) => handleDays(get(event, "target.value"))}
              />
              <RangePicker
                onChange={(dateStrings) => handleRange(dateStrings)}
              />
            </Space>
          </Row>
        </Col>
        <Col span={6} pull={18}>
          <div className="screen-title">Ads</div>
        </Col>
      </Row>
      <div style={{ float: "right" }}>
        <Button type="primary" onClick={createNewTvAds}>
          +Add TV Ads
        </Button>
      </div>

      <Table
        columns={IndividualTvAdsColumn({
          deleteTvAd,
          toggleViewAds,
          toggleEdit,
        })}
        dataSource={get(billingsResult, "billings")}
        pagination={{
          pageSize: get(billingsResult, "pagination.per_page", 1),
          current: get(billingsResult, "pagination.current_page", 1),
          total: get(billingsResult, "pagination.total", 1),
          onChange: (val) => {
            getBillingList(
              val,
              get(filter, "fromDate"),
              get(filter, "toDate"),
              get(filter, "days"),
              get(filter, "status"),
              get(filter, "name")
            );
          },

          position: ["bottomLeft"],
        }}
      />
      <Drawer
        className="main-drawer"
        destroyOnClose
        width="25%"
        closable={false}
        onClose={closeNewTvAd}
        visible={openNewAd}
      >
        <CreateTvAdsDetails
          closeNewTvAd={closeNewTvAd}
          data={get(billingInfo, "data")}
          tv_id={params?.currentTvId}
          currentAdId={currentAdId}
          setCurrentAdId={setCurrentAdId}
          fetchMediaAds={getBillingList}
          filter={filter}
        />
      </Drawer>
      <Modal
      style={{width:"100px"}}
        className="custom-modal-ads"
        open={openModal}
        onCancel={()=>onCloseModal()}
        footer={null}
      >
        <ViewAdsForm currentAd={currentAd}/>
      </Modal>
    </Spin>
  );
};

export default IndividualTvAdsScreen;
