import * as APIS from '../../constants/apiEndpoints';
import { axiosInstance } from '../../utilities/configureAxios';

export const inviteHospitalAgent = (values) => {
  return axiosInstance.post(APIS.INVITE_HOSPITAL_AGENT, values).then((result) => {
    return result;
  });
};

export const getHospitalAgents = () => {
  return axiosInstance.get(APIS.GET_HOSPITAL_AGENTS).then((result) => {
    return result;
  });
};

export const registerHospitalAgent = (values) => {
  return axiosInstance.post(APIS.REGISTER_HOSPITAL_AGENT, values).then((result) => {
    return result;
  });
};


export const getHospitalAgentInvitation = (uuid, payload) => {
  let url = APIS.GET_HOSPITAL_AGENT_INVITATION.replace(
    '{uuid}',
    uuid
  );
  return axiosInstance.get(url, payload).then((result) => {
    return result;
  });
};

export const validateHospitalAgentLink = (uuid) => {
  return axiosInstance
    .get(APIS.VALID_HOSPITAL_AGENT_LINK.concat(uuid))
    .then((result) => {
      return result;
    });
};

