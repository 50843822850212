import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import EditDetails from '../../components/drawerContent/doctordetails/editDetails/EditDetails';
import { validateDoctorLink } from '../../redux/actions/doctorAction';
import get from 'lodash/get';
import { Spin } from 'antd';
import {
  INVALID_LINK,
  INVALID_LINK_SUBTITLE,
  REGISTRATION_SUBTITLE,
  SUCCESSFUL_REGISTRATION,
} from '../../constants/messages';
import './style.scss';
import CustomResult from '../../components/customResult/CustomResult';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/actions/authAction';

const RegisterDoctorScreen = () => {
  const dispatch = useDispatch();

  dispatch(logout())
    .then(() => {})
    .catch((error) => {});

  const [loading, setLoading] = useState(true);
  const { uuid } = useParams();
  const [showSuccess, setShowSuccess] = useState(false);
  const [registerDetails, setRegisterDetails] = useState({
    info: {},
    invite_uuid: '',
  });
  const [showForm, setShowForm] = useState(false);
  const [showError, setShowError] = useState(false);
  const [imageUser, setUserImage] = useState();

  useEffect(() => {
    validateDoctorLink(uuid)
      .then((result) => {
        setLoading(false);
        let user = {...result?.user};
        user = {image : "",name :result?.user?.name}
        result = {...result,user}

        if (get(result, 'as_doctor_exists')) {
          setShowSuccess(true);
        } else {
          setRegisterDetails({ info: result, invite_uuid: uuid });
          setShowForm(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        setShowError(true);
      });
  }, [uuid]);

  const toggleResultSuccess = () => {
    setLoading(false);
    setShowSuccess(true);
    setShowForm(false);
  };
  console.log('Register Details', registerDetails);
  return (
    <Spin spinning={loading}>
      <div className="register-doctor-screen">
        {showForm && (
          <div className="register-doctor-form">
            <EditDetails
              editForm={false}
              details={registerDetails ? registerDetails : null}
              toggleResultSuccess={toggleResultSuccess}
              setUserImage={setUserImage}
              imageUser={imageUser}
            />
          </div>
        )}
        {showSuccess && (
          <CustomResult
            status="success"
            title={SUCCESSFUL_REGISTRATION}
            subTitle={REGISTRATION_SUBTITLE.concat(
              get(registerDetails, 'info.hospital.name'),
            )}
          />
        )}
        {showError && (
          <CustomResult
            status="error"
            title={INVALID_LINK}
            subTitle={INVALID_LINK_SUBTITLE}
          />
        )}
      </div>
    </Spin>
  );
};
export default RegisterDoctorScreen;
