import { SearchOutlined } from '@ant-design/icons';
import { Col, Input, Row, Spin, Table } from 'antd';
import { useEffect, useState } from 'react';
import { hospitalAgentsColumn } from './HospitalAgentsColumn';
import { getHospitalAgents } from '../../redux/actions/hospitalAgentAction';
import DoneModal from '../../components/modals/doneModal/DoneModal';
import requireAuth from '../../hocs/requireAuth';

const HospitalAgentsScreen = () => {
  const [loading, setLoading] = useState(false);
  const [hospitalAgents, setHospitalAgents] = useState([]);
  const [message, setMessage] = useState();
  const fetchHospitalAgents = () => {
    getHospitalAgents()
      .then((result) => {
        console.log(result);
        setHospitalAgents(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchHospitalAgents();
  }, []);
  const [doneModal, setDoneModal] = useState(false);
  const toggleDone = (message) => {
    setMessage(message);
    setDoneModal(!doneModal);
  };
  return (
    <Spin spinning={loading}>
      <Row justify="space-between" style={{ alignItems: 'baseline' }}>
        <Col xs={14} md={12}>
          <div className="screen-title">Hospital Agents</div>
        </Col>

        <Col xs={10} md={12}>
          <Input
            placeholder="Hospital Agent name or email"
            allowClear
            size="large"
            prefix={<SearchOutlined style={{ fontSize: '12px' }} />}
          />
        </Col>
      </Row>
      <Table
        columns={hospitalAgentsColumn({})}
        scroll={{ x: true }}
        dataSource={hospitalAgents}
        pagination={false}
      />
      <DoneModal
        message={message}
        visible={doneModal}
        toggleDone={toggleDone}
      />
    </Spin>
  );
};

export default requireAuth(HospitalAgentsScreen, [1]);
