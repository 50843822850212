import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Spin, Form, notification } from 'antd';

import { useState } from 'react';
import { INVITE_ADMIN_SUCCESS } from '../../../constants/messages';
import { validateMessages } from '../../../constants/validation';
import { inviteAdmin } from '../../../redux/actions/adminAction';
import assign from 'lodash/assign';

const AddAdminForm = ({ toggleDrawer }) => {
  const [loading, setLoading] = useState(false);
  const onFinish = (values) => {
    setLoading(true);

    assign(values, { role: 2 });

    inviteAdmin(values)
      .then(() => {
        toggleDrawer();
        setLoading(false);
        notification.success({
          message: (INVITE_ADMIN_SUCCESS),
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <Spin spinning={loading}>
      <div className="add-drawer-content">
        <Row justify="space-between">
          <Col className="drawer-title">Invite Admin</Col>
          <Col>
            <CloseOutlined onClick={toggleDrawer} />
          </Col>
        </Row>
        <div className="add-drawer-form">
          <Form
            name="control-hooks"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            validateMessages={validateMessages}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  type: 'email',
                },
              ]}
            >
              <Input className="custom-input" placeholder="Enter mail" />
            </Form.Item>

            <Form.Item>
              <Button htmlType="submit" type="primary">
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Spin>
  );
};
export default AddAdminForm;
