import { CloseOutlined } from '@ant-design/icons';
import { Col, Row, Spin, Form, Input, Button, notification } from 'antd';
import { useState } from 'react';
import { SEND_INVITE_STATUS } from '../../constants/messages';
import { validateMessages } from '../../constants/validation';
import assign from 'lodash/assign';
import { inviteHospitalAgent } from '../../redux/actions/hospitalAgentAction';

const AddHospitalAgentForm = ({ toggleAddHospitalAgent,hospitalDetails }) => {
  const [loading, setLoading] = useState(false);
  const onFinish = (values) => {
    setLoading(true);
    let inviteValue = { email: values.email, data: { name: values.name } };
    assign(inviteValue, { role: 7,hospitalUuid:hospitalDetails?.id });
    inviteHospitalAgent(inviteValue)
      .then(() => {
        setLoading(false);
        toggleAddHospitalAgent();
        notification.success({
          message: (SEND_INVITE_STATUS),
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <Spin spinning={loading}>
      <div className="add-drawer-content">
        <Row justify="space-between">
          <Col className="drawer-title">Invite Hospital Agent</Col>
          <Col>
            <CloseOutlined onClick={toggleAddHospitalAgent} />
          </Col>
        </Row>
        <div className="add-drawer-form">
          <Form
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            validateMessages={validateMessages}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  type: 'email',
                },
              ]}
            >
              <Input className="custom-input" />
            </Form.Item>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input className="custom-input" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="full-button">
                Send Invite
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Spin>
  );
};
export default AddHospitalAgentForm;
