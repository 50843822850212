import { useEffect } from 'react';
import { Area } from '@antv/g2plot';
import get from 'lodash/get';
import map from 'lodash/map';
import moment from 'moment';
import './styles.scss';
import { DAY_MONTH, MONTH_YEAR } from '../../constants/constantsDateFormats';

function DashboardGraph({ data1 , type }) {

  const data = map(data1, (d) => {
    const formatted_month = moment().month(get(d, 'month')-1).format(MONTH_YEAR);
    const formatted_date = moment([get(d, 'year'), get(d, 'month')-1, get(d, 'day') ]).format(DAY_MONTH)
    const arrayData = [
      {
        appointment_type: 'complete',
        value: parseInt(get(d, 'complete')),
        Month: formatted_month,
        Date: formatted_date,
      },
      {
        appointment_type: 'cancelled',
        value: parseInt(get(d, 'cancelled')),
        Month: formatted_month,
        Date: formatted_date,
      },
      {
        appointment_type: 'noShow',
        value: parseInt(get(d, 'noShow')),
        Month: formatted_month,
        Date: formatted_date
      },
    ];

    return arrayData.flat();
  });
  useEffect(() => {
    let graphDiv = document.getElementById(`container-${type}`);
    graphDiv.innerHTML='';
    let graphEntries = data.flat();
    console.log(`GRAPH ENTRIES ${type} >>>>`, graphEntries);
    graphEntries = graphEntries ? graphEntries : [];

    if (type === 'monthlyStats') {
      const area = new Area(`container-${type}`, {
        data: graphEntries,
        xField: 'Month',
        yField: 'value',
        seriesField: 'appointment_type',
        annotations: [],
        xAxis: { title: { text: 'Month' } },
        yAxis: { title: { text: 'No. of Appointments' } },
      });
      area.render();
    } else {
      const area = new Area(`container-${type}`, {
        data: graphEntries,
        xField: 'Date',
        yField: 'value',
        seriesField: 'appointment_type',
        annotations: [],
        xAxis: { title: { text: 'Date' },fill:"red",color:"red" },
        yAxis: { title: { text: 'No. of Appointments' } },
        shadowColor: 'black',
        shadowBlur: 10,
        shadowOffsetX: 5,
        shadowOffsetY: 5,
        fill:"red"
      });
      area.render();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data1]);

  return (
    <div id={`container-${type}`} className="graph-container"></div>
  )
}

export default DashboardGraph;
