import { Button, Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Modal from 'antd/lib/modal/Modal';
import get from 'lodash/get';

const ReasonModal = ({ visible, onCancel, onDone, title }) => {
  const onFinish = (values) => {
    onDone(get(values, 'reason'));
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Modal
      className="custom-modal"
      centered={true}
      closable={false}
      visible={visible}
      footer={null}
      destroyOnClose={true}
    >
      <div className="modal-children">
        <div className="title">{title}</div>
        <Form
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Give a reason"
            name="reason"
            rules={[
              {
                required: true,
                message: 'Please enter reason',
              },
            ]}
          >
            <TextArea rows={4} placeholder="Type here..." />
          </Form.Item>
          <div className="buttons buttons-gap justify-end">
            <Button type="text" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Done
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
export default ReasonModal;
