import { Button, Drawer } from 'antd';
import { useState } from 'react';
import get from 'lodash/get';
import AvailabilityForm from '../forms/availabilityForm';

const AvailabilityDrawer = ({ refreshCalendar, selectedDoctorId }) => {
  const [drawer, setSrawer] = useState({
    visible: false,
  });

  const toggleDrawer = (data = null) => {
    setSrawer({
      visible: !get(drawer, 'visible'),
    });
  };
  const onSuccess = () => {
    toggleDrawer();
    refreshCalendar();
  };
  return (
    <>
      <Button type="primary" onClick={() => toggleDrawer()}>
        Edit Availability
      </Button>
      <Drawer
        className="main-drawer"
        destroyOnClose
        width="32%"
        closable={false}
        visible={get(drawer, 'visible')}
      >
        <AvailabilityForm
          toggleDrawer={toggleDrawer}
          onSuccess={onSuccess}
          edit="true"
          selectedDoctorId={selectedDoctorId}
          setSrawer={setSrawer}
          drawer={drawer}
        />
      </Drawer>
    </>
  );
};

export default AvailabilityDrawer;
