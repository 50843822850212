import React, { useState } from "react";
import { Button, Col, Row, Form, Input, message } from "antd";
import "./style.scss";
import { contactDetails } from "../../redux/actions/contactUsAction";
import SingleImageUpload from "../../../src/components/mediaUpload/SingleImageUpload";
import get from "lodash/get";
import assign from "lodash/assign";
import { useHistory } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";

const ContactUsScreen = () => {
  const [loading, setLoading] = useState(false);
  const [imageUser, setUserImage] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [file, setFile] = useState(null);
  const [form] = Form.useForm();
  let history = useHistory();

  const handleUpload = (file) => {
    message.success(`${file.name} uploaded successfully`);
  };

  const handleRemove = () => {
    setFile(null);
  };

  const onFinish = (values) => {
    assign(values, {
      image: get(imageUser, "key"),
      thumbnail: get(thumbnail, "thumbnail_key"),
    });
    setLoading(true);
    contactDetails(values, file)
      .then((result) => {
        console.log(result);
        setLoading(false);
        message.success("Query Created Successfully");

        // Clear form and reset image
        form.resetFields();
        setUserImage(null);
        setThumbnail(null);
        setFile(null); // Clear any uploaded file preview if needed

        history("/dashboard");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onUploadLogoDone = (imageResponse) => {
    setUserImage(imageResponse);
    setThumbnail(imageResponse);
  };

  return (
    <>
      <Row>
        <Col span={12} className="form">
          <h2 className="title2">Contact Us</h2>
          <p className="description">For Any queries</p>
          <Form
            form={form}
            name="basic"
            layout="vertical"
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 16 }}
            autoComplete="off"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please enter your name" }]}
            >
              <Input className="custom-input" />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, type: "email", message: "Please enter a valid email" }]}
            >
              <Input className="custom-input" />
            </Form.Item>

            <Form.Item
              label="Phone"
              name="phone"
              rules={[{ required: true, message: "Please enter a valid phone number", pattern: /^\d{8,11}$/ }]}
            >
              <Input
                type="text"
                className="custom-input"
                placeholder="Enter 8-11 digit phone number"
              />
            </Form.Item>

            <Form.Item
              label="Comments"
              name="comments"
              rules={[{ required: true, message: "Please add your comments" }]}
            >
              <TextArea />
            </Form.Item>

            <Form.Item>
              <SingleImageUpload
                className="user-image"
                onUploadDone={onUploadLogoDone}
                imageUrl={get(imageUser, "url")}
                setImageUser={setUserImage}
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ cursor: "pointer" }}
                loading={loading}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <style>
        {`
          .ant-form-item-explain-error {
            color: red;
          }
        `}
      </style>
    </>
  );
};

export default ContactUsScreen;
