import { DownOutlined, MenuOutlined } from '@ant-design/icons';
import { Col, Layout, Row, Badge, Space, Drawer, Popover, Button } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import get from 'lodash/get';
import { useState } from 'react';
import Notifications from '../../drawerContent/notifications/Notifications';
import notification_bell_inactive from '../../../assets/icons/notification_bell_inactive.svg';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logout } from '../../../redux/actions/authAction';
import { LOGIN } from '../../../constants/routes';
import DoctorDetails from '../../drawerContent/doctordetails';
import UserDetails from '../../drawerContent/userDetails/UserDetails';

const { Header } = Layout;

export default function LayoutHeader({ openMenuButton }) {
  const [visible, setVisible] = useState(false);
  const [notificationDrawer, setNotificationDrawer] = useState({
    visible: false,
    edit: false,
    data: null,
  });
  const media = window.matchMedia(`(min-width: 1023px)`);
  const name = useSelector((state) => get(state, 'auth.user.name'));
  const title = useSelector((state) => get(state, 'auth.user.title'));
  const image = useSelector((state) => get(state, 'auth.user.image.url'));

  const toggleNotificationDrawer = (data = null) => {
    setNotificationDrawer({
      visible: !get(notificationDrawer, 'visible'),
      edit: !get(notificationDrawer, 'visible'),
      data,
    });
  };

  const handleVisibleChange = () => {
    setVisible(!visible);
  };
  return (
    <>
      <Header className="main-header">
        <Row  justify='space-between'>
            <Col>
              {!media.matches && (
                <Button onClick={openMenuButton} className="mobile-toggle-button">
                  <MenuOutlined />
                </Button>
              )}
            </Col>
            <Col>
              <Row align="end" justify="center">
                <Space size={40}>
                  <Col>
                    <Badge size="small" count={5}>
                      <img
                        src={notification_bell_inactive}
                        alt=""
                        onClick={toggleNotificationDrawer}
                        style={{ cursor: 'pointer' }}
                      />
                    </Badge>
                  </Col>
                  <Col>
                    <Popover
                      placement="bottomRight"
                      content={ProfileDrop({ setVisible })}
                      trigger="hover"
                      visible={visible}
                      onVisibleChange={handleVisibleChange}
                    >
                      <Row>
                        <Space>
                          <Col>
                            <Avatar
                              size="large"
                              icon={<img src={image} alt="profile" />}
                            />
                          </Col>
                          <Col>
                            <div>
                              {title} {name}
                            </div>
                            <div className="x-small-grey">Signed in as</div>
                          </Col>
                          <Col>
                            <DownOutlined style={{ fontSize: '12px' }} />
                          </Col>
                        </Space>
                      </Row>
                    </Popover>
                  </Col>
                </Space>
              </Row>
            </Col>
        </Row>
      </Header>
      <Drawer
        className="main-drawer"
        destroyOnClose
        width={400}
        closable={false}
        onClose={() => toggleNotificationDrawer(null)}
        visible={get(notificationDrawer, 'visible')}
      >
        <Notifications toggleNotificationDrawer={toggleNotificationDrawer} />
      </Drawer>
    </>
  );
}
const ProfileDrop = ({ setVisible }) => {
  const roles = useSelector((state) => get(state, 'auth.roles'));
  const [doctorProfileDrawer, setDoctorProfileDrawer] = useState({
    visible: false,
    edit: false,
    data: null,
  });

  const toggleDoctorProfile = (data = null, edit = false) => {
    setDoctorProfileDrawer({
      visible: !get(doctorProfileDrawer, 'visible'),
      edit: edit,
      data,
    });
  };

  const [userProfileDrawer, setUserProfileDrawer] = useState({
    visible: false,
    edit: false,
    data: null,
  });

  const toggleUserProfileDrawer = (data = null, edit = false) => {
    setUserProfileDrawer({
      visible: !get(userProfileDrawer, 'visible'),
      edit: edit,
      data,
    });
  };
  const dispatch = useDispatch();
  let history = useHistory();

  const onClickLogout = () => {
    dispatch(logout())
      .then(() => {
        history.push(LOGIN);
      })
      .catch((error) => { });
  };

  const handleViewProfile = () => {
    setVisible(false);
    if (roles.includes(3)) {
      toggleDoctorProfile();
    } else {
      toggleUserProfileDrawer();
    }
  };

  return (
    <div className="profile-drop">
      <div className="profile-link" onClick={handleViewProfile}>
        View Profile
      </div>
      <div
        className="profile-link"
        onClick={() => {
          onClickLogout();
        }}
      >
        Logout
      </div>
      <Drawer
        className="doctor-details-drawer"
        destroyOnClose
        width="30%"
        closable={true}
        onClose={() => toggleDoctorProfile(null)}
        visible={get(doctorProfileDrawer, 'visible')}
      >
        <DoctorDetails
          toggleDoctorDetails={toggleDoctorProfile}
          details={get(doctorProfileDrawer, 'data')}
          edit={get(doctorProfileDrawer, 'edit')}
        />
      </Drawer>
      <Drawer
        destroyOnClose
        width={350}
        className="main-drawer"
        closable={false}
        onClose={() => toggleUserProfileDrawer(null)}
        visible={get(userProfileDrawer, 'visible')}
      >
        <UserDetails toggleUserProfileDrawer={toggleUserProfileDrawer} />
      </Drawer>
    </div>
  );
};
