import { CloseOutlined } from '@ant-design/icons';
import { Col, Row, Input, Space } from 'antd';
import get from 'lodash/get';
import moment from "moment";

import './style.scss';

const InvoiceInfo = ({ toggleBillingInfo, data }) => {
  console.log('INOIVE INFO>>>>>', data);
  return (
    <div className="add-drawer-content">
      <Row justify="space-between">
        <Col className="drawer-title">Billing Info</Col>
        <Col>
          <CloseOutlined onClick={toggleBillingInfo} />
        </Col>
      </Row>

      <div className="add-drawer-form">
        <Space direction="vertical" size={15}>
          <div className="medium">Billing type</div>
          <Input
            value={get(data, 'hospital.billing_method.name')}
            disabled="true"
          />
          <div className="medium">Unit amount</div>
          <div className="unbilled-card">
            <div className="info">
              <div className="price-title">
                $<span>{get(data, 'amount')}</span>
              </div>
              <div className="appointements">
                for {get(data, 'units')}{' '}
                {
                  get(data, 'hospital.billing_method.name', 'no data').split(
                    ' '
                  )[1]
                }
                s<div className="divider"></div>
                Per{' '}
                {
                  get(data, 'hospital.billing_method.name', 'no data').split(
                    ' '
                  )[1]
                }{' '}
                ${get(data, 'unit_amount')}
              </div>
            </div>
          </div>
          <div>Created At : {moment(data?.created_at).format("DD-MM-YYYY")}</div>
          <div>Time : {moment(data?.created_at).format("hh:mm:ss")}</div>
        </Space>
      </div>
    </div>
  );
};
export default InvoiceInfo;
